import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { Power } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse
} from '../../../components';
import { AnnouncementForm } from './AnnouncementForm';
import { useAnnouncementActions } from './useAnnouncementActions';
import { useModal } from '../../../hooks';
import { addDays, format } from 'date-fns';

type AnnouncementModalProps = {
  isOpen: boolean;
  announcementId: string | null;
  toggle: () => void;
};

export type Announcement = {
  title: string;
  body: string;
  startDate: string;
  endDate: string;
};

const defaultAnnouncement: Announcement = {
  title: '',
  body: '',
  startDate: format(new Date(), 'yyyy-MM-dd'),
  endDate: format(addDays(new Date(), 7), 'yyyy-MM-dd')
};

enum AnnouncementMode {
  Create = 'Create',
  Update = 'Update'
}

export const AnnouncementModal: React.FC<AnnouncementModalProps> = (props) => {
  const { isOpen, toggle, announcementId } = props;

  const [mode, setMode] = useState<AnnouncementMode>(AnnouncementMode.Create);
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isDeleteAnnouncementModalOpen,
    toggleModal: toggleDeleteAnnouncementModal
  } = useModal();

  const {
    announcement,
    getAnnouncement,
    getAnnouncementLoading,
    handleCreateAnnouncement,
    createAnnouncementLoading,
    handleUpdateAnnouncement,
    updateAnnouncementLoading,
    handleDeleteAnnouncement,
    deleteAnnouncementLoading
  } = useAnnouncementActions({ toggle, toggleDeleteAnnouncementModal });

  const form = useForm<Announcement>({
    defaultValues: defaultAnnouncement
  });

  useEffect(() => {
    form.reset(announcement);
  }, [announcement]);

  useEffect(() => {
    setLoading(createAnnouncementLoading || updateAnnouncementLoading);
  }, [createAnnouncementLoading, updateAnnouncementLoading]);

  useEffect(() => {
    if (isOpen) {
      if (announcementId) {
        setMode(AnnouncementMode.Update);
        getAnnouncement({
          variables: {
            id: announcementId
          }
        });
      } else {
        setMode(AnnouncementMode.Create);
        form.reset(defaultAnnouncement);
      }
    }
  }, [announcementId, isOpen]);

  const handleSubmit = (data: Announcement) => {
    if (mode === AnnouncementMode.Update) {
      handleUpdateAnnouncement(data);
    } else {
      handleCreateAnnouncement(data);
    }
  };

  const menuItems = [
    {
      icon: <Power size={14} />,
      label: 'Delete',
      onClick: toggleDeleteAnnouncementModal
    }
  ];

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isDeleteAnnouncementModalOpen}
        toggle={toggleDeleteAnnouncementModal}
        title="Delete Announcement"
        body={
          <p>
            Are you sure you want to delete this announcement? This action can
            not be reversed.
          </p>
        }
        loading={deleteAnnouncementLoading}
        confirmationText="Yes, delete"
        onConfirm={handleDeleteAnnouncement}
      />
      <Modal toggle={!loading ? toggle : () => null} isOpen={isOpen} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="editAnnouncement">
            {`${mode} Announcement`}
          </h5>
          {announcement && mode === AnnouncementMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getAnnouncementLoading ? (
          <Pulse />
        ) : (
          <>
            <AnnouncementForm form={form} />
            <ModalFooter>
              <Button disabled={loading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={loading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {mode}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
