import React, { forwardRef } from 'react';
import { Label } from 'reactstrap';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';

export type ToggleProps = InputWrapperProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ label, helperText, error, ...props }, ref) => (
    <InputWrapper label={label} helperText={helperText} error={error}>
      <Label className="custom-toggle">
        <input type="checkbox" ref={ref} {...props} />
        <span className="custom-toggle-slider rounded-circle" />
      </Label>
    </InputWrapper>
  )
);

Toggle.displayName = 'Toggle';
