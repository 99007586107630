import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessCompaniesQuery = { getCompanies: Array<{ id: string, name: string, email: string | null, phoneNumber: string | null, phoneTier: Types.CompanyPhoneTier | null, activeUserCount: number, deactivatedAt: string | null, membership: { id: string, name: string, emoji: string } }> };

export type GetBusinessCompanyQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetBusinessCompanyQuery = { getCompany: { id: string, membershipId: string, name: string, email: string | null, phoneNumber: string | null, faxNumber: string | null, tollNumber: string | null, websiteUrl: string | null, notes: string | null, intake: string | null, training: string | null, memberSince: string | null, phoneTier: Types.CompanyPhoneTier | null, deactivatedAt: string | null } };

export type GetCompanyAlertsCountQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyAlertsCountQuery = { getCompany: { id: string, activeAlertCount: number } };

export type CreateBusinessCompanyMutationVariables = Types.Exact<{
  data: Types.CreateCompanyInput;
}>;


export type CreateBusinessCompanyMutation = { createCompany: { id: string } };

export type UpdateBusinessCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateCompanyInput;
}>;


export type UpdateBusinessCompanyMutation = { updateCompany: { id: string } };

export type ActivateBusinessCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ActivateBusinessCompanyMutation = { activateCompany: { id: string } };

export type DeactivateBusinessCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeactivateBusinessCompanyMutation = { deactivateCompany: { id: string } };


export const GetBusinessCompaniesDocument = gql`
    query GetBusinessCompanies {
  getCompanies(includeInactive: true) {
    id
    name
    email
    phoneNumber
    membership {
      id
      name
      emoji
    }
    phoneTier
    activeUserCount
    deactivatedAt
  }
}
    `;

/**
 * __useGetBusinessCompaniesQuery__
 *
 * To run a query within a React component, call `useGetBusinessCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessCompaniesQuery, GetBusinessCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessCompaniesQuery, GetBusinessCompaniesQueryVariables>(GetBusinessCompaniesDocument, options);
      }
export function useGetBusinessCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessCompaniesQuery, GetBusinessCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessCompaniesQuery, GetBusinessCompaniesQueryVariables>(GetBusinessCompaniesDocument, options);
        }
export type GetBusinessCompaniesQueryHookResult = ReturnType<typeof useGetBusinessCompaniesQuery>;
export type GetBusinessCompaniesLazyQueryHookResult = ReturnType<typeof useGetBusinessCompaniesLazyQuery>;
export type GetBusinessCompaniesQueryResult = Apollo.QueryResult<GetBusinessCompaniesQuery, GetBusinessCompaniesQueryVariables>;
export const GetBusinessCompanyDocument = gql`
    query GetBusinessCompany($id: UUID!) {
  getCompany(id: $id) {
    id
    membershipId
    name
    email
    phoneNumber
    faxNumber
    tollNumber
    websiteUrl
    notes
    intake
    training
    memberSince
    phoneTier
    deactivatedAt
  }
}
    `;

/**
 * __useGetBusinessCompanyQuery__
 *
 * To run a query within a React component, call `useGetBusinessCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessCompanyQuery, GetBusinessCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessCompanyQuery, GetBusinessCompanyQueryVariables>(GetBusinessCompanyDocument, options);
      }
export function useGetBusinessCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessCompanyQuery, GetBusinessCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessCompanyQuery, GetBusinessCompanyQueryVariables>(GetBusinessCompanyDocument, options);
        }
export type GetBusinessCompanyQueryHookResult = ReturnType<typeof useGetBusinessCompanyQuery>;
export type GetBusinessCompanyLazyQueryHookResult = ReturnType<typeof useGetBusinessCompanyLazyQuery>;
export type GetBusinessCompanyQueryResult = Apollo.QueryResult<GetBusinessCompanyQuery, GetBusinessCompanyQueryVariables>;
export const GetCompanyAlertsCountDocument = gql`
    query GetCompanyAlertsCount($companyId: UUID!) {
  getCompany(id: $companyId) {
    id
    activeAlertCount
  }
}
    `;

/**
 * __useGetCompanyAlertsCountQuery__
 *
 * To run a query within a React component, call `useGetCompanyAlertsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAlertsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAlertsCountQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyAlertsCountQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyAlertsCountQuery, GetCompanyAlertsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyAlertsCountQuery, GetCompanyAlertsCountQueryVariables>(GetCompanyAlertsCountDocument, options);
      }
export function useGetCompanyAlertsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAlertsCountQuery, GetCompanyAlertsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyAlertsCountQuery, GetCompanyAlertsCountQueryVariables>(GetCompanyAlertsCountDocument, options);
        }
export type GetCompanyAlertsCountQueryHookResult = ReturnType<typeof useGetCompanyAlertsCountQuery>;
export type GetCompanyAlertsCountLazyQueryHookResult = ReturnType<typeof useGetCompanyAlertsCountLazyQuery>;
export type GetCompanyAlertsCountQueryResult = Apollo.QueryResult<GetCompanyAlertsCountQuery, GetCompanyAlertsCountQueryVariables>;
export const CreateBusinessCompanyDocument = gql`
    mutation CreateBusinessCompany($data: CreateCompanyInput!) {
  createCompany(data: $data) {
    id
  }
}
    `;
export type CreateBusinessCompanyMutationFn = Apollo.MutationFunction<CreateBusinessCompanyMutation, CreateBusinessCompanyMutationVariables>;

/**
 * __useCreateBusinessCompanyMutation__
 *
 * To run a mutation, you first call `useCreateBusinessCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessCompanyMutation, { data, loading, error }] = useCreateBusinessCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusinessCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessCompanyMutation, CreateBusinessCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessCompanyMutation, CreateBusinessCompanyMutationVariables>(CreateBusinessCompanyDocument, options);
      }
export type CreateBusinessCompanyMutationHookResult = ReturnType<typeof useCreateBusinessCompanyMutation>;
export type CreateBusinessCompanyMutationResult = Apollo.MutationResult<CreateBusinessCompanyMutation>;
export type CreateBusinessCompanyMutationOptions = Apollo.BaseMutationOptions<CreateBusinessCompanyMutation, CreateBusinessCompanyMutationVariables>;
export const UpdateBusinessCompanyDocument = gql`
    mutation UpdateBusinessCompany($id: UUID!, $data: UpdateCompanyInput!) {
  updateCompany(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateBusinessCompanyMutationFn = Apollo.MutationFunction<UpdateBusinessCompanyMutation, UpdateBusinessCompanyMutationVariables>;

/**
 * __useUpdateBusinessCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessCompanyMutation, { data, loading, error }] = useUpdateBusinessCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusinessCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessCompanyMutation, UpdateBusinessCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessCompanyMutation, UpdateBusinessCompanyMutationVariables>(UpdateBusinessCompanyDocument, options);
      }
export type UpdateBusinessCompanyMutationHookResult = ReturnType<typeof useUpdateBusinessCompanyMutation>;
export type UpdateBusinessCompanyMutationResult = Apollo.MutationResult<UpdateBusinessCompanyMutation>;
export type UpdateBusinessCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessCompanyMutation, UpdateBusinessCompanyMutationVariables>;
export const ActivateBusinessCompanyDocument = gql`
    mutation ActivateBusinessCompany($id: UUID!) {
  activateCompany(id: $id) {
    id
  }
}
    `;
export type ActivateBusinessCompanyMutationFn = Apollo.MutationFunction<ActivateBusinessCompanyMutation, ActivateBusinessCompanyMutationVariables>;

/**
 * __useActivateBusinessCompanyMutation__
 *
 * To run a mutation, you first call `useActivateBusinessCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBusinessCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBusinessCompanyMutation, { data, loading, error }] = useActivateBusinessCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBusinessCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBusinessCompanyMutation, ActivateBusinessCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBusinessCompanyMutation, ActivateBusinessCompanyMutationVariables>(ActivateBusinessCompanyDocument, options);
      }
export type ActivateBusinessCompanyMutationHookResult = ReturnType<typeof useActivateBusinessCompanyMutation>;
export type ActivateBusinessCompanyMutationResult = Apollo.MutationResult<ActivateBusinessCompanyMutation>;
export type ActivateBusinessCompanyMutationOptions = Apollo.BaseMutationOptions<ActivateBusinessCompanyMutation, ActivateBusinessCompanyMutationVariables>;
export const DeactivateBusinessCompanyDocument = gql`
    mutation DeactivateBusinessCompany($id: UUID!) {
  deactivateCompany(id: $id) {
    id
  }
}
    `;
export type DeactivateBusinessCompanyMutationFn = Apollo.MutationFunction<DeactivateBusinessCompanyMutation, DeactivateBusinessCompanyMutationVariables>;

/**
 * __useDeactivateBusinessCompanyMutation__
 *
 * To run a mutation, you first call `useDeactivateBusinessCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateBusinessCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateBusinessCompanyMutation, { data, loading, error }] = useDeactivateBusinessCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateBusinessCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateBusinessCompanyMutation, DeactivateBusinessCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateBusinessCompanyMutation, DeactivateBusinessCompanyMutationVariables>(DeactivateBusinessCompanyDocument, options);
      }
export type DeactivateBusinessCompanyMutationHookResult = ReturnType<typeof useDeactivateBusinessCompanyMutation>;
export type DeactivateBusinessCompanyMutationResult = Apollo.MutationResult<DeactivateBusinessCompanyMutation>;
export type DeactivateBusinessCompanyMutationOptions = Apollo.BaseMutationOptions<DeactivateBusinessCompanyMutation, DeactivateBusinessCompanyMutationVariables>;