import { Option } from '../../types';
import { useGetCompanyOptionsQuery } from './__generated__/graphql';

export const useCompanyOptions = (): Option[] => {
  const { data } = useGetCompanyOptionsQuery();

  return (data?.getCompanies ?? []).map(
    ({ id, name }): Option => ({
      value: id,
      label: name
    })
  );
};
