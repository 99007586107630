import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import moment from 'moment-timezone';
import { CornerDownRight, XCircle } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse,
  Toggle
} from '../../../components';
import { useModal } from '../../../hooks';
import {
  baseAppointmentKeys,
  useAppointmentActions
} from './useAppointmentActions';
import { AppointmentForm } from './AppointmentForm';
import { PartialAppointmentData } from './Calendar';
import { pick } from 'lodash';
import { AppointmentHistoryModal } from './AppointmentHistoryModal';

type AppointmentModalProps = {
  isOpen: boolean;
  appointmentId: string | null;
  toggle: () => void;
  initialValues: PartialAppointmentData | null;
};

export type Appointment = {
  userId: string;
  resourceId: string;
  notes: string | null;
  internalNotes: string | null;
  startAt: string;
  endAt: string;
};

const defaultAppointment: Appointment = {
  userId: '',
  resourceId: '',
  notes: null,
  internalNotes: null,
  startAt: moment().add(1, 'hour').startOf('hour').toISOString(),
  endAt: moment().add(2, 'hour').startOf('hour').toISOString()
};

enum AppointmentMode {
  Create = 'Create',
  Update = 'Update'
}

export const AppointmentModal: React.FC<AppointmentModalProps> = (props) => {
  const { isOpen, toggle, appointmentId, initialValues } = props;

  const [mode, setMode] = useState<AppointmentMode>(AppointmentMode.Create);
  const [notifyUser, setNotifyUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isCancelAppointmentModalOpen,
    toggleModal: toggleCancelAppointmentModal
  } = useModal();

  const {
    isOpen: isAppointmentHistoryModalOpen,
    toggleModal: toggleAppointmentHistoryModal
  } = useModal();

  const {
    appointment,
    getAppointment,
    getAppointmentLoading,
    handleCreateAppointment,
    createAppointmentLoading,
    handleUpdateAppointment,
    updateAppointmentLoading,
    handleCancelAppointment,
    cancelAppointmentLoading
  } = useAppointmentActions({ toggle, toggleCancelAppointmentModal });

  const form = useForm<Appointment>({
    defaultValues: defaultAppointment
  });

  useEffect(() => {
    form.reset(pick(appointment, baseAppointmentKeys));
  }, [appointment]);

  useEffect(() => {
    setLoading(
      createAppointmentLoading ||
        updateAppointmentLoading ||
        cancelAppointmentLoading
    );
  }, [createAppointmentLoading, updateAppointmentLoading]);

  useEffect(() => {
    if (isOpen) {
      setNotifyUser(false);

      if (appointmentId) {
        setMode(AppointmentMode.Update);
        getAppointment({
          variables: {
            id: appointmentId
          }
        });
      } else {
        setMode(AppointmentMode.Create);
        form.reset({
          ...defaultAppointment,
          ...initialValues
        });
      }
    }
  }, [appointmentId, isOpen]);

  const toggleNotifyUser = () => setNotifyUser(!notifyUser);

  const handleSubmit = (data: Appointment) => {
    if (mode === AppointmentMode.Update) {
      handleUpdateAppointment(data, { notifyUser });
    } else {
      handleCreateAppointment(data, { notifyUser });
    }
  };

  const menuItems = [
    {
      icon: <CornerDownRight size={16} />,
      label: 'View History',
      onClick: toggleAppointmentHistoryModal
    },
    {
      icon: <XCircle size={16} />,
      label: 'Cancel Appointment',
      onClick: toggleCancelAppointmentModal
    }
  ];

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isCancelAppointmentModalOpen}
        toggle={toggleCancelAppointmentModal}
        title="Cancel Appointment"
        body={
          <span className="mb--4">
            <p>
              Are you sure you want to cancel this appointment? This action can
              not be reversed.
            </p>
            <Toggle
              label="Notify User"
              helperText="Enable this option to deliver an email notification"
              checked={notifyUser}
              onChange={toggleNotifyUser}
            />
          </span>
        }
        loading={cancelAppointmentLoading}
        confirmationText="Yes, cancel"
        cancelText="No, back"
        onConfirm={() => handleCancelAppointment({ notifyUser })}
      />
      <AppointmentHistoryModal
        toggle={toggleAppointmentHistoryModal}
        isOpen={isAppointmentHistoryModalOpen}
        events={appointment?.events || []}
      />
      <Modal
        toggle={!loading ? toggle : () => null}
        isOpen={isOpen}
        size="lg"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="editAppointment">
            {`${mode} Appointment`}
          </h5>
          {appointment && mode === AppointmentMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getAppointmentLoading ? (
          <Pulse />
        ) : (
          <>
            <AppointmentForm
              form={form}
              notifyUser={notifyUser}
              toggleNotifyUser={toggleNotifyUser}
            />
            <ModalFooter>
              <Button disabled={loading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={loading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {mode}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
