import React, { useState, useMemo } from 'react';
import { Column } from 'react-table';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CSVLink } from 'react-csv';
import {
  GetBusinessUsersQuery,
  useGetBusinessUsersQuery
} from './__generated__/graphql';
import { Table, Toggle } from '../../../components';
import { useModal } from '../../../hooks';
import { UserModal } from './UserModal';
import { Filter } from 'react-feather';
import { formatPhoneNumberNational } from '../../../utils';

type User = GetBusinessUsersQuery['getUsers'][0];

const columns: Column<User>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: (data) => `${data.firstName} ${data.lastName}`
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Phone Number',
    accessor: (user) =>
      user.phoneNumber ? formatPhoneNumberNational(user.phoneNumber) : '—',
    disableSortBy: true
  },
  {
    Header: 'Title',
    accessor: (user) => user.title ?? '—',
    disableSortBy: true
  },
  {
    Header: 'Company',
    accessor: (user) => user.company.name,
    disableSortBy: true
  },
  {
    Header: 'Active',
    accessor: 'deactivatedAt',
    disableSortBy: true,
    Cell: ({ value: deactivatedAt }) => {
      return <Toggle checked={!deactivatedAt} disabled />;
    }
  }
];

const headers = [
  { label: 'ID', key: 'id' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Company', key: 'company.name' }
];

const exportSettings = { headers, filename: 'clients.csv' };

export const Users: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [includeInactive, setIncludeInactive] = useState(false);

  const { isOpen: isUserModalOpen, toggleModal: toggleUserModal } = useModal();
  const {
    isOpen: isUserFiltersModalOpen,
    toggleModal: toggleUserFiltersModal
  } = useModal();

  const { loading } = useGetBusinessUsersQuery({
    onCompleted: (data) => setUsers(data.getUsers)
  });

  const handleSelect = (user: User) => {
    setUserId(user.id);
    toggleUserModal();
  };

  const handleCreate = () => {
    setUserId(null);
    toggleUserModal();
  };

  const data = useMemo(() => {
    return includeInactive
      ? users
      : users.filter((user) => !user.deactivatedAt);
  }, [includeInactive, users]);

  return (
    <>
      <UserModal
        isOpen={isUserModalOpen}
        toggle={toggleUserModal}
        userId={userId}
      />
      <Table<User>
        loading={loading}
        data={data}
        columns={columns}
        handleSelect={handleSelect}
        sortBy={{
          id: 'name',
          desc: false
        }}
      >
        <>
          <Modal
            centered
            isOpen={isUserFiltersModalOpen}
            toggle={toggleUserFiltersModal}
          >
            <ModalHeader className="pb-0 pl-4">Search Filters</ModalHeader>
            <ModalBody>
              <Toggle
                label="Include Inactive Users"
                helperText="Enable to include inactive users in the search results."
                checked={includeInactive}
                onChange={() => setIncludeInactive(!includeInactive)}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleUserFiltersModal}>Close</Button>
            </ModalFooter>
          </Modal>
          <CSVLink
            data={Object.values(data)}
            filename={exportSettings.filename}
            headers={exportSettings.headers}
            target="_blank"
          >
            <Button size="sm" color="primary">
              Export
            </Button>
          </CSVLink>
          <Button
            size="sm"
            color="default"
            className="ml-2"
            onClick={handleCreate}
          >
            Create
          </Button>
          <Button size="sm" onClick={toggleUserFiltersModal}>
            <Filter size={14} color="#606E80" /> Filter
          </Button>
        </>
      </Table>
    </>
  );
};
