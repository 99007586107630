import React from 'react';
import classnames from 'classnames';
import { FormGroup, Label } from 'reactstrap';

export type InputWrapperProps = {
  label?: string;
  helperText?: string;
  error?: string;
};

export const InputWrapper: React.FC<
  React.PropsWithChildren<InputWrapperProps>
> = ({ label, helperText, error, children }) => (
  <FormGroup className={classnames({ 'mb-0': !label })}>
    {label && (
      <Label
        className={classnames('form-control-label', { 'mb-0': helperText })}
      >
        {label}
      </Label>
    )}
    {helperText && <p className="text-muted">{helperText}</p>}
    <div className={classnames({ 'border-danger': error })}>{children}</div>
    <span className="text-danger font-weight-normal h5">{error}</span>
  </FormGroup>
);
