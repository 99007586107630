import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import { SelectInput, TextArea, TextInput } from '../../../components';
import { resourceTypeOptions } from '../../../constants';
import { Resource } from './ResourceModal';
import { generateNumberOptions } from '../../../utils';

type ResourceFormProps = {
  form: UseFormReturn<Resource>;
};

export const ResourceForm: React.FC<ResourceFormProps> = ({
  form: {
    register,
    control,
    formState: { errors }
  }
}) => {
  return (
    <ModalBody>
      <Row>
        <Col xs="12">
          <TextInput
            label="Name"
            error={errors.name?.message}
            placeholder="Name"
            {...register('name', {
              required: 'Name is required'
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <TextArea
            label="Description"
            error={errors.description?.message}
            placeholder="Description"
            rows={5}
            {...register('description')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Controller
            control={control}
            name="type"
            rules={{
              required: 'Type is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="Type"
                error={errors.type?.message}
                placeholder="Select Type"
                options={resourceTypeOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col xs="6">
          <Controller
            control={control}
            name="capacity"
            rules={{
              required: 'Capacity is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="Capacity"
                error={errors.capacity?.message}
                placeholder="Select Capacity"
                options={generateNumberOptions(8)}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
