import { pick } from 'lodash';
import {
  CreateAnnouncementInput,
  UpdateAnnouncementInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useCreateBusinessAnnouncementMutation,
  useDeleteBusinessAnnouncementMutation,
  useGetBusinessAnnouncementLazyQuery,
  useUpdateBusinessAnnouncementMutation
} from './__generated__/graphql';
import { useMemo } from 'react';
import { Announcement } from './AnnouncementModal';

type UseAnnouncementActionsOptions = {
  toggle: () => void;
  toggleDeleteAnnouncementModal: () => void;
};

const baseAnnouncementKeys: Array<
  keyof CreateAnnouncementInput & keyof UpdateAnnouncementInput
> = ['title', 'body', 'startDate', 'endDate'];

export const useAnnouncementActions = ({
  toggle,
  toggleDeleteAnnouncementModal
}: UseAnnouncementActionsOptions) => {
  const [
    getAnnouncement,
    { data: getAnnouncementData, loading: getAnnouncementLoading }
  ] = useGetBusinessAnnouncementLazyQuery();

  const announcement = useMemo(
    () => getAnnouncementData?.getAnnouncement,
    [getAnnouncementData]
  );

  const [createAnnouncement, { loading: createAnnouncementLoading }] =
    useCreateBusinessAnnouncementMutation({
      refetchQueries: [namedOperations.Query.GetBusinessAnnouncements],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Announcement successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating announcement!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateAnnouncement, { loading: updateAnnouncementLoading }] =
    useUpdateBusinessAnnouncementMutation({
      refetchQueries: [namedOperations.Query.GetBusinessAnnouncements],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Announcement successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating announcement!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [deleteAnnouncement, { loading: deleteAnnouncementLoading }] =
    useDeleteBusinessAnnouncementMutation({
      refetchQueries: [namedOperations.Query.GetBusinessAnnouncements],
      onCompleted: () => {
        toggleDeleteAnnouncementModal();
        toggle();
        notify({
          message: 'Announcement successfully deleted!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deleting announcement!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateAnnouncement = async (data: Announcement) => {
    await createAnnouncement({
      variables: { data: pick(data, baseAnnouncementKeys) }
    });
  };

  const handleUpdateAnnouncement = async (data: Announcement) => {
    if (announcement) {
      await updateAnnouncement({
        variables: {
          id: announcement.id,
          data: pick(data, baseAnnouncementKeys)
        }
      });
    }
  };

  const handleDeleteAnnouncement = async () => {
    if (announcement) {
      const options = {
        variables: {
          id: announcement.id
        }
      };

      await deleteAnnouncement(options);
    }
  };

  return {
    announcement: getAnnouncementData?.getAnnouncement,
    getAnnouncement,
    getAnnouncementLoading,
    handleCreateAnnouncement,
    createAnnouncementLoading,
    handleUpdateAnnouncement,
    updateAnnouncementLoading,
    handleDeleteAnnouncement,
    deleteAnnouncementLoading
  };
};
