import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetResourceOptionsQueryVariables = Types.Exact<{
  includeInactive: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetResourceOptionsQuery = { getResources: Array<{ id: string, name: string }> };


export const GetResourceOptionsDocument = gql`
    query GetResourceOptions($includeInactive: Boolean) {
  getResources(includeInactive: $includeInactive) {
    id
    name
  }
}
    `;

/**
 * __useGetResourceOptionsQuery__
 *
 * To run a query within a React component, call `useGetResourceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceOptionsQuery({
 *   variables: {
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useGetResourceOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceOptionsQuery, GetResourceOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceOptionsQuery, GetResourceOptionsQueryVariables>(GetResourceOptionsDocument, options);
      }
export function useGetResourceOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceOptionsQuery, GetResourceOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceOptionsQuery, GetResourceOptionsQueryVariables>(GetResourceOptionsDocument, options);
        }
export type GetResourceOptionsQueryHookResult = ReturnType<typeof useGetResourceOptionsQuery>;
export type GetResourceOptionsLazyQueryHookResult = ReturnType<typeof useGetResourceOptionsLazyQuery>;
export type GetResourceOptionsQueryResult = Apollo.QueryResult<GetResourceOptionsQuery, GetResourceOptionsQueryVariables>;