import React, { useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import {
  GetCompanyNotificationPreferencesQuery,
  useGetCompanyNotificationPreferencesQuery
} from './__generated__/graphql';
import { useModal } from '../../../hooks';
import { CompanyNotificationPreferenceModal } from './CompanyNotificationPreferenceModal';
import { Pulse } from '../../core';
import { logType } from '../../../constants';

type CompanyNotificationPreferencesTableProps = {
  companyId: string;
};

export type UserWithNotificationPreference =
  GetCompanyNotificationPreferencesQuery['getCompany']['users'][0];

export const CompanyNotificationPreferencesTable: React.FC<
  CompanyNotificationPreferencesTableProps
> = ({ companyId }) => {
  const [selectedUser, setSelectedUser] =
    useState<UserWithNotificationPreference | null>(null);

  const [companyNotificationPreferences, setCompanyNotificationPreferences] =
    useState<UserWithNotificationPreference[]>([]);

  const {
    isOpen: isCompanyNotificationPreferenceModalOpen,
    toggleModal: toggleCompanyNotificationPreferenceModal
  } = useModal();

  const { loading: companyNotificationPreferencesLoading } =
    useGetCompanyNotificationPreferencesQuery({
      onCompleted: (data) =>
        setCompanyNotificationPreferences(
          data.getCompany.users.filter((user) => !user.deactivatedAt)
        ),
      variables: {
        companyId
      }
    });

  const handleSelect = (user: UserWithNotificationPreference) => {
    setSelectedUser(user);
    toggleCompanyNotificationPreferenceModal();
  };

  if (companyNotificationPreferencesLoading) {
    return <Pulse />;
  }

  return (
    <>
      <CompanyNotificationPreferenceModal
        isOpen={isCompanyNotificationPreferenceModalOpen}
        toggle={toggleCompanyNotificationPreferenceModal}
        user={selectedUser}
      />
      <Row>
        <Col xs="12">
          {companyNotificationPreferences.length === 0 && (
            <p className="mb-0">
              No users found for this company to set notification preferences.
            </p>
          )}
          {companyNotificationPreferences.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Email</th>
                  <th>Log Types</th>
                </tr>
              </thead>
              <tbody>
                {companyNotificationPreferences.map((user) => (
                  <tr key={user.id} onClick={() => handleSelect(user)}>
                    <td className="wrap-cell">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="wrap-cell">{user.email}</td>
                    <td className="wrap-cell">
                      {user.notificationPreference.logTypes
                        .map((type) => logType[type])
                        .join(', ') || '—'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
