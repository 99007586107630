import React, { useState } from 'react';

import {
  BarChart2,
  Book,
  Box,
  Briefcase,
  Calendar,
  // Eye,
  Globe,
  Home,
  Info,
  List,
  LogOut,
  Settings,
  User,
  Users,
  XCircle
} from 'react-feather';

import Logo from '../../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { UserRole } from '../../../__generated__/graphql';

export type NavigationProps = {
  role: UserRole;
};

type NavigationItem = {
  label: string;
  icon?: JSX.Element;
  path: string;
  items?: never;
};

type NavigationDropdownItem = {
  label: string;
  icon: JSX.Element;
  items: NavigationItem[];
};

type Navigation = {
  [key in UserRole]: (NavigationItem | NavigationDropdownItem)[];
};

const navigation: Navigation = {
  [UserRole.Client]: [
    {
      label: 'Dashboard',
      icon: <Home />,
      path: '/dashboard'
    },
    {
      label: 'Book',
      icon: <Book />,
      path: '/schedule/search'
    },
    {
      label: 'Calendar',
      icon: <Calendar />,
      path: '/calendar'
    },
    {
      label: 'Account',
      icon: <User />,
      path: '/account'
    },
    {
      label: 'Logout',
      icon: <LogOut />,
      path: '/logout'
    }
  ],
  [UserRole.Business]: [
    {
      label: 'Dashboard',
      icon: <Home />,
      path: '/dashboard'
    },
    {
      label: 'Calendar',
      icon: <Calendar />,
      path: '/business/calendar'
    },
    {
      label: 'Business',
      icon: <Briefcase />,
      items: [
        {
          label: 'Users',
          icon: <Users />,
          path: '/business/users'
        },
        {
          label: 'Companies',
          icon: <Globe />,
          path: '/business/companies'
        },
        {
          label: 'Resources',
          icon: <Box />,
          path: '/business/resources'
        },
        {
          label: 'Announcements',
          icon: <Info />,
          path: '/business/announcements'
        },
        {
          label: 'Block Outs',
          icon: <XCircle />,
          path: '/business/blockouts'
        }
      ]
    },
    {
      label: `Captain's log`,
      icon: <List />,
      path: '/business/logs'
    },
    {
      label: 'Analytics',
      icon: <BarChart2 />,
      items: [
        {
          label: 'Company Usage',
          path: '/business/reports/company-usage'
        },
        {
          label: `Captain's Log`,
          path: '/business/reports/captains-log'
        }
      ]
    },
    {
      label: 'Settings',
      icon: <Settings />,
      path: '/business/settings'
    },
    {
      label: 'Account',
      icon: <User />,
      path: '/account'
    },
    {
      label: 'Logout',
      icon: <LogOut />,
      path: '/logout'
    }
  ]
};

const NavigationItem: React.FC<NavigationItem> = ({ path, label, icon }) => (
  <NavLink to={path} className="list-group-item">
    {icon && <div className="mr-3">{icon}</div>}
    {label}
  </NavLink>
);

const NavigationDropdown: React.FC<NavigationDropdownItem> = ({
  label,
  icon,
  items
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleItemClick = () => setDropdownOpen(false);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      direction="right"
    >
      <DropdownToggle nav caret className="list-group-item">
        <div className="mr-3">{icon}</div>
        {label}
      </DropdownToggle>
      <DropdownMenu>
        {items.map((item) => (
          <div key={item.label} onClick={handleItemClick}>
            <NavigationItem {...item} />
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export const Navigation: React.FC<NavigationProps> = ({ role }) => {
  return (
    <div className="toggled">
      <div id="sidebar-wrapper" className="shadow">
        <div className="sidebar-heading pt-4 pb-5">
          <img src={Logo} alt="Logo" width="150" />
        </div>
        {navigation[role].map((item) => {
          if (item.items) {
            return <NavigationDropdown key={item.label} {...item} />;
          } else {
            return <NavigationItem key={item.label} {...item} />;
          }
        })}
      </div>
    </div>
  );
};
