import {
  Calendar as BigCalendar,
  Views,
  CalendarProps
} from 'react-big-calendar';
import Color from 'color';
import 'react-big-calendar/lib/css/react-big-calendar.css';

export const DEFAULT_HEX_COLOR = '#5e72e4';

export const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => (
  <BigCalendar
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventPropGetter={(event: any) => {
      const hexColor = Color(DEFAULT_HEX_COLOR);
      const backgroundColor =
        hexColor.luminosity() > 0.6 ? hexColor : hexColor.lighten(0.4);
      const borderLeft = hexColor.darken(hexColor.luminosity() * 0.4);
      return {
        style: {
          backgroundColor: backgroundColor.toString(),
          borderLeft: `7.5px solid ${borderLeft}`
        },
        ...(event.status === 'COMPLETED' && { className: 'rbc-event-disabled' })
      };
    }}
    views={['day']}
    min={new Date(2022, 10, 0, 6, 0, 0)}
    max={new Date(2022, 10, 0, 20, 0, 0)}
    toolbar={false}
    step={15}
    timeslots={4}
    formats={{
      timeGutterFormat: (date, culture, localizer) =>
        localizer!.format(date, 'h:mm A', culture),
      dayFormat: (date, culture, localizer) =>
        localizer!.format(date, 'ddd DD', culture),
      dayRangeHeaderFormat: (date, culture, localizer) =>
        localizer!.format(date.start, 'YYYY', culture)
    }}
    defaultView={Views.DAY}
    {...props}
  />
);
