import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessBlockoutsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessBlockoutsQuery = { getBlockouts: Array<{ id: string, description: string | null, date: string }> };

export type CreateBusinessBlockoutMutationVariables = Types.Exact<{
  data: Types.CreateBlockoutInput;
}>;


export type CreateBusinessBlockoutMutation = { createBlockout: { id: string } };

export type DeleteBusinessBlockoutMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteBusinessBlockoutMutation = { deleteBlockout: { id: string } };


export const GetBusinessBlockoutsDocument = gql`
    query GetBusinessBlockouts {
  getBlockouts {
    id
    description
    date
  }
}
    `;

/**
 * __useGetBusinessBlockoutsQuery__
 *
 * To run a query within a React component, call `useGetBusinessBlockoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessBlockoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessBlockoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessBlockoutsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessBlockoutsQuery, GetBusinessBlockoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessBlockoutsQuery, GetBusinessBlockoutsQueryVariables>(GetBusinessBlockoutsDocument, options);
      }
export function useGetBusinessBlockoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessBlockoutsQuery, GetBusinessBlockoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessBlockoutsQuery, GetBusinessBlockoutsQueryVariables>(GetBusinessBlockoutsDocument, options);
        }
export type GetBusinessBlockoutsQueryHookResult = ReturnType<typeof useGetBusinessBlockoutsQuery>;
export type GetBusinessBlockoutsLazyQueryHookResult = ReturnType<typeof useGetBusinessBlockoutsLazyQuery>;
export type GetBusinessBlockoutsQueryResult = Apollo.QueryResult<GetBusinessBlockoutsQuery, GetBusinessBlockoutsQueryVariables>;
export const CreateBusinessBlockoutDocument = gql`
    mutation CreateBusinessBlockout($data: CreateBlockoutInput!) {
  createBlockout(data: $data) {
    id
  }
}
    `;
export type CreateBusinessBlockoutMutationFn = Apollo.MutationFunction<CreateBusinessBlockoutMutation, CreateBusinessBlockoutMutationVariables>;

/**
 * __useCreateBusinessBlockoutMutation__
 *
 * To run a mutation, you first call `useCreateBusinessBlockoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessBlockoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessBlockoutMutation, { data, loading, error }] = useCreateBusinessBlockoutMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusinessBlockoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessBlockoutMutation, CreateBusinessBlockoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessBlockoutMutation, CreateBusinessBlockoutMutationVariables>(CreateBusinessBlockoutDocument, options);
      }
export type CreateBusinessBlockoutMutationHookResult = ReturnType<typeof useCreateBusinessBlockoutMutation>;
export type CreateBusinessBlockoutMutationResult = Apollo.MutationResult<CreateBusinessBlockoutMutation>;
export type CreateBusinessBlockoutMutationOptions = Apollo.BaseMutationOptions<CreateBusinessBlockoutMutation, CreateBusinessBlockoutMutationVariables>;
export const DeleteBusinessBlockoutDocument = gql`
    mutation DeleteBusinessBlockout($id: UUID!) {
  deleteBlockout(id: $id) {
    id
  }
}
    `;
export type DeleteBusinessBlockoutMutationFn = Apollo.MutationFunction<DeleteBusinessBlockoutMutation, DeleteBusinessBlockoutMutationVariables>;

/**
 * __useDeleteBusinessBlockoutMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessBlockoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessBlockoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessBlockoutMutation, { data, loading, error }] = useDeleteBusinessBlockoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessBlockoutMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessBlockoutMutation, DeleteBusinessBlockoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessBlockoutMutation, DeleteBusinessBlockoutMutationVariables>(DeleteBusinessBlockoutDocument, options);
      }
export type DeleteBusinessBlockoutMutationHookResult = ReturnType<typeof useDeleteBusinessBlockoutMutation>;
export type DeleteBusinessBlockoutMutationResult = Apollo.MutationResult<DeleteBusinessBlockoutMutation>;
export type DeleteBusinessBlockoutMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessBlockoutMutation, DeleteBusinessBlockoutMutationVariables>;