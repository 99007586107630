import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Badge, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { Calendar, ExternalLink, Info, Trash2 } from 'react-feather';
import { faArrowsRotate, faBell } from '@fortawesome/free-solid-svg-icons';
import { LogStatus, LogTag, LogType } from '../../../../__generated__/graphql';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  DropdownMenuItem,
  ModalType,
  Pulse,
  SelectInput
} from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useModal, useUserOptions } from '../../../../hooks';
import { LogForm } from './LogForm';
import { useLogActions } from './useLogActions';
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form';
import { useLogContext } from '../../../../contexts';
import { GetLogQuery } from '../__generated__/graphql';
import { logStatus } from '../../../../constants';

const logStatusColor: { [key in LogStatus]: string } = {
  [LogStatus.Completed]: 'default',
  [LogStatus.New]: 'info',
  [LogStatus.InProgress]: 'primary',
  [LogStatus.Deleted]: 'danger'
};

export type Log = {
  companyId: string | null;
  notes: string | null;
  internalNotes: string | null;
  type: LogType | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressZip: string | null;
  tags: LogTag[];
  rating: number | null;
  appointmentAt: string | null;
  fileUploads: { id: string; fileName: string; url: string }[];
};

const defaultLog: Log = {
  companyId: null,
  notes: null,
  internalNotes: null,
  type: null,
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  addressLine1: null,
  addressLine2: null,
  addressCity: null,
  addressState: null,
  addressZip: null,
  tags: [],
  rating: null,
  appointmentAt: null,
  fileUploads: []
};

type LogCardProps = {
  logId: string;
  isReadOnly?: boolean;
  isModal?: boolean;
  onClose?: () => void;
};

export const LogCard: React.FC<LogCardProps> = ({
  logId,
  isReadOnly = false,
  isModal = false,
  onClose
}) => {
  const form = useForm<Log>({
    defaultValues: defaultLog,
    mode: 'onChange'
  });

  const { control, formState, handleSubmit } = form;

  const formData = useWatch({ control });
  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!formState.errors.phoneNumber) {
        setPhoneNumber(formData.phoneNumber ?? null);
      } else {
        setPhoneNumber(null);
      }

      if (logId === parentLogId) {
        setCompanyId(formData.companyId ?? null);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [formState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (JSON.stringify(dirtyFields) !== '{}') {
        handleSubmit(({ fileUploads, ...data }) => {
          const fileUploadIds = fileUploads.map(({ id }) => id);
          updateLog(logId, { ...data, fileUploadIds }).then(() =>
            handleFetchLog(logId)
          );
        })();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [JSON.stringify(formData), dirtyFields]);

  const {
    logId: parentLogId,
    companyId,
    setCompanyId,
    setPhoneNumber
  } = useLogContext();

  const [log, setLog] = useState<GetLogQuery['getLog']>();
  const [showSavingIndicator, setShowSavingIndicator] = useState(false);

  const {
    fetchLog,
    deleteLog,
    deleteLogLoading,
    updateLog,
    updateLogLoading,
    completeLog,
    completeLogLoading
  } = useLogActions();

  const handleFetchLog = useCallback(
    async (logId: string) => {
      const data = await fetchLog(logId);
      if (data) {
        setLog(data);

        if (!log) {
          form.reset(data);
        }

        /**
         * The parentLogId is used to indicate the log currently being edited
         * or viewed at the top level. The logId is the specific ID for this instance
         * of LogCard, which can be different if the LogCard is being viewed in the context
         * of interaction history.
         */

        if (logId === parentLogId) {
          setCompanyId(data.companyId);
          setPhoneNumber(data.phoneNumber);
        }
      }
    },
    [fetchLog, form, parentLogId, setCompanyId, setPhoneNumber]
  );

  useEffect(() => {
    handleFetchLog(logId);
  }, [logId, parentLogId]);

  useEffect(() => {
    if (updateLogLoading) {
      setShowSavingIndicator(true);
    } else {
      const timer = setTimeout(() => setShowSavingIndicator(false), 500);
      return () => clearTimeout(timer);
    }
  }, [updateLogLoading]);

  const navigate = useNavigate();

  const { isOpen: isDeleteLogModalOpen, toggleModal: toggleDeleteLogModal } =
    useModal();

  const {
    isOpen: isCompleteLogModalOpen,
    toggleModal: toggleCompleteLogModal
  } = useModal();

  const handleOpenInNewTab = () => window.open(window.location.href, '_blank');

  const userOptions = useUserOptions({ filterByCompanyId: companyId });

  const [notifyUserIds, setNotifyUserIds] = useState<string[]>([]);

  useEffect(() => {
    if (!companyId || !log?.type) {
      setNotifyUserIds([]);
    } else {
      setNotifyUserIds(
        log?.company?.users
          .filter(
            (user) =>
              log.type &&
              !user.deactivatedAt &&
              user.notificationPreference.logTypes.includes(log.type)
          )
          .map((user) => user.id) ?? []
      );
    }
  }, [log, companyId]);

  const loading = deleteLogLoading || updateLogLoading || completeLogLoading;

  const isCompleted = log?.status === LogStatus.Completed;

  const menuItems: DropdownMenuItem[] = [
    {
      label: 'Open in New Tab',
      icon: <ExternalLink size={14} />,
      onClick: handleOpenInNewTab
    },
    {
      label: 'Delete',
      icon: <Trash2 size={14} />,
      onClick: toggleDeleteLogModal,
      hide: isCompleted
    }
  ];

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isDeleteLogModalOpen}
        toggle={toggleDeleteLogModal}
        title="Delete Log"
        body={
          <p>
            Are you sure you want to delete this log? This action can not be
            reversed.
          </p>
        }
        loading={deleteLogLoading}
        confirmationText="Yes, delete"
        onConfirm={() => deleteLog(logId)}
      />
      <ConfirmationModal
        type={ModalType.Info}
        isOpen={isCompleteLogModalOpen}
        toggle={toggleCompleteLogModal}
        title="Complete Log"
        body={
          <>
            {companyId && (
              <SelectInput
                isMulti
                label="Notify Users"
                helperText="Please select the users to notify about this log"
                placeholder="Select Users"
                options={userOptions}
                value={notifyUserIds}
                onChange={setNotifyUserIds}
              />
            )}
            <p>Are you sure you want to complete this log?</p>
          </>
        }
        loading={completeLogLoading}
        confirmationText="Yes, complete"
        onConfirm={() => completeLog(logId, notifyUserIds)}
      />
      <Card className="mb-0 pb-0 flex-grow-1" style={{ maxHeight: '90vh' }}>
        {log ? (
          <>
            <CardHeader className="d-flex justify-content-between">
              <table className="log-table">
                <tbody>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faCircleUser} className="mr-2" />{' '}
                      Assignee
                    </td>
                    <td>{`${log.author.firstName} ${log.author.lastName}`}</td>
                  </tr>
                  <tr>
                    <td>
                      <Calendar size={14} className="mr-2" /> Date & Time
                    </td>
                    <td>{`${moment(log.createdAt).format('dddd, MMMM Do YYYY [at] h:mm A')}`}</td>
                  </tr>
                  <tr>
                    <td>
                      <Info size={14} className="mr-2" /> Status
                    </td>
                    <td>
                      <Badge color={logStatusColor[log.status]} pill>
                        {logStatus[log.status]}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faArrowsRotate} className="mr-2" />{' '}
                      Last Updated
                    </td>
                    <td>
                      {showSavingIndicator
                        ? 'Saving...'
                        : `${moment(log.updatedAt).fromNow()}`}
                    </td>
                  </tr>
                  <tr>
                    <td className="wrap-cell">
                      <FontAwesomeIcon icon={faBell} className="mr-2" /> Last
                      Notified
                    </td>
                    <td>
                      {log?.notifications.length > 0 ? (
                        <>
                          Sent by email to{' '}
                          {log?.notifications
                            .map(
                              ({ user, metadata: { to } }) =>
                                `${user.firstName} ${user.lastName} (${to})`
                            )
                            .join(', ')}{' '}
                          on{' '}
                          {moment(log?.notifications[0]?.timestamp).format(
                            'MMMM Do YYYY [at] h:mm A'
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {!isReadOnly && <DropdownMenu items={menuItems} />}
            </CardHeader>
            <CardBody style={{ overflow: 'scroll' }}>
              <FormProvider {...form}>
                <LogForm isReadOnly={isReadOnly || isCompleted} />
              </FormProvider>
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button
                color="secondary"
                disabled={loading}
                onClick={() =>
                  isModal ? onClose?.() : navigate('/business/logs')
                }
              >
                {isModal ? 'Close' : 'Back'}
              </Button>
              {!isReadOnly && !isCompleted && (
                <Button
                  style={{ width: '115px' }}
                  color="primary"
                  disabled={loading}
                  onClick={toggleCompleteLogModal}
                >
                  Complete
                </Button>
              )}
            </CardFooter>
          </>
        ) : (
          <Pulse />
        )}
      </Card>
    </>
  );
};
