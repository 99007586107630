import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { useGetAccountInformationQuery } from './__generated__/graphql';
import { UserInformationCard } from './UserInformationCard';
import { CompanyInformationCard } from './CompanyInformationCard';
import { Pulse } from '../../components';

export const Account: React.FC = () => {
  const { data, loading } = useGetAccountInformationQuery();

  const user = data?.currentUser;

  if (loading || !user) {
    return <Pulse />;
  }

  return (
    <Row>
      <Col lg="8" md="7" sm="12" className="d-flex flex-column">
        <UserInformationCard user={user} />
      </Col>
      <Col lg="4" md="5" sm="12" className="d-flex flex-column">
        <CompanyInformationCard company={user.company} />
      </Col>
      <Col>
        <Row>
          <Col>
            <Link to="/logout">
              <Button color="danger">Logout</Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
