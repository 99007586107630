export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AccountNumber: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Byte: { input: any; output: any; }
  CountryCode: { input: any; output: any; }
  Cuid: { input: any; output: any; }
  Currency: { input: any; output: any; }
  DID: { input: any; output: any; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  DateTimeISO: { input: any; output: any; }
  DeweyDecimal: { input: any; output: any; }
  Duration: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  GUID: { input: any; output: any; }
  HSL: { input: any; output: any; }
  HSLA: { input: any; output: any; }
  HexColorCode: { input: any; output: any; }
  Hexadecimal: { input: any; output: any; }
  IBAN: { input: any; output: any; }
  IP: { input: any; output: any; }
  IPCPatent: { input: any; output: any; }
  IPv4: { input: any; output: any; }
  IPv6: { input: any; output: any; }
  ISBN: { input: any; output: any; }
  ISO8601Duration: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  JWT: { input: any; output: any; }
  LCCSubclass: { input: any; output: any; }
  Latitude: { input: any; output: any; }
  LocalDate: { input: any; output: any; }
  LocalDateTime: { input: any; output: any; }
  LocalEndTime: { input: any; output: any; }
  LocalTime: { input: any; output: any; }
  Locale: { input: any; output: any; }
  Long: { input: any; output: any; }
  Longitude: { input: any; output: any; }
  MAC: { input: any; output: any; }
  NegativeFloat: { input: any; output: any; }
  NegativeInt: { input: any; output: any; }
  NonEmptyString: { input: any; output: any; }
  NonNegativeFloat: { input: any; output: any; }
  NonNegativeInt: { input: any; output: any; }
  NonPositiveFloat: { input: any; output: any; }
  NonPositiveInt: { input: any; output: any; }
  ObjectID: { input: any; output: any; }
  PhoneNumber: { input: any; output: any; }
  Port: { input: any; output: any; }
  PositiveFloat: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
  PostalCode: { input: any; output: any; }
  RGB: { input: any; output: any; }
  RGBA: { input: any; output: any; }
  RoutingNumber: { input: any; output: any; }
  SafeInt: { input: any; output: any; }
  SemVer: { input: any; output: any; }
  Time: { input: any; output: any; }
  TimeZone: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
  URL: { input: any; output: any; }
  USCurrency: { input: any; output: any; }
  UUID: { input: string; output: string; }
  UnsignedFloat: { input: any; output: any; }
  UnsignedInt: { input: any; output: any; }
  Upload: { input: any; output: any; }
  UtcOffset: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export enum AppointmentField {
  Buffer = 'buffer',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  EndAt = 'endAt',
  Id = 'id',
  InternalNotes = 'internalNotes',
  Notes = 'notes',
  StartAt = 'startAt',
  UpdatedAt = 'updatedAt'
}

export type AppointmentFilters = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type AppointmentOptions = {
  notifyUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AppointmentOrderByOptions = {
  field: AppointmentField;
  sort: SortDirection;
};

export enum AppointmentStatus {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Inprogress = 'INPROGRESS'
}

export enum CompanyPhoneTier {
  Black = 'Black',
  Blue = 'Blue',
  White = 'White'
}

export type CreateAnnouncementInput = {
  body: Scalars['String']['input'];
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  title: Scalars['String']['input'];
};

export type CreateAppointmentInput = {
  endAt: Scalars['String']['input'];
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['UUID']['input'];
  startAt: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateBlockoutInput = {
  date: Scalars['Date']['input'];
  description: Scalars['String']['input'];
};

export type CreateCompanyAlertInput = {
  companyId: Scalars['UUID']['input'];
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  note: Scalars['String']['input'];
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateCompanyInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  intake?: InputMaybe<Scalars['String']['input']>;
  memberSince?: InputMaybe<Scalars['Date']['input']>;
  membershipId: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneTier?: InputMaybe<CompanyPhoneTier>;
  tollNumber?: InputMaybe<Scalars['String']['input']>;
  training?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyLocationInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressZip?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['UUID']['input'];
  isPrimary: Scalars['Boolean']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type CreateResourceInput = {
  capacity: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: ResourceType;
};

export type CreateUserInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  companyId: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  role: UserRole;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DateRangeOptions = {
  endAt: Scalars['DateTime']['input'];
  startAt: Scalars['DateTime']['input'];
};

export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export enum EventAction {
  Create = 'Create',
  Delete = 'Delete',
  Read = 'Read',
  Update = 'Update'
}

export type GetAppointmentsOptions = {
  dateRange?: InputMaybe<DateRangeOptions>;
  filters?: InputMaybe<AppointmentFilters>;
  includeCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<AppointmentOrderByOptions>;
  pagination?: InputMaybe<PaginationOptions>;
};

export type GetAvailableResourcesOptions = {
  capacity: Scalars['NonNegativeInt']['input'];
  dateRange: DateRangeOptions;
};

export type GetLogsOptions = {
  dateRange?: InputMaybe<DateRangeOptions>;
  filters?: InputMaybe<LogFilters>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<LogOrderByOptions>;
  pagination?: InputMaybe<PaginationOptions>;
};

export enum LogField {
  AddressCity = 'addressCity',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AddressState = 'addressState',
  AddressZip = 'addressZip',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  InternalNotes = 'internalNotes',
  LastName = 'lastName',
  Notes = 'notes',
  PhoneNumber = 'phoneNumber',
  UpdatedAt = 'updatedAt'
}

export type LogFilters = {
  authorId?: InputMaybe<Scalars['UUID']['input']>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LogStatus>;
  type?: InputMaybe<LogType>;
};

export type LogOrderByOptions = {
  field: LogField;
  sort: SortDirection;
};

export enum LogStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export enum LogTag {
  AppointmentCancelled = 'APPOINTMENT_CANCELLED',
  AppointmentScheduled = 'APPOINTMENT_SCHEDULED',
  Cali = 'CALI',
  Concierged = 'CONCIERGED',
  Connected = 'CONNECTED',
  Live = 'LIVE',
  NameVerified = 'NAME_VERIFIED',
  Notified = 'NOTIFIED',
  NoAction = 'NO_ACTION',
  Prospect = 'PROSPECT',
  Robot = 'ROBOT'
}

export enum LogType {
  Awareness = 'AWARENESS',
  Dropoff = 'DROPOFF',
  Email = 'EMAIL',
  Fax = 'FAX',
  Other = 'OTHER',
  PhoneCall = 'PHONE_CALL',
  Sms = 'SMS',
  Traffic = 'TRAFFIC',
  Voicemail = 'VOICEMAIL'
}

export type PaginationOptions = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export enum ResourceType {
  Conference = 'CONFERENCE',
  Coworking = 'COWORKING',
  Private = 'PRIVATE'
}

export type SetNotificationPreferenceInput = {
  logTypes: Array<LogType>;
  userId: Scalars['UUID']['input'];
};

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type UpdateAnnouncementInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAppointmentInput = {
  endAt?: InputMaybe<Scalars['String']['input']>;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['UUID']['input']>;
  startAt?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateBusinessHourInput = {
  closeTime: Scalars['String']['input'];
  day: DayOfWeek;
  id: Scalars['UUID']['input'];
  openTime: Scalars['String']['input'];
};

export type UpdateBusinessInput = {
  appointmentBookingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentBuffer?: InputMaybe<Scalars['Int']['input']>;
  appointmentMaxBookingDays?: InputMaybe<Scalars['Int']['input']>;
  maintenanceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyAlertInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  note: Scalars['String']['input'];
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateCompanyInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  intake?: InputMaybe<Scalars['String']['input']>;
  memberSince?: InputMaybe<Scalars['Date']['input']>;
  membershipId?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneTier?: InputMaybe<CompanyPhoneTier>;
  tollNumber?: InputMaybe<Scalars['String']['input']>;
  training?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyLocationInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressZip?: InputMaybe<Scalars['String']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLogInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressZip?: InputMaybe<Scalars['String']['input']>;
  appointmentAt?: InputMaybe<Scalars['DateTime']['input']>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fileUploadIds: Array<Scalars['UUID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<LogStatus>;
  tags: Array<LogTag>;
  type?: InputMaybe<LogType>;
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type UpdateResourceInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ResourceType>;
};

export type UpdateUserInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum UserRole {
  Business = 'Business',
  Client = 'Client'
}

export const namedOperations = {
  Query: {
    GetCompanyAlerts: 'GetCompanyAlerts',
    GetCompanyAlert: 'GetCompanyAlert',
    GetCompanyLocations: 'GetCompanyLocations',
    GetCompanyLocation: 'GetCompanyLocation',
    GetCompanyNotificationPreferences: 'GetCompanyNotificationPreferences',
    GetCompanyUsage: 'GetCompanyUsage',
    GetFileUpload: 'GetFileUpload',
    GetCompanyForLog: 'GetCompanyForLog',
    GetInteractionHistory: 'GetInteractionHistory',
    GetBusinessHours: 'GetBusinessHours',
    GetBusinessSettings: 'GetBusinessSettings',
    GetCompanyOptions: 'GetCompanyOptions',
    GetBusinessLogs: 'GetBusinessLogs',
    GetMembershipOptions: 'GetMembershipOptions',
    GetResourceOptions: 'GetResourceOptions',
    GetUserRole: 'GetUserRole',
    GetCurrentUser: 'GetCurrentUser',
    GetUserOptions: 'GetUserOptions',
    GetAccountInformation: 'GetAccountInformation',
    GetBusinessAnnouncements: 'GetBusinessAnnouncements',
    GetBusinessAnnouncement: 'GetBusinessAnnouncement',
    GetBusinessBlockouts: 'GetBusinessBlockouts',
    GetBusinessCalendar: 'GetBusinessCalendar',
    GetBusinessAppointment: 'GetBusinessAppointment',
    GetLog: 'GetLog',
    GetBusinessCompanies: 'GetBusinessCompanies',
    GetBusinessCompany: 'GetBusinessCompany',
    GetCompanyAlertsCount: 'GetCompanyAlertsCount',
    GetLogReport: 'GetLogReport',
    GetCompanyUsageReport: 'GetCompanyUsageReport',
    GetUsageReportForCompany: 'GetUsageReportForCompany',
    GetBusinessResources: 'GetBusinessResources',
    GetBusinessResource: 'GetBusinessResource',
    GetGeneralBusinessSettings: 'GetGeneralBusinessSettings',
    GetBusinessHoursSettings: 'GetBusinessHoursSettings',
    GetBusinessUsers: 'GetBusinessUsers',
    GetBusinessUser: 'GetBusinessUser',
    GetUserCalendar: 'GetUserCalendar',
    GetUserAppointment: 'GetUserAppointment',
    GetDashboardAnnouncements: 'GetDashboardAnnouncements',
    GetDashboardBlockouts: 'GetDashboardBlockouts',
    GetDashboardAppointments: 'GetDashboardAppointments',
    GetResourceForAppointment: 'GetResourceForAppointment',
    GetAppointmentConfirmation: 'GetAppointmentConfirmation',
    GetAvailableResources: 'GetAvailableResources'
  },
  Mutation: {
    CreateCompanyAlert: 'CreateCompanyAlert',
    UpdateCompanyAlert: 'UpdateCompanyAlert',
    DeleteCompanyAlert: 'DeleteCompanyAlert',
    CreateCompanyLocation: 'CreateCompanyLocation',
    UpdateCompanyLocation: 'UpdateCompanyLocation',
    DeleteCompanyLocation: 'DeleteCompanyLocation',
    SetUserNotificationPreference: 'SetUserNotificationPreference',
    UploadFiles: 'UploadFiles',
    CreateBusinessAnnouncement: 'CreateBusinessAnnouncement',
    UpdateBusinessAnnouncement: 'UpdateBusinessAnnouncement',
    DeleteBusinessAnnouncement: 'DeleteBusinessAnnouncement',
    CreateBusinessBlockout: 'CreateBusinessBlockout',
    DeleteBusinessBlockout: 'DeleteBusinessBlockout',
    CreateBusinessAppointment: 'CreateBusinessAppointment',
    UpdateBusinessAppointment: 'UpdateBusinessAppointment',
    CancelBusinessAppointment: 'CancelBusinessAppointment',
    CreateLog: 'CreateLog',
    UpdateLog: 'UpdateLog',
    CompleteLog: 'CompleteLog',
    DeleteLog: 'DeleteLog',
    CleanLogNotes: 'CleanLogNotes',
    CreateBusinessCompany: 'CreateBusinessCompany',
    UpdateBusinessCompany: 'UpdateBusinessCompany',
    ActivateBusinessCompany: 'ActivateBusinessCompany',
    DeactivateBusinessCompany: 'DeactivateBusinessCompany',
    CreateBusinessResource: 'CreateBusinessResource',
    UpdateBusinessResource: 'UpdateBusinessResource',
    ActivateBusinessResource: 'ActivateBusinessResource',
    DeactivateBusinessResource: 'DeactivateBusinessResource',
    UpdateBusinessSettings: 'UpdateBusinessSettings',
    UpdateBusinessHours: 'UpdateBusinessHours',
    CreateBusinessUser: 'CreateBusinessUser',
    UpdateBusinessUser: 'UpdateBusinessUser',
    ActivateBusinessUser: 'ActivateBusinessUser',
    DeactivateBusinessUser: 'DeactivateBusinessUser',
    SendPasswordReset: 'SendPasswordReset',
    UpdateUserAppointment: 'UpdateUserAppointment',
    CancelUserAppointment: 'CancelUserAppointment',
    Login: 'Login',
    TriggerPasswordReset: 'TriggerPasswordReset',
    UpdateUserPassword: 'UpdateUserPassword',
    CreateUserAppointment: 'CreateUserAppointment'
  }
}