import React from 'react';
import voca from 'voca';
import moment from 'moment-timezone';
import { Row, Col, FormGroup, CardBody, Card } from 'reactstrap';
import { GetAccountInformationQuery } from './__generated__/graphql';

type UserInformationCardProps = {
  user: GetAccountInformationQuery['currentUser'];
};

export const UserInformationCard: React.FC<UserInformationCardProps> = ({
  user
}) => {
  const formatBirthdate = (birthdate: string | null) =>
    birthdate ? moment(birthdate).format('MMMM Do, YYYY') : '';

  const fields = [
    { label: 'First Name', value: voca.capitalize(user.firstName) },
    { label: 'Last Name', value: voca.capitalize(user.lastName) },
    { label: 'Email', value: user.email },
    { label: 'Phone Number', value: user.phoneNumber },
    { label: 'Title', value: user.title },
    { label: 'Birthday 🎂', value: formatBirthdate(user.birthdate) }
  ].filter(({ value }) => value);

  return (
    <>
      <h2>User Information</h2>
      <Card className="flex-fill">
        <CardBody>
          <Row>
            {fields.map(({ label, value }) => (
              <Col xs="6" key={label}>
                <FormGroup>
                  <div className="h4">{label}</div>
                  <h5 className="text-muted font-weight-normal">{value}</h5>
                </FormGroup>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
