import { Option } from '../../types';
import { useGetResourceOptionsQuery } from './__generated__/graphql';

export const useResourceOptions = ({
  includeInactive = false
}: {
  includeInactive?: boolean;
} = {}): Option[] => {
  const { data } = useGetResourceOptionsQuery({
    variables: {
      includeInactive
    }
  });

  return (data?.getResources ?? []).map(
    ({ id, name }): Option => ({
      value: id,
      label: name
    })
  );
};
