import * as Types from '../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyUsageReportQueryVariables = Types.Exact<{
  dateRange: Types.DateRangeOptions;
}>;


export type GetCompanyUsageReportQuery = { getCompanies: Array<{ id: string, name: string, deactivatedAt: string | null, membership: { id: string, name: string, emoji: string }, usage: { hoursAllocated: number, hoursAvailable: number, hoursUtilized: number, percentUtilized: number } }> };

export type GetUsageReportForCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID']['input'];
  dateRange: Types.DateRangeOptions;
}>;


export type GetUsageReportForCompanyQuery = { getCompany: { id: string, name: string, memberSince: string | null, deactivatedAt: string | null, membership: { id: string, name: string, emoji: string }, usage: { hoursAllocated: number, hoursAvailable: number, hoursOverLimit: number, hoursUtilized: number, percentUtilized: number, isOverLimit: boolean, appointments: Array<{ id: string, startAt: string, endAt: string, status: Types.AppointmentStatus, notes: string | null, createdAt: string, resource: { id: string, name: string }, user: { id: string, firstName: string, lastName: string } }> } } };


export const GetCompanyUsageReportDocument = gql`
    query GetCompanyUsageReport($dateRange: DateRangeOptions!) {
  getCompanies(includeInactive: true) {
    id
    name
    membership {
      id
      name
      emoji
    }
    deactivatedAt
    usage(dateRange: $dateRange) {
      hoursAllocated
      hoursAvailable
      hoursUtilized
      percentUtilized
    }
  }
}
    `;

/**
 * __useGetCompanyUsageReportQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsageReportQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetCompanyUsageReportQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyUsageReportQuery, GetCompanyUsageReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUsageReportQuery, GetCompanyUsageReportQueryVariables>(GetCompanyUsageReportDocument, options);
      }
export function useGetCompanyUsageReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsageReportQuery, GetCompanyUsageReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUsageReportQuery, GetCompanyUsageReportQueryVariables>(GetCompanyUsageReportDocument, options);
        }
export type GetCompanyUsageReportQueryHookResult = ReturnType<typeof useGetCompanyUsageReportQuery>;
export type GetCompanyUsageReportLazyQueryHookResult = ReturnType<typeof useGetCompanyUsageReportLazyQuery>;
export type GetCompanyUsageReportQueryResult = Apollo.QueryResult<GetCompanyUsageReportQuery, GetCompanyUsageReportQueryVariables>;
export const GetUsageReportForCompanyDocument = gql`
    query GetUsageReportForCompany($companyId: UUID!, $dateRange: DateRangeOptions!) {
  getCompany(id: $companyId) {
    id
    name
    memberSince
    membership {
      id
      name
      emoji
    }
    deactivatedAt
    usage(dateRange: $dateRange) {
      hoursAllocated
      hoursAvailable
      hoursOverLimit
      hoursUtilized
      percentUtilized
      isOverLimit
      appointments {
        id
        startAt
        endAt
        status
        resource {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
        notes
        createdAt
      }
    }
  }
}
    `;

/**
 * __useGetUsageReportForCompanyQuery__
 *
 * To run a query within a React component, call `useGetUsageReportForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageReportForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageReportForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetUsageReportForCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetUsageReportForCompanyQuery, GetUsageReportForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsageReportForCompanyQuery, GetUsageReportForCompanyQueryVariables>(GetUsageReportForCompanyDocument, options);
      }
export function useGetUsageReportForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsageReportForCompanyQuery, GetUsageReportForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsageReportForCompanyQuery, GetUsageReportForCompanyQueryVariables>(GetUsageReportForCompanyDocument, options);
        }
export type GetUsageReportForCompanyQueryHookResult = ReturnType<typeof useGetUsageReportForCompanyQuery>;
export type GetUsageReportForCompanyLazyQueryHookResult = ReturnType<typeof useGetUsageReportForCompanyLazyQuery>;
export type GetUsageReportForCompanyQueryResult = Apollo.QueryResult<GetUsageReportForCompanyQuery, GetUsageReportForCompanyQueryVariables>;