import React, { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import {
  DateInput,
  SelectInput,
  TextArea,
  TimeInput,
  Toggle
} from '../../../components';
import { Appointment } from './AppointmentModal';
import moment from 'moment';
import { useResourceOptions, useUserOptions } from '../../../hooks';

type AppointmentFormProps = {
  form: UseFormReturn<Appointment>;
  notifyUser: boolean;
  toggleNotifyUser: () => void;
};

export const AppointmentForm: React.FC<AppointmentFormProps> = ({
  form: {
    register,
    control,
    formState: { errors },
    watch,
    setValue
  },
  notifyUser,
  toggleNotifyUser
}) => {
  const resources = useResourceOptions();
  const users = useUserOptions();

  const [startAt, endAt] = watch(['startAt', 'endAt']);

  useEffect(() => {
    const start = moment(startAt);

    const newEndAt = moment(endAt)
      .year(start.year())
      .month(start.month())
      .date(start.date());

    setValue('endAt', newEndAt.toISOString());
  }, [startAt]);

  return (
    <ModalBody>
      <Row>
        <Col xs="12" md="6">
          <Controller
            name="resourceId"
            control={control}
            rules={{
              required: 'Resource is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="Resource"
                error={errors.resourceId?.message}
                placeholder="Select Resource"
                options={resources}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="startAt"
            control={control}
            rules={{
              required: 'Date is required'
            }}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Date"
                error={errors.startAt?.message}
                onChange={(date) => {
                  onChange(moment(date).toISOString());
                }}
                value={value}
              />
            )}
          />
          <Controller
            name="startAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TimeInput
                label="Start Time"
                selected={moment(value).toDate()}
                onChange={(value) =>
                  value && onChange(moment(value).toISOString())
                }
              />
            )}
          />
          <Controller
            name="endAt"
            control={control}
            rules={{
              validate: {
                afterStartDate: (value) =>
                  moment(value).isAfter(startAt) ||
                  'End time must be greater than the start time'
              }
            }}
            render={({ field: { value, onChange } }) => (
              <TimeInput
                label="End Time"
                error={errors.endAt?.message}
                selected={moment(value).toDate()}
                onChange={(value) =>
                  value && onChange(moment(value).toISOString())
                }
              />
            )}
          />
        </Col>
        <Col xs="12" md="6">
          <Controller
            name="userId"
            control={control}
            rules={{
              required: 'User is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="User"
                error={errors.userId?.message}
                placeholder="Select User"
                options={users}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <TextArea
            label="Notes"
            placeholder="Notes about this appointment and special requests..."
            error={errors.notes?.message}
            rows={2}
            {...register('notes')}
          />
          <TextArea
            label="Internal Notes"
            placeholder="Internal notes for team communication and awareness..."
            error={errors.internalNotes?.message}
            rows={2}
            {...register('internalNotes')}
          />
          <Toggle
            label="Notify User"
            helperText="Enable this option to deliver an email notification"
            checked={notifyUser}
            onChange={toggleNotifyUser}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
