import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { Button } from '../../../components';
import { BlockoutForm } from './BlockoutsForm';
import { useBlockoutActions } from './useBlockoutActions';
import { format } from 'date-fns';

type BlockoutModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

export type Blockout = {
  date: string;
  description: string;
};

const defaultBlockout: Blockout = {
  description: '',
  date: format(new Date(), 'yyyy-MM-dd')
};

export const BlockoutModal: React.FC<BlockoutModalProps> = (props) => {
  const { isOpen, toggle } = props;

  const { handleCreateBlockout, createBlockoutLoading } = useBlockoutActions({
    toggle
  });

  const form = useForm<Blockout>({
    defaultValues: defaultBlockout
  });

  useEffect(() => {
    if (isOpen) {
      form.reset(defaultBlockout);
    }
  }, [isOpen]);

  const handleSubmit = (data: Blockout) => {
    handleCreateBlockout(data);
  };

  return (
    <>
      <Modal
        toggle={!createBlockoutLoading ? toggle : () => null}
        isOpen={isOpen}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="editBlockout">
            Create Blockout
          </h5>
        </div>

        <>
          <BlockoutForm form={form} />
          <ModalFooter>
            <Button disabled={createBlockoutLoading} onClick={toggle}>
              Cancel
            </Button>
            <Button
              color="primary"
              loading={createBlockoutLoading}
              onClick={form.handleSubmit(handleSubmit)}
            >
              Create
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};
