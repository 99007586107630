import React, { forwardRef } from 'react';
import ReactDateTime, { DatetimepickerProps } from 'react-datetime';
import { InputGroup } from 'reactstrap';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { AddOnType, InputAddon, InputAddonProps } from '../InputAddon';
import ReactDatetimeClass from 'react-datetime';
import { Calendar } from 'react-feather';
import moment from 'moment';

export type DateInputProps = InputWrapperProps & {
  inputAddon?: InputAddonProps;
  disabled?: boolean;
} & DatetimepickerProps;

const defaultInputAddon = {
  addonType: AddOnType.Prepend,
  value: <Calendar color="#606E80" size={18} />
};

export const DateInput = forwardRef<ReactDatetimeClass, DateInputProps>(
  (
    {
      label,
      helperText,
      error,
      value,
      disabled,
      inputAddon = defaultInputAddon,
      ...rest
    },
    ref
  ) => (
    <InputWrapper label={label} helperText={helperText} error={error}>
      <InputGroup className="input-group-alternative">
        {inputAddon?.addonType === AddOnType.Prepend && (
          <InputAddon {...inputAddon} />
        )}
        <ReactDateTime
          ref={ref}
          inputProps={{
            placeholder: 'Select Date',
            readOnly: true,
            disabled
          }}
          timeFormat={false}
          dateFormat="dddd, MMMM Do, YYYY"
          closeOnSelect
          closeOnClickOutside
          value={value && moment(value)}
          {...rest}
        />
        {inputAddon?.addonType === AddOnType.Append && (
          <InputAddon {...inputAddon} />
        )}
      </InputGroup>
    </InputWrapper>
  )
);

DateInput.displayName = 'DateInput';
