import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAppointmentConfirmationQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetAppointmentConfirmationQuery = { getAppointment: { id: string, notes: string | null, status: Types.AppointmentStatus, startAt: string, endAt: string, resource: { name: string, type: Types.ResourceType, capacity: number } } };


export const GetAppointmentConfirmationDocument = gql`
    query GetAppointmentConfirmation($id: UUID!) {
  getAppointment(id: $id) {
    id
    notes
    status
    startAt
    endAt
    resource {
      name
      type
      capacity
    }
  }
}
    `;

/**
 * __useGetAppointmentConfirmationQuery__
 *
 * To run a query within a React component, call `useGetAppointmentConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentConfirmationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppointmentConfirmationQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentConfirmationQuery, GetAppointmentConfirmationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentConfirmationQuery, GetAppointmentConfirmationQueryVariables>(GetAppointmentConfirmationDocument, options);
      }
export function useGetAppointmentConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentConfirmationQuery, GetAppointmentConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentConfirmationQuery, GetAppointmentConfirmationQueryVariables>(GetAppointmentConfirmationDocument, options);
        }
export type GetAppointmentConfirmationQueryHookResult = ReturnType<typeof useGetAppointmentConfirmationQuery>;
export type GetAppointmentConfirmationLazyQueryHookResult = ReturnType<typeof useGetAppointmentConfirmationLazyQuery>;
export type GetAppointmentConfirmationQueryResult = Apollo.QueryResult<GetAppointmentConfirmationQuery, GetAppointmentConfirmationQueryVariables>;