import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import { TextInput, Toggle } from '../..';
import { CompanyLocation } from './CompanyLocationsModal';

type CompanyLocationFormProps = {
  form: UseFormReturn<CompanyLocation>;
};

export const CompanyLocationForm: React.FC<CompanyLocationFormProps> = ({
  form: {
    register,
    formState: { errors }
  }
}) => {
  return (
    <ModalBody>
      <Row>
        <Col xs="7">
          <TextInput
            label="Street Address"
            error={errors.addressLine1?.message}
            placeholder="Street Address"
            {...register('addressLine1')}
          />
        </Col>
        <Col xs="5">
          <TextInput
            label="Street Address 2"
            error={errors.addressLine2?.message}
            placeholder="Apt, unit, suite, etc"
            {...register('addressLine2')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="4">
          <TextInput
            label="City"
            error={errors.addressCity?.message}
            placeholder="City"
            {...register('addressCity')}
          />
        </Col>
        <Col xs="4">
          <TextInput
            label="State"
            error={errors.addressState?.message}
            placeholder="State"
            {...register('addressState')}
          />
        </Col>
        <Col xs="4">
          <TextInput
            label="Zip Code"
            error={errors.addressZip?.message}
            placeholder="Zip Code"
            {...register('addressZip')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Toggle
            label="Primary Location"
            error={errors.isPrimary?.message}
            {...register('isPrimary')}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
