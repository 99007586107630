import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccountInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAccountInformationQuery = { currentUser: { id: string, firstName: string, lastName: string, email: string, phoneNumber: string | null, title: string | null, birthdate: string | null, company: { id: string, name: string, memberSince: string | null, activeUserCount: number, membership: { id: string, name: string, emoji: string } } } };


export const GetAccountInformationDocument = gql`
    query GetAccountInformation {
  currentUser {
    id
    firstName
    lastName
    email
    phoneNumber
    title
    birthdate
    company {
      id
      name
      memberSince
      membership {
        id
        name
        emoji
      }
      activeUserCount
    }
  }
}
    `;

/**
 * __useGetAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(GetAccountInformationDocument, options);
      }
export function useGetAccountInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(GetAccountInformationDocument, options);
        }
export type GetAccountInformationQueryHookResult = ReturnType<typeof useGetAccountInformationQuery>;
export type GetAccountInformationLazyQueryHookResult = ReturnType<typeof useGetAccountInformationLazyQuery>;
export type GetAccountInformationQueryResult = Apollo.QueryResult<GetAccountInformationQuery, GetAccountInformationQueryVariables>;