import { Option } from '../../types';
import { useGetMembershipOptionsQuery } from './__generated__/graphql';

export const useMembershipOptions = (): Option[] => {
  const { data } = useGetMembershipOptionsQuery();

  return (data?.getBusiness.memberships ?? []).map(
    ({ id, name, emoji }): Option => ({
      value: id,
      label: `${name} ${emoji}`
    })
  );
};
