import React from 'react';
import {
  Modal as ReactstrapModal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { Button } from '../Button';

export enum ModalType {
  Info = 'Info',
  Error = 'Error'
}

const options = {
  [ModalType.Info]: {
    color: 'primary'
  },
  [ModalType.Error]: {
    color: 'danger'
  }
};

export type ConfirmationModalProps = {
  isOpen: boolean;
  toggle: () => void;
  type?: ModalType;
  title: string;
  body: JSX.Element | string;
  loading?: boolean;
  confirmationText?: string;
  onConfirm?: () => Promise<void>;
  cancelText?: string;
  onCancel?: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  toggle,
  title,
  body,
  type = ModalType.Info,
  loading = false,
  confirmationText = 'Confirm',
  onConfirm,
  cancelText = 'Cancel',
  onCancel = toggle
}) => {
  const { color } = options[type];

  const handleToggle = () => {
    if (!loading) {
      toggle();
    }
  };

  return (
    <ReactstrapModal isOpen={isOpen} toggle={handleToggle} centered>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button disabled={loading} onClick={onCancel}>
          {cancelText}
        </Button>
        {onConfirm && (
          <Button color={color} loading={loading} onClick={onConfirm}>
            {confirmationText}
          </Button>
        )}
      </ModalFooter>
    </ReactstrapModal>
  );
};
