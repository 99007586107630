import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import {
  AddOnType,
  DateInput,
  SelectInput,
  TextInput
} from '../../../components';
import {
  GetLogsOptions,
  LogStatus,
  LogType,
  UserRole
} from '../../../__generated__/graphql';
import { SetFilter, useCompanyOptions, useUserOptions } from '../../../hooks';
import { Search } from 'react-feather';
import { logStatusOptions, logTypeOptions } from '../../../constants';
import moment from 'moment';

type SearchFiltersProps = {
  isOpen: boolean;
  toggle: () => void;
  filters: GetLogsOptions;
  setFilter: SetFilter;
};

export const SearchFilters: React.FC<SearchFiltersProps> = ({
  isOpen,
  toggle,
  filters,
  setFilter
}) => {
  const companyOptions = useCompanyOptions();

  const authorOptions = useUserOptions({
    includeInactive: true,
    filterByRole: UserRole.Business
  });

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader className="pb-0 pl-4">Search Filters</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <DateInput
              label="Start Date"
              value={filters.dateRange?.startAt}
              onChange={(value) =>
                setFilter('startAt', moment(value).startOf('day').toISOString())
              }
            />
          </Col>
          <Col md="6">
            <DateInput
              label="End Date"
              value={filters.dateRange?.endAt}
              onChange={(value) =>
                setFilter('endAt', moment(value).endOf('day').toISOString())
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              label="Search"
              placeholder="Search"
              inputAddon={{
                addonType: AddOnType.Prepend,
                value: <Search color="#606E80" size={18} />
              }}
              value={filters.keywords ?? ''}
              onChange={(e) => setFilter('keywords', e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <SelectInput
              label="Company"
              placeholder="Select Company"
              options={companyOptions}
              value={filters.filters?.companyId}
              onChange={(value: string) => setFilter('companyId', value)}
            />
          </Col>
          <Col md="6">
            <SelectInput
              label="Author"
              placeholder="Select Author"
              options={authorOptions}
              value={filters.filters?.authorId}
              onChange={(value: string) => setFilter('authorId', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <SelectInput
              label="Status"
              placeholder="Select Status"
              options={logStatusOptions.filter(
                (options) => options.value !== LogStatus.Deleted
              )}
              value={filters.filters?.status}
              onChange={(value: LogStatus) => setFilter('status', value)}
            />
          </Col>
          <Col md="6">
            <SelectInput
              label="Type"
              placeholder="Select Type"
              options={logTypeOptions}
              value={filters.filters?.type}
              onChange={(value: LogType) => setFilter('type', value)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
