import React, { forwardRef } from 'react';
import { InputGroup } from 'reactstrap';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { AddOnType, InputAddon, InputAddonProps } from '../InputAddon';

export type TextInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'value'
> & {
  inputAddon?: InputAddonProps;
  value?: string | number | readonly string[] | undefined | null;
} & InputWrapperProps;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, helperText, error, inputAddon, value, ...rest }, ref) => (
    <InputWrapper label={label} helperText={helperText} error={error}>
      <InputGroup className="input-group-alternative">
        {inputAddon?.addonType === AddOnType.Prepend && (
          <InputAddon {...inputAddon} />
        )}
        <input
          ref={ref}
          className="form-control"
          value={value ?? undefined}
          {...rest}
        />
        {inputAddon?.addonType === AddOnType.Append && (
          <InputAddon {...inputAddon} />
        )}
      </InputGroup>
    </InputWrapper>
  )
);

TextInput.displayName = 'TextInput';
