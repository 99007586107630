import React, { useEffect } from 'react';
import { LogProvider, useLogContext } from '../../../contexts';
import { Badge, Button, Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { LogCard } from './LogCard';
import { InformationCard } from './InformationCard';
import { useCreateLogMutation } from './__generated__/graphql';
import { Column } from 'react-table';
import moment from 'moment-timezone';
import { logStatus, logTag, logType } from '../../../constants';
import { LogStatus } from '../../../__generated__/graphql';
import { Table } from '../../../components';
import { useLogs, useModal } from '../../../hooks';
import { GetBusinessLogsQuery } from '../../../hooks/useLogs/__generated__/graphql';
import { Filter } from 'react-feather';
import { SearchFilters } from './SearchFilters';
import { formatPhoneNumberNational } from '../../../utils';

type Log = GetBusinessLogsQuery['getLogs']['logs'][0];

const StatusBadge: React.FC<{ status: LogStatus }> = ({ status }) => {
  const colorMap = {
    [LogStatus.Completed]: 'default',
    [LogStatus.New]: 'info',
    [LogStatus.InProgress]: 'primary',
    [LogStatus.Deleted]: 'danger'
  };

  return (
    <Badge color={colorMap[status]} pill>
      {logStatus[status]}
    </Badge>
  );
};
const Notes: React.FC<{ value: string | null }> = ({ value }) => {
  const formatNotes = (text: string | null) => {
    if (!text) return '';
    const escapeHTML = (str: string) =>
      str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    return escapeHTML(text).replace(/\n/g, '<br />');
  };

  return <div dangerouslySetInnerHTML={{ __html: formatNotes(value) }} />;
};

const columns: Column<Log>[] = [
  {
    Header: 'Date',
    accessor: ({ createdAt }) => moment(createdAt).format('YYYY-MM-DD'),
    disableSortBy: true
  },
  {
    Header: 'Time',
    accessor: ({ createdAt }) => moment(createdAt).format('h:mm A'),
    disableSortBy: true
  },
  {
    Header: 'Author',
    accessor: ({ author }) => `${author.firstName} ${author.lastName}`,
    disableSortBy: true
  },
  {
    Header: 'Company',
    accessor: ({ company }) => company?.name ?? '—',
    disableSortBy: true
  },
  {
    Header: 'Type',
    accessor: ({ type }) => (type ? logType[type] : '—'),
    disableSortBy: true
  },
  {
    Header: 'Full Name',
    accessor: ({ firstName, lastName }) => {
      if (firstName?.trim() || lastName?.trim()) {
        return `${firstName ?? ''} ${lastName ?? ''}`.trim();
      }

      return '—';
    },
    disableSortBy: true
  },
  {
    Header: 'Phone Number',
    accessor: ({ phoneNumber }) =>
      phoneNumber ? formatPhoneNumberNational(phoneNumber) : '—',
    disableSortBy: true
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => {
      if (!value) {
        return <span>—</span>;
      }

      return <div className="wrap-cell">{<Notes value={value} />}</div>;
    },
    disableSortBy: true
  },
  {
    Header: 'Internal Notes',
    accessor: 'internalNotes',
    Cell: ({ value }) => {
      if (!value) {
        return <span>—</span>;
      }

      return <div className="wrap-cell">{<Notes value={value} />}</div>;
    },
    disableSortBy: true
  },
  {
    Header: 'Tags',
    accessor: ({ tags, id }) =>
      tags.map((tag) => <li key={`${id}-${tag}`}>{logTag[tag]}</li>),
    disableSortBy: true
  },
  {
    Header: 'Status',
    accessor: ({ status }) => <StatusBadge status={status} />,
    disableSortBy: true
  }
];

export const CaptainsLog: React.FC = () => {
  return (
    <LogProvider>
      <CaptainsLogContainer />
    </LogProvider>
  );
};

const CaptainsLogContainer: React.FC = () => {
  const { setLogId } = useLogContext();
  const navigate = useNavigate();
  const { id: logId } = useParams();

  useEffect(() => {
    setLogId(logId ?? null);
  }, [logId, setLogId, navigate]);

  const {
    loading,
    logs,
    goToPage,
    pageCount,
    pageSize,
    currentPage,
    totalCount,
    hasNextPage,
    hasPreviousPage,
    filters,
    setFilter
  } = useLogs();

  const [createLog] = useCreateLogMutation({
    onCompleted: (data) => navigate(`/business/logs/${data.createLog.id}`)
  });

  const { isOpen: isLogFiltersModalOpen, toggleModal: toggleLogFiltersModal } =
    useModal();

  const handleSelect = (log: Log) => navigate(`/business/logs/${log.id}`);

  const handleCreate = () => createLog();

  if (!logId) {
    return (
      <>
        <SearchFilters
          isOpen={isLogFiltersModalOpen}
          toggle={toggleLogFiltersModal}
          filters={filters}
          setFilter={setFilter}
        />
        <Table<Log>
          striped
          disableSearch
          loading={loading}
          data={logs}
          columns={columns}
          handleSelect={handleSelect}
          pageSize={pageSize}
          pagination={{
            totalCount,
            goToPage,
            pageCount,
            currentPage,
            hasNextPage,
            hasPreviousPage
          }}
        >
          <div className="d-flex justify-content-between">
            <div style={{ textAlign: 'left' }}>
              <Button size="sm" color="primary" onClick={() => navigate(0)}>
                Refresh
              </Button>
            </div>
            <div>
              <Button
                size="sm"
                color="default"
                className="ml-2"
                onClick={handleCreate}
              >
                Create
              </Button>
              <Button size="sm" onClick={toggleLogFiltersModal}>
                <Filter size={14} color="#606E80" /> Filter
              </Button>
            </div>
          </div>
        </Table>
      </>
    );
  }

  return (
    <Row className="d-flex">
      <Col sm="12" md="6" className="d-flex flex-column">
        <LogCard logId={logId} />
      </Col>
      <Col sm="12" md="6" className="d-flex flex-column">
        <InformationCard />
      </Col>
    </Row>
  );
};
