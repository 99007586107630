import React from 'react';

import './style.scss';

export type PulseProps = {
  size?: number;
  color?: string;
  className?: string;
};

export const Pulse: React.FC<PulseProps> = ({
  color = '#C9D9EB',
  size = 50,
  className
}) => {
  return (
    <div
      className={className ? `${className} pulse` : 'pulse'}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div className="double-bounce1" style={{ backgroundColor: color }} />
      <div className="double-bounce2" style={{ backgroundColor: color }} />
    </div>
  );
};
