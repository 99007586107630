/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
  Alert,
  Col,
  Modal,
  ModalBody,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useLogContext } from '../../../../contexts';
import { Pulse, Toggle } from '../../../../components';

import './InteractionHistoryTab.style.scss';
import { LogCard } from '../LogCard';
import { Info } from 'react-feather';
import { logType } from '../../../../constants';

const NameVerifiedIcon: React.FC = () => (
  <>
    <FontAwesomeIcon
      icon={faCircleCheck}
      color="#5e72e4"
      id="interaction-history-name-verified"
    />
    <UncontrolledTooltip
      style={{ fontSize: '10px' }}
      hideArrow
      placement="right"
      target="interaction-history-name-verified"
      innerClassName="bg-primary"
      className="p-0 m-0"
      delay={0}
    >
      Name Verified
    </UncontrolledTooltip>
  </>
);

export const InteractionHistoryTab: React.FC = () => {
  const {
    logId,
    companyId,
    phoneNumber,
    interactionHistory,
    loadingInteractionHistory
  } = useLogContext();

  const [targetCompany, setTargetCompany] = useState(false);

  const [selectedLogId, setSelectedLogId] = useState<string | null>(null);
  const [showLogModal, setShowLogModal] = useState(false);

  const toggleLogModal = (id?: string) => {
    if (id) {
      setSelectedLogId(id);
    }
    setShowLogModal(!showLogModal);
  };

  if (!phoneNumber) {
    return (
      <p className="mb-0">
        Please enter a phone number to view interaction history.
      </p>
    );
  }

  if (!interactionHistory || loadingInteractionHistory) {
    return <Pulse />;
  }

  const getDisplayName = ({
    firstName,
    lastName
  }: {
    firstName: string | null;
    lastName: string | null;
  }) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return firstName || lastName || '—';
  };

  const logs = interactionHistory.logs.filter((log) => log.id !== logId);

  const filteredHistory =
    targetCompany && companyId
      ? logs.filter((log) => log.company?.id === companyId)
      : logs;

  return (
    <div>
      <Modal
        centered
        size="lg"
        isOpen={showLogModal}
        toggle={() => toggleLogModal()}
      >
        <ModalBody className="p-0">
          {selectedLogId && (
            <LogCard
              logId={selectedLogId}
              isReadOnly
              isModal
              onClose={toggleLogModal}
            />
          )}
        </ModalBody>
      </Modal>
      <div>
        <Row>
          <Col xs="6">
            <div className="form-control-label">
              Full Name{' '}
              {interactionHistory.isNameVerified && <NameVerifiedIcon />}
            </div>
            <p>{getDisplayName(interactionHistory)}</p>
          </Col>
          <Col xs="6">
            <div className="form-control-label">Email Address</div>
            <p>{interactionHistory.email ?? '—'}</p>
          </Col>
        </Row>
      </div>
      <div>
        <h3>History</h3>
        {companyId && logs.length > 0 && (
          <Toggle
            label="Filter by Selected Company"
            defaultChecked={targetCompany}
            onClick={() => setTargetCompany(!targetCompany)}
          />
        )}
        <Row>
          <Col xs="12" className="interaction-history-tab">
            {!filteredHistory.length && (
              <Alert
                color="primary"
                className="d-flex align-items-center text-white"
              >
                <Info size={20} className="mr-2" />
                No interaction history found for this phone number:
                <strong className="pl-1">{phoneNumber}</strong>.
              </Alert>
              // <p className="mt--1 mb-0">
              //   No interaction history found for this phone number {phoneNumber}
              //   .
              // </p>
            )}
            <Table
              className={classNames({ 'd-none': !filteredHistory.length })}
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Company</th>
                  <th>Notes</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredHistory.map((log) => (
                  <tr key={log.id} onClick={() => toggleLogModal(log.id)}>
                    <td>{log.type ? logType[log.type] : '-'}</td>
                    <td>{log.company?.name || '-'}</td>
                    <td>{log.notes ?? '-'}</td>
                    <td>{moment(log.createdAt).format('MMMM Do YYYY')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
};
