import { Announcements } from './Announcements';
import { Blockouts } from './Blockouts';
import { CaptainsLog } from './CaptainsLog';
import { Companies } from './Companies';
import { Users } from './Users';
import { Reports } from './Reports';
import { Resources } from './Resources';
import { Settings } from './Settings';

export const Business = {
  Announcements,
  Blockouts,
  CaptainsLog,
  Companies,
  Users,
  Reports,
  Resources,
  Settings
};
