import { NotificationSeverity, notify } from '../../utils/toast';
import { useUploadFilesMutation } from './__generated__/graphql';

export type FileUpload = {
  id: string;
  fileName: string;
  url: string;
};

export const useFileUpload = () => {
  const [uploadFilesMutation, { data, loading, error }] =
    useUploadFilesMutation();

  const uploadFiles = async (files: FileList) => {
    try {
      const fileArray = Array.from(files);

      const { data } = await uploadFilesMutation({
        variables: { files: fileArray }
      });

      if (!data?.uploadFiles) {
        throw new Error('No file upload data returned from server');
      }

      return data.uploadFiles;
    } catch (error) {
      notify({
        message: 'Error uploading file(s)!',
        severity: NotificationSeverity.Error
      });
      return [];
    }
  };

  return {
    uploadFiles,
    data,
    loading,
    error
  };
};
