import React from 'react';
import { Icon, MoreVertical } from 'react-feather';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu as ReactstrapDropdownMenu,
  DropdownItem
} from 'reactstrap';

export type DropdownMenuItem = {
  icon?: React.ReactElement<Icon>;
  label: string;
  onClick: () => void;
  hide?: boolean;
};

export type DropdownMenuProps = {
  items: DropdownMenuItem[];
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ items }) => {
  const filteredItems = items.filter((item) => !item.hide);

  if (!filteredItems.length) {
    return null;
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle size="sm" className="p-1" color="primary">
        <MoreVertical size={18} />
      </DropdownToggle>
      <ReactstrapDropdownMenu>
        <DropdownItem header>Actions</DropdownItem>
        {filteredItems.map((item) => (
          <DropdownItem
            className="text-muted"
            onClick={item.onClick}
            key={item.label}
          >
            {item.icon}
            {item.label}
          </DropdownItem>
        ))}
      </ReactstrapDropdownMenu>
    </UncontrolledDropdown>
  );
};
