import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './StarRating.style.scss';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';

export type StarRatingProps = InputWrapperProps & {
  onChange?: (rating?: number | null) => void;
  defaultValue?: number | null;
  disabled?: boolean;
};

const totalStars = 5;

export const StarRating: React.FC<StarRatingProps> = ({
  label,
  helperText,
  error,
  onChange,
  defaultValue,
  disabled
}) => {
  const [selected, setSelected] = useState<number | null>(defaultValue || null);

  useEffect(() => {
    setSelected(defaultValue || null);
  }, [defaultValue]);

  const handleClick = (value: number) => {
    if (!disabled) {
      const newValue = selected === value ? null : value;
      setSelected(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <InputWrapper label={label} helperText={helperText} error={error}>
      <div className="d-flex align-items-center mt-4 ml--2 mb-5">
        {Array.from({ length: totalStars }, (_, index) => {
          const value = index + 1;
          return (
            <div className="star-rating" key={value}>
              <div
                role="presentation"
                className={classnames('star-rating-item', {
                  active: value <= (selected ?? 0),
                  inactive: value > (selected ?? 0)
                })}
                onClick={() => handleClick(value)}
              >
                ⭐
              </div>
            </div>
          );
        })}
      </div>
    </InputWrapper>
  );
};
