import React, { useState } from 'react';
import {
  AddOnType,
  DateInput,
  InputWrapper,
  PhoneNumberInput,
  SelectInput,
  StarRating,
  TextArea,
  TextInput,
  TimeInput
} from '../../../../components';
import { Mail, XSquare } from 'react-feather';
import {
  FileUpload,
  useBusinessSettings,
  useCompanyOptions
} from '../../../../hooks';
import { logTagOptions, logTypeOptions } from '../../../../constants';
import { CleanNotesButton } from './CleanNotesButton';
import { Col, Collapse, Row } from 'reactstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { Log } from './LogCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FileUploadInput } from '../../../../components/core/FileUploadInput';
import { emailRegex } from '../../../../utils';

export type LogFormProps = {
  isReadOnly?: boolean;
};

export const LogForm: React.FC<LogFormProps> = ({ isReadOnly }) => {
  const companyOptions = useCompanyOptions();
  const settings = useBusinessSettings();

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<Log>();

  const fileUploads = watch('fileUploads');

  const removeFileUpload = (fileUploadId: string) => {
    setValue(
      'fileUploads',
      fileUploads.filter((fileUpload) => fileUpload.id !== fileUploadId),
      { shouldDirty: true }
    );
  };

  const handleFileUploads = (uploadedFiles: FileUpload[]) => {
    setValue('fileUploads', [...fileUploads, ...uploadedFiles], {
      shouldDirty: true
    });
  };

  if (!settings || !companyOptions) {
    return null;
  }

  return (
    <>
      <Row>
        <Col xs="12">
          <h4>General Information</h4>
        </Col>
        <Col xs="12">
          <hr className="mt-0" />
          <Row>
            <Col xs="6">
              <Controller
                control={control}
                name="companyId"
                render={({ field: { value, onChange } }) => (
                  <SelectInput
                    label="Company"
                    error={errors?.companyId?.message}
                    placeholder="Select Company"
                    value={value}
                    onChange={onChange}
                    options={companyOptions}
                    isDisabled={isReadOnly}
                  />
                )}
              />
            </Col>
            <Col xs="6">
              <Controller
                control={control}
                name="type"
                render={({ field: { value, onChange } }) => (
                  <SelectInput
                    label="Log Type"
                    placeholder="Select Type"
                    error={errors?.companyId?.message}
                    value={value}
                    onChange={onChange}
                    options={logTypeOptions}
                    isDisabled={isReadOnly}
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h4>Personal Information</h4>
        </Col>
        <Col xs="12">
          <hr className="mt-0" />
          <Row>
            <Col xs="6">
              <Controller
                control={control}
                name="firstName"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    value={value}
                    onChange={onChange}
                    error={errors?.firstName?.message}
                    disabled={isReadOnly}
                  />
                )}
              />
            </Col>
            <Col xs="6">
              <Controller
                control={control}
                name="lastName"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    value={value}
                    onChange={onChange}
                    error={errors?.lastName?.message}
                    disabled={isReadOnly}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Controller
                control={control}
                name="email"
                rules={{
                  pattern: {
                    value: emailRegex,
                    message: 'Email is invalid'
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                    error={errors?.email?.message}
                    disabled={isReadOnly}
                    inputAddon={{
                      addonType: AddOnType.Prepend,
                      value: <Mail color="#606E80" size="16" />
                    }}
                  />
                )}
              />
            </Col>
            <Col xs="6">
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { value, onChange } }) => (
                  <PhoneNumberInput
                    label="Phone Number"
                    value={value}
                    onChange={onChange}
                    error={errors?.phoneNumber?.message}
                    disabled={isReadOnly}
                  />
                )}
              />
            </Col>
          </Row>
          <Collapse isOpen={isCollapseOpen}>
            <Row>
              <Col xs="8">
                <Controller
                  control={control}
                  name="addressLine1"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="Street Address"
                      placeholder="Street Address"
                      value={value}
                      onChange={onChange}
                      error={errors?.addressLine1?.message}
                      disabled={isReadOnly}
                    />
                  )}
                />
              </Col>
              <Col xs="4">
                <Controller
                  control={control}
                  name="addressLine2"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="Street Address 2"
                      placeholder="Apt, unit, suite, etc."
                      value={value}
                      onChange={onChange}
                      error={errors?.addressLine2?.message}
                      disabled={isReadOnly}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Controller
                  control={control}
                  name="addressCity"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="City"
                      placeholder="City"
                      value={value}
                      onChange={onChange}
                      error={errors?.addressCity?.message}
                      disabled={isReadOnly}
                    />
                  )}
                />
              </Col>
              <Col xs="4">
                <Controller
                  control={control}
                  name="addressState"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="State"
                      placeholder="State"
                      value={value}
                      onChange={onChange}
                      error={errors?.addressState?.message}
                      disabled={isReadOnly}
                    />
                  )}
                />
              </Col>
              <Col xs="4">
                <Controller
                  control={control}
                  name="addressZip"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="Zip Code"
                      placeholder="Zip Code"
                      value={value}
                      onChange={onChange}
                      error={errors?.addressZip?.message}
                      disabled={isReadOnly}
                    />
                  )}
                />
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Col
              className="text-default text-center"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            >
              {isCollapseOpen ? (
                <FontAwesomeIcon icon={faCaretUp} />
              ) : (
                <FontAwesomeIcon icon={faCaretDown} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h4>Additional Information</h4>
        </Col>
        <Col xs="12">
          <hr className="mt-0" />
          <Row>
            <Col xs="12">
              <Controller
                control={control}
                name="notes"
                render={({ field: { value, onChange } }) => (
                  <>
                    <TextArea
                      label="Notes"
                      placeholder="Enter important notes here..."
                      value={value}
                      onChange={onChange}
                      error={errors?.notes?.message}
                      disabled={isReadOnly}
                      rows={8}
                    />
                    {!isReadOnly && <CleanNotesButton />}
                  </>
                )}
              />
            </Col>
            <Col xs="12">
              <Controller
                control={control}
                name="internalNotes"
                render={({ field: { value, onChange } }) => (
                  <TextArea
                    label="Internal Notes"
                    placeholder="Enter important internal notes here.."
                    value={value}
                    onChange={onChange}
                    error={errors?.internalNotes?.message}
                    disabled={isReadOnly}
                    rows={4}
                  />
                )}
              />
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              {!isReadOnly && (
                <FileUploadInput
                  label="File Attachments"
                  helperText="Select file(s) to attach to this log"
                  onCompleted={handleFileUploads}
                />
              )}
              <InputWrapper label={isReadOnly ? 'File Attachments' : undefined}>
                <div>
                  {fileUploads.map(
                    (file: { id: string; fileName: string; url: string }) => (
                      <div key={file.id}>
                        {` • `}
                        <a
                          href={`/businesses/${settings.id}/uploads/${file.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.fileName}
                        </a>
                        {!isReadOnly && (
                          <XSquare
                            size={16}
                            className="ml-1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeFileUpload(file.id)}
                          />
                        )}
                      </div>
                    )
                  )}
                  {isReadOnly && !fileUploads.length && <p>—</p>}
                </div>
              </InputWrapper>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Controller
                control={control}
                name="rating"
                render={({ field: { value, onChange } }) => (
                  <StarRating
                    label="Interaction Rating"
                    helperText="Select the rating for this interaction"
                    defaultValue={value}
                    onChange={onChange}
                    error={errors?.rating?.message}
                    disabled={isReadOnly}
                  />
                )}
              />
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Controller
                control={control}
                name="appointmentAt"
                render={({ field: { value, onChange } }) => (
                  <DateInput
                    label="Appointment Date"
                    value={value ?? undefined}
                    onChange={onChange}
                    error={errors?.appointmentAt?.message}
                    disabled={isReadOnly}
                  />
                )}
              />
            </Col>
            <Col xs="6">
              <Controller
                control={control}
                name="appointmentAt"
                render={({ field: { value, onChange } }) => (
                  <TimeInput
                    label="Appointment Start Time"
                    selected={value ? moment(value).toDate() : null}
                    onChange={onChange}
                    error={errors?.appointmentAt?.message}
                    disabled={isReadOnly}
                  />
                )}
              />
            </Col>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Controller
                control={control}
                name="tags"
                render={({ field: { value, onChange } }) => (
                  <SelectInput
                    label="Tags"
                    placeholder="Select Tags"
                    value={value}
                    onChange={onChange}
                    options={logTagOptions}
                    isDisabled={isReadOnly}
                    isMulti
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
