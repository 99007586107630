import React from 'react';
import {
  Col,
  Pagination as PaginationComponent,
  PaginationItem,
  PaginationLink,
  Row
} from 'reactstrap';

export type PaginationProps = {
  totalCount: number;
  pageCount: number;
  currentPage: number;
  goToPage: (page: number) => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    totalCount,
    pageCount,
    currentPage,
    goToPage,
    hasPreviousPage,
    hasNextPage
  } = props;

  const pages = [];

  if (pageCount <= 3) {
    for (let i = 0; i < pageCount; i += 1) {
      pages.push(i + 1);
    }
  } else if (currentPage + 1 === pageCount) {
    pages.push(pageCount - 2);
    pages.push(pageCount - 1);
    pages.push(pageCount);
  } else {
    pages.push(currentPage === 0 ? currentPage + 1 : currentPage);
    pages.push(currentPage === 0 ? currentPage + 2 : currentPage + 1);
    pages.push(currentPage === 0 ? currentPage + 3 : currentPage + 2);
  }

  return (
    <Row className="d-flex flex-column align-items-center pt-3">
      <Col xs="auto">
        <PaginationComponent aria-label="Page navigation example">
          <PaginationItem disabled={!hasPreviousPage}>
            <PaginationLink
              onClick={(e) => {
                e.preventDefault();
                goToPage(currentPage - 1);
              }}
              previous
              href="#"
            />
          </PaginationItem>
          {pages.map((page) => (
            <PaginationItem active={currentPage + 1 === page} key={page}>
              <PaginationLink
                onClick={(e) => {
                  e.preventDefault();
                  goToPage(page - 1);
                }}
                href="#"
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={!hasNextPage}>
            <PaginationLink
              onClick={(e) => {
                e.preventDefault();
                goToPage(currentPage + 1);
              }}
              next
              href="#"
            />
          </PaginationItem>
        </PaginationComponent>
      </Col>
      <Col xs="auto">
        <p>{`${totalCount} record${totalCount > 1 ? 's' : ''}`}</p>
      </Col>
    </Row>
  );
};
