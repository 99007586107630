import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Badge, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { useGetUsageReportForCompanyLazyQuery } from './__generated__/graphql';
import { PlanUsageCard, Pulse } from '../../../../components';

type CompanyUsageDrilldownProps = {
  companyId: string;
  dateRange: {
    startAt: string;
    endAt: string;
  };
};

export const CompanyUsageDrilldown: React.FC<CompanyUsageDrilldownProps> = ({
  companyId,
  dateRange
}) => {
  const [getCompanyUsageReport, { data }] =
    useGetUsageReportForCompanyLazyQuery();

  const company = useMemo(() => data?.getCompany, [data]);

  useEffect(() => {
    getCompanyUsageReport({
      variables: {
        companyId,
        dateRange
      }
    });
  }, [companyId]);

  if (!company) {
    return <Pulse />;
  }

  return (
    <>
      <Row>
        <Col xs="6" className="d-flex flex-column">
          <PlanUsageCard companyId={company.id} dateRange={dateRange} expand />
        </Col>
        <Col xs="6" className="d-flex flex-column">
          <h2>Plan Details</h2>
          <Card className="flex-fill">
            <CardBody>
              <div className="form-group">
                <div className="h4">Company</div>
                <h5 className="text-muted font-weight-normal">
                  {company.name}
                </h5>
              </div>
              <div className="form-group">
                <div className="h4">Plan</div>
                <h5 className="text-muted font-weight-normal">
                  {company.membership.name} {company.membership.emoji}
                </h5>
              </div>
              <div className="form-group">
                <div className="h4">Member Since</div>
                <h5 className="text-muted font-weight-normal">
                  {company.memberSince
                    ? moment(company.memberSince).format('MMMM YYYY')
                    : '–'}
                </h5>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <h2>Appointments</h2>
      <Card>
        <CardBody>
          {company.usage.appointments.length === 0 ? (
            <p className="mb-0">
              No appointments found for the specified date range.
            </p>
          ) : (
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>User</th>
                  <th>Room</th>
                  <th>Notes</th>
                  <th>Created At</th>
                  <th>Cancelled</th>
                </tr>
              </thead>
              <tbody>
                {company.usage.appointments.map((appointment) => (
                  <tr key={appointment.id} style={{ cursor: 'default' }}>
                    <td>
                      {moment(appointment.startAt).format('MMMM Do YYYY')}
                    </td>
                    <td>
                      {`
                        ${moment(appointment.startAt).format('hh:mm A ')}
                        –
                        ${moment(appointment.endAt).format('hh:mm A')}
                      `}
                    </td>
                    <td>{`${appointment.user.firstName} ${appointment.user.lastName}`}</td>
                    <td>{appointment.resource.name}</td>
                    <td className="wrap-cell">{appointment.notes || '–'}</td>
                    <td>
                      {moment(appointment.createdAt).format('MMMM Do YYYY')}
                    </td>
                    <th>
                      {appointment.status === 'CANCELLED' && (
                        <Badge color="danger" pill>
                          Cancelled
                        </Badge>
                      )}
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};
