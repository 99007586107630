import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetResourceForAppointmentQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetResourceForAppointmentQuery = { getResource: { id: string, name: string, description: string | null, capacity: number, type: Types.ResourceType, images: Array<{ id: string, url: string }> } };

export type CreateUserAppointmentMutationVariables = Types.Exact<{
  data: Types.CreateAppointmentInput;
}>;


export type CreateUserAppointmentMutation = { createAppointment: { id: string } };


export const GetResourceForAppointmentDocument = gql`
    query GetResourceForAppointment($id: UUID!) {
  getResource(id: $id) {
    id
    name
    description
    capacity
    type
    images {
      id
      url
    }
  }
}
    `;

/**
 * __useGetResourceForAppointmentQuery__
 *
 * To run a query within a React component, call `useGetResourceForAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceForAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceForAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResourceForAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetResourceForAppointmentQuery, GetResourceForAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceForAppointmentQuery, GetResourceForAppointmentQueryVariables>(GetResourceForAppointmentDocument, options);
      }
export function useGetResourceForAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceForAppointmentQuery, GetResourceForAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceForAppointmentQuery, GetResourceForAppointmentQueryVariables>(GetResourceForAppointmentDocument, options);
        }
export type GetResourceForAppointmentQueryHookResult = ReturnType<typeof useGetResourceForAppointmentQuery>;
export type GetResourceForAppointmentLazyQueryHookResult = ReturnType<typeof useGetResourceForAppointmentLazyQuery>;
export type GetResourceForAppointmentQueryResult = Apollo.QueryResult<GetResourceForAppointmentQuery, GetResourceForAppointmentQueryVariables>;
export const CreateUserAppointmentDocument = gql`
    mutation CreateUserAppointment($data: CreateAppointmentInput!) {
  createAppointment(data: $data, options: {notifyUser: true}) {
    id
  }
}
    `;
export type CreateUserAppointmentMutationFn = Apollo.MutationFunction<CreateUserAppointmentMutation, CreateUserAppointmentMutationVariables>;

/**
 * __useCreateUserAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateUserAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAppointmentMutation, { data, loading, error }] = useCreateUserAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserAppointmentMutation, CreateUserAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserAppointmentMutation, CreateUserAppointmentMutationVariables>(CreateUserAppointmentDocument, options);
      }
export type CreateUserAppointmentMutationHookResult = ReturnType<typeof useCreateUserAppointmentMutation>;
export type CreateUserAppointmentMutationResult = Apollo.MutationResult<CreateUserAppointmentMutation>;
export type CreateUserAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateUserAppointmentMutation, CreateUserAppointmentMutationVariables>;