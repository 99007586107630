import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import {
  AddOnType,
  DateInput,
  SelectInput,
  TextInput
} from '../../../components';
import { Mail, Phone } from 'react-feather';
import { useCompanyOptions } from '../../../hooks';
import {
  emailRegex,
  formatPhoneNumber,
  formatPhoneNumberNational
} from '../../../utils';
import { userRoleOptions } from '../../../constants';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { User } from './UserModal';
import moment from 'moment';

type UserFormProps = {
  form: UseFormReturn<User>;
  isEditting?: boolean;
};

export const UserForm: React.FC<UserFormProps> = ({
  isEditting,
  form: {
    register,
    control,
    formState: { errors }
  }
}) => {
  const companyOptions = useCompanyOptions();

  return (
    <ModalBody>
      <Row>
        <Col xs="6">
          <TextInput
            label="First Name"
            error={errors.firstName?.message}
            placeholder="First Name"
            {...register('firstName', {
              required: 'First name is required'
            })}
          />
        </Col>
        <Col xs="6">
          <TextInput
            label="Last Name"
            error={errors.lastName?.message}
            placeholder="Last Name"
            {...register('lastName', {
              required: 'Last name is required'
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <TextInput
            label="Email"
            placeholder="hello@officedivvy.com"
            error={errors.email?.message}
            inputAddon={{
              addonType: AddOnType.Prepend,
              value: <Mail color="#606E80" size="16" />
            }}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: emailRegex,
                message: 'Email is invalid'
              }
            })}
          />
        </Col>
        <Col xs="6">
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              validate: (value) => {
                if (!value) {
                  return true;
                }

                return (
                  isValidPhoneNumber(value, 'US') || 'Phone number is invalid'
                );
              }
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Phone Number"
                error={errors.phoneNumber?.message}
                placeholder="(555) 555-5555"
                inputAddon={{
                  addonType: AddOnType.Prepend,
                  value: <Phone color="#606E80" size="16" />
                }}
                value={formatPhoneNumberNational(value)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(formatPhoneNumber(event.target.value, 'E.164'))
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <TextInput
            label="Title"
            error={errors.title?.message}
            placeholder="Owner"
            {...register('title')}
          />
        </Col>
        <Col xs="6">
          <Controller
            control={control}
            name="role"
            rules={{
              required: 'Privilege is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="Privilege"
                error={errors.role?.message}
                placeholder="Select Privilege"
                options={userRoleOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Controller
            name="birthdate"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Birthdate"
                dateFormat="MMMM Do, YYYY"
                error={errors.birthdate?.message}
                isValidDate={(date) => moment(date).isSameOrBefore(moment())}
                onChange={(date) => {
                  onChange(moment(date).format('YYYY-MM-DD'));
                }}
                value={value ?? undefined}
              />
            )}
          />
        </Col>
        <Col xs="6">
          <Controller
            control={control}
            name="companyId"
            rules={{
              required: 'Company is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                isDisabled={isEditting}
                label="Company"
                error={errors.companyId?.message}
                placeholder="Select Company"
                options={companyOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
