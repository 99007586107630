import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import routes from './routes';

export const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {routes.map(({ path, element, authenticationRequired, ...rest }) => (
          <Route
            key={path}
            path={path}
            element={
              authenticationRequired ? (
                <ProtectedRoute element={element} {...rest} />
              ) : (
                element
              )
            }
          />
        ))}
      </Routes>
    </Router>
  );
};
