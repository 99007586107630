import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Quill } from '../../../components';
import { Company } from './CompanyModal';

export const Training: React.FC = () => {
  const { control } = useFormContext<Company>();

  return (
    <Row>
      <Col xs="12">
        <Controller
          control={control}
          name="training"
          render={({ field }) => <Quill {...field} />}
        />
      </Col>
    </Row>
  );
};
