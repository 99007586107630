import io from 'socket.io-client';

export const socket = io(
  process.env.REACT_APP_API_URL?.replace('/api', '') || '',
  {
    transports: ['websocket'],
    query: {
      'x-access-token': localStorage.getItem('x-access-token') || ''
    }
  }
);
