import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Row>
      <Col xs="12">
        <h2>Page Not Found</h2>
        <p>
          Sorry the page you have requested does not exist or is temporarily
          unavailable.
        </p>
        <Button color="default" onClick={goToDashboard}>
          Dashboard
        </Button>
      </Col>
    </Row>
  );
};
