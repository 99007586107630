import React, { useState, useEffect, useRef } from 'react';
import {
  type ButtonProps as ReactstrapButtonProps,
  Button as ReactstrapButton
} from 'reactstrap';
import { Spinner } from '../Spinner';

export type ButtonProps = {
  loading?: boolean;
} & ReactstrapButtonProps;

export const Button: React.FC<ButtonProps> = ({
  loading = false,
  disabled = false,
  children,
  ...rest
}) => {
  const [buttonWidth, setButtonWidth] = useState('auto');
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current) {
      const currentWidth = ref.current.offsetWidth;
      const minWidth = Math.max(currentWidth, 100);
      setButtonWidth(`${minWidth}px`);
    }
  }, [children]);

  return (
    <ReactstrapButton
      innerRef={ref}
      style={{ minWidth: buttonWidth }}
      disabled={disabled || loading}
      {...rest}
    >
      {!loading ? children : <Spinner />}
    </ReactstrapButton>
  );
};
