import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Home, Book, Calendar, User, LogOut } from 'react-feather';

export const MobileNavigation: React.FC = () => {
  const [isVisible, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      const {
        body: { scrollHeight, offsetHeight }
      } = document;

      const maxScroll = scrollHeight - offsetHeight;

      if (window.scrollY === 0) {
        setShow(true);
      } else if (window.scrollY === maxScroll) {
        setShow(false);
      } else if (window.scrollY > lastScrollY + 25) {
        setLastScrollY(window.scrollY);
        setShow(false);
      } else if (window.scrollY < lastScrollY - 25) {
        setLastScrollY(window.scrollY);
        setShow(true);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
    }

    return () => window.removeEventListener('scroll', controlNavbar);
  }, [lastScrollY]);

  return (
    <nav
      className={`${isVisible ? 'visible' : 'hidden'} navbar-mobile navbar fixed-bottom navbar-expand-lg bg-white d-md-none`}
    >
      <div className="container-fluid justify-content-between text-center py-1 px-2">
        <NavLink to="/dashboard">
          <Home />
          <div className="small">Dashboard</div>
        </NavLink>
        <NavLink to="/schedule/search">
          <Book />
          <div className="small">Book</div>
        </NavLink>
        <NavLink to="/calendar">
          <Calendar />
          <div className="small">Calendar</div>
        </NavLink>
        <NavLink to="/account">
          <User />
          <div className="small">Account</div>
        </NavLink>
        <NavLink to="/logout">
          <LogOut />
          <div className="small">Logout</div>
        </NavLink>
      </div>
    </nav>
  );
};
