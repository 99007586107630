import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL
}) as unknown as ApolloLink;

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-access-token': localStorage.getItem('x-access-token') || ''
  }
}));

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === 'Unauthorized Request') {
        localStorage.removeItem('x-access-token');
        window.location.replace('/login');
      }
    });
  }
});

// Custom function to recursively replace empty strings with null
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replaceEmptyStringsWithNull(value: any) {
  if (typeof value === 'object' && value !== null) {
    Object.keys(value).forEach((key) => {
      if (typeof value[key] === 'string' && value[key].trim() === '') {
        value[key] = null;
      } else if (typeof value[key] === 'object') {
        replaceEmptyStringsWithNull(value[key]);
      }
    });
  }
  return value;
}

const cleanVariablesLink = new ApolloLink((operation, forward) => {
  const { variables } = operation;
  operation.variables = replaceEmptyStringsWithNull(variables);
  return forward(operation);
});

const client = new ApolloClient({
  link: from([authLink, errorLink, cleanVariablesLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    },
    watchQuery: {
      fetchPolicy: 'network-only'
    },
    mutate: {
      fetchPolicy: 'network-only'
    }
  }
});

export default client;
