import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Card, CardBody } from 'reactstrap';
import {
  AddOnType,
  Button,
  Pulse,
  TextInput,
  Toggle
} from '../../../components';
import {
  useGetGeneralBusinessSettingsQuery,
  useUpdateBusinessSettingsMutation
} from './__generated__/graphql';
import {
  UpdateBusinessInput,
  namedOperations
} from '../../../__generated__/graphql';
import { pick } from 'lodash';

type BusinessSettings = {
  appointmentBuffer: number;
  appointmentBookingEnabled: boolean;
  maintenanceEnabled: boolean;
  appointmentMaxBookingDays: number;
};

const baseBusinessSetingskeys: Array<keyof UpdateBusinessInput> = [
  'appointmentBuffer',
  'appointmentBookingEnabled',
  'maintenanceEnabled',
  'appointmentMaxBookingDays'
];

export const GeneralSettings: React.FC = () => {
  const { data } = useGetGeneralBusinessSettingsQuery();

  const settings = useMemo(() => data?.getBusiness, [data]);

  useEffect(() => {
    if (settings) {
      reset(settings);
    }
  }, [settings]);

  const [updateBusinessSettings, { loading: updateBusinessSettingsLoading }] =
    useUpdateBusinessSettingsMutation();

  const form = useForm<BusinessSettings>();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = form;

  const handleUpdateBusinessSettings = (data: BusinessSettings) => {
    updateBusinessSettings({
      variables: { data: pick(data, baseBusinessSetingskeys) },
      onCompleted: () => {
        toast.success('Settings successfully updated!', {
          className: 'bg-primary'
        });
      },
      refetchQueries: [namedOperations.Query.GetBusinessSettings]
    });
  };

  if (!settings) {
    return <Pulse />;
  }

  return (
    <Form onSubmit={handleSubmit(handleUpdateBusinessSettings)}>
      <Row>
        <Col md="12">
          <h2>General Settings</h2>
          <Card className="flex-fill">
            <CardBody className="mb--4">
              <Toggle
                label="Maintenance Mode"
                helperText="Temporarily disable all non-administrator user access to the business"
                defaultChecked={settings.maintenanceEnabled}
                {...register('maintenanceEnabled')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h2>Appointment Settings</h2>
          <Card className="flex-fill">
            <CardBody className="mb--3">
              <Row>
                <Col lg="4">
                  <TextInput
                    label="Appointment Buffer"
                    helperText="The minimum amount of time between appointments"
                    error={errors.appointmentBuffer?.message}
                    inputAddon={{
                      addonType: AddOnType.Append,
                      value: 'minutes'
                    }}
                    defaultValue={settings.appointmentBuffer}
                    {...register('appointmentBuffer', {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: 'Appointment buffer can not be negative'
                      },
                      required: 'Appointment buffer is required'
                    })}
                  />
                </Col>
                <Col lg="4">
                  <TextInput
                    label="Appointment Lead Time"
                    helperText="How far in advance a user can book an appointment"
                    error={errors.appointmentMaxBookingDays?.message}
                    type="number"
                    inputAddon={{
                      addonType: AddOnType.Append,
                      value: 'days'
                    }}
                    defaultValue={settings.appointmentMaxBookingDays}
                    {...register('appointmentMaxBookingDays', {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: 'Appointment lead time can not be negative'
                      },
                      required: 'Appointment lead time is required'
                    })}
                  />
                </Col>
                <Col lg="4">
                  <Toggle
                    label="Appointment Booking"
                    helperText="Enable/disable appointment booking for all users"
                    defaultChecked={settings.appointmentBookingEnabled}
                    {...register('appointmentBookingEnabled')}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Button color="primary" loading={updateBusinessSettingsLoading}>
        Save Changes
      </Button>
    </Form>
  );
};
