import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGeneralBusinessSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGeneralBusinessSettingsQuery = { getBusiness: { id: string, appointmentBuffer: number, appointmentBookingEnabled: boolean, maintenanceEnabled: boolean, appointmentMaxBookingDays: number } };

export type GetBusinessHoursSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessHoursSettingsQuery = { getBusiness: { id: string, businessHours: Array<{ id: string, day: Types.DayOfWeek, openTime: string, closeTime: string }> } };

export type UpdateBusinessSettingsMutationVariables = Types.Exact<{
  data: Types.UpdateBusinessInput;
}>;


export type UpdateBusinessSettingsMutation = { updateBusiness: { id: string } };

export type UpdateBusinessHoursMutationVariables = Types.Exact<{
  data: Array<Types.UpdateBusinessHourInput> | Types.UpdateBusinessHourInput;
}>;


export type UpdateBusinessHoursMutation = { updateBusinessHours: Array<{ id: string }> };


export const GetGeneralBusinessSettingsDocument = gql`
    query GetGeneralBusinessSettings {
  getBusiness {
    id
    appointmentBuffer
    appointmentBookingEnabled
    maintenanceEnabled
    appointmentMaxBookingDays
  }
}
    `;

/**
 * __useGetGeneralBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useGetGeneralBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralBusinessSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralBusinessSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetGeneralBusinessSettingsQuery, GetGeneralBusinessSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneralBusinessSettingsQuery, GetGeneralBusinessSettingsQueryVariables>(GetGeneralBusinessSettingsDocument, options);
      }
export function useGetGeneralBusinessSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneralBusinessSettingsQuery, GetGeneralBusinessSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneralBusinessSettingsQuery, GetGeneralBusinessSettingsQueryVariables>(GetGeneralBusinessSettingsDocument, options);
        }
export type GetGeneralBusinessSettingsQueryHookResult = ReturnType<typeof useGetGeneralBusinessSettingsQuery>;
export type GetGeneralBusinessSettingsLazyQueryHookResult = ReturnType<typeof useGetGeneralBusinessSettingsLazyQuery>;
export type GetGeneralBusinessSettingsQueryResult = Apollo.QueryResult<GetGeneralBusinessSettingsQuery, GetGeneralBusinessSettingsQueryVariables>;
export const GetBusinessHoursSettingsDocument = gql`
    query GetBusinessHoursSettings {
  getBusiness {
    id
    businessHours {
      id
      day
      openTime
      closeTime
    }
  }
}
    `;

/**
 * __useGetBusinessHoursSettingsQuery__
 *
 * To run a query within a React component, call `useGetBusinessHoursSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessHoursSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessHoursSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessHoursSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessHoursSettingsQuery, GetBusinessHoursSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessHoursSettingsQuery, GetBusinessHoursSettingsQueryVariables>(GetBusinessHoursSettingsDocument, options);
      }
export function useGetBusinessHoursSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessHoursSettingsQuery, GetBusinessHoursSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessHoursSettingsQuery, GetBusinessHoursSettingsQueryVariables>(GetBusinessHoursSettingsDocument, options);
        }
export type GetBusinessHoursSettingsQueryHookResult = ReturnType<typeof useGetBusinessHoursSettingsQuery>;
export type GetBusinessHoursSettingsLazyQueryHookResult = ReturnType<typeof useGetBusinessHoursSettingsLazyQuery>;
export type GetBusinessHoursSettingsQueryResult = Apollo.QueryResult<GetBusinessHoursSettingsQuery, GetBusinessHoursSettingsQueryVariables>;
export const UpdateBusinessSettingsDocument = gql`
    mutation UpdateBusinessSettings($data: UpdateBusinessInput!) {
  updateBusiness(data: $data) {
    id
  }
}
    `;
export type UpdateBusinessSettingsMutationFn = Apollo.MutationFunction<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>;

/**
 * __useUpdateBusinessSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessSettingsMutation, { data, loading, error }] = useUpdateBusinessSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusinessSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>(UpdateBusinessSettingsDocument, options);
      }
export type UpdateBusinessSettingsMutationHookResult = ReturnType<typeof useUpdateBusinessSettingsMutation>;
export type UpdateBusinessSettingsMutationResult = Apollo.MutationResult<UpdateBusinessSettingsMutation>;
export type UpdateBusinessSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>;
export const UpdateBusinessHoursDocument = gql`
    mutation UpdateBusinessHours($data: [UpdateBusinessHourInput!]!) {
  updateBusinessHours(data: $data) {
    id
  }
}
    `;
export type UpdateBusinessHoursMutationFn = Apollo.MutationFunction<UpdateBusinessHoursMutation, UpdateBusinessHoursMutationVariables>;

/**
 * __useUpdateBusinessHoursMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessHoursMutation, { data, loading, error }] = useUpdateBusinessHoursMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusinessHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessHoursMutation, UpdateBusinessHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessHoursMutation, UpdateBusinessHoursMutationVariables>(UpdateBusinessHoursDocument, options);
      }
export type UpdateBusinessHoursMutationHookResult = ReturnType<typeof useUpdateBusinessHoursMutation>;
export type UpdateBusinessHoursMutationResult = Apollo.MutationResult<UpdateBusinessHoursMutation>;
export type UpdateBusinessHoursMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessHoursMutation, UpdateBusinessHoursMutationVariables>;