import { UserRole } from '../../__generated__/graphql';
import { Option } from '../../types';
import { useGetUserOptionsQuery } from './__generated__/graphql';

export const useUserOptions = ({
  includeInactive = false,
  filterByRole,
  filterByCompanyId
}: {
  includeInactive?: boolean;
  filterByRole?: UserRole;
  filterByCompanyId?: string | null;
} = {}): Option[] => {
  const { data } = useGetUserOptionsQuery({
    variables: {
      includeInactive
    }
  });

  return (data?.getUsers ?? [])
    .filter(({ role }) => {
      if (filterByRole) {
        return role === filterByRole;
      }

      return true;
    })
    .filter(({ companyId }) => {
      if (filterByCompanyId) {
        return filterByCompanyId === companyId;
      }

      return true;
    })
    .map(
      ({ id, firstName, lastName }): Option => ({
        value: id,
        label: `${firstName} ${lastName}`
      })
    );
};
