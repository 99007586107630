import { pick } from 'lodash';
import {
  AppointmentOptions,
  CreateAppointmentInput,
  UpdateAppointmentInput
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useCreateBusinessAppointmentMutation,
  useCancelBusinessAppointmentMutation,
  useGetBusinessAppointmentLazyQuery,
  useUpdateBusinessAppointmentMutation
} from './__generated__/graphql';
import { useMemo } from 'react';
import { Appointment } from './AppointmentModal';

type UseAppointmentActionsOptions = {
  toggle: () => void;
  toggleCancelAppointmentModal: () => void;
};

export const baseAppointmentKeys: Array<
  keyof CreateAppointmentInput & keyof UpdateAppointmentInput
> = ['userId', 'resourceId', 'notes', 'internalNotes', 'startAt', 'endAt'];

export const useAppointmentActions = ({
  toggle,
  toggleCancelAppointmentModal
}: UseAppointmentActionsOptions) => {
  const [
    getAppointment,
    { data: getAppointmentData, loading: getAppointmentLoading }
  ] = useGetBusinessAppointmentLazyQuery();

  const appointment = useMemo(
    () => getAppointmentData?.getAppointment,
    [getAppointmentData]
  );

  const [createAppointment, { loading: createAppointmentLoading }] =
    useCreateBusinessAppointmentMutation({
      onCompleted: () => {
        toggle();
        notify({
          message: 'Appointment successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating appointment!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateAppointment, { loading: updateAppointmentLoading }] =
    useUpdateBusinessAppointmentMutation({
      onCompleted: () => {
        toggle();
        notify({
          message: 'Appointment successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating appointment!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useCancelBusinessAppointmentMutation({
      onCompleted: () => {
        toggleCancelAppointmentModal();
        toggle();
        notify({
          message: 'Appointment successfully cancelled!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error cancelling appointment!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateAppointment = async (
    data: Appointment,
    options: AppointmentOptions
  ) => {
    await createAppointment({
      variables: { data: pick(data, baseAppointmentKeys), options }
    });
  };

  const handleUpdateAppointment = async (
    data: Appointment,
    options: AppointmentOptions
  ) => {
    if (appointment) {
      await updateAppointment({
        variables: {
          id: appointment.id,
          data: pick(data, baseAppointmentKeys),
          options
        }
      });
    }
  };

  const handleCancelAppointment = async (options: AppointmentOptions) => {
    if (appointment) {
      const params = {
        variables: {
          id: appointment.id,
          options
        }
      };

      await cancelAppointment(params);
    }
  };

  return {
    appointment: getAppointmentData?.getAppointment,
    getAppointment,
    getAppointmentLoading,
    handleCreateAppointment,
    createAppointmentLoading,
    handleUpdateAppointment,
    updateAppointmentLoading,
    handleCancelAppointment,
    cancelAppointmentLoading
  };
};
