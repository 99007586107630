import React from 'react';
import { Button } from '../../../../components';
import { Star } from 'react-feather';
import { useCleanLogNotesMutation } from '../__generated__/graphql';
import { useFormContext } from 'react-hook-form';
import { Log } from './LogCard';

export const CleanNotesButton: React.FC = () => {
  const [cleanNotes, { loading }] = useCleanLogNotesMutation();

  const { watch, setValue } = useFormContext<Log>();
  const notes = watch('notes');

  const handleCleanNotes = async () => {
    if (notes) {
      const { data } = await cleanNotes({
        variables: {
          notes
        }
      });

      if (data?.cleanNotes) {
        setValue('notes', data.cleanNotes, { shouldDirty: true });
      }
    }
  };
  return (
    <Button
      size="sm"
      color="dark"
      className="mb-4"
      onClick={handleCleanNotes}
      disabled={!notes?.length}
      loading={loading}
    >
      <Star size={14} className="mr-1" />
      Cleanup Notes
    </Button>
  );
};
