import React, { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CSVLink } from 'react-csv';
import {
  GetBusinessCompaniesQuery,
  useGetBusinessCompaniesQuery
} from './__generated__/graphql';
import { Table, Toggle } from '../../../components';
import { useModal } from '../../../hooks';
import { CompanyModal } from './CompanyModal';
import { phoneTierOptions } from '../../../constants';
import { Filter } from 'react-feather';
import { formatPhoneNumberNational } from '../../../utils';

type Company = GetBusinessCompaniesQuery['getCompanies'][0];

const columns: Column<Company>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Email',
    accessor: (company) => company.email ?? '—'
  },
  {
    Header: 'Phone Number',
    accessor: (company) =>
      company.phoneNumber
        ? formatPhoneNumberNational(company.phoneNumber)
        : '—',
    disableSortBy: true
  },
  {
    Header: 'Membership',
    accessor: (company) =>
      `${company.membership.name} ${company.membership.emoji}`
  },
  {
    Header: 'Phone Tier',
    accessor: (company) =>
      phoneTierOptions.find(
        (phoneTier) => phoneTier.value === company.phoneTier
      )?.label || '—'
  },
  {
    Header: '# Active Users',
    accessor: 'activeUserCount',
    disableSortBy: true
  },
  {
    Header: 'Active',
    accessor: 'deactivatedAt',
    disableSortBy: true,
    Cell: ({ value: deactivatedAt }) => {
      return <Toggle checked={!deactivatedAt} disabled />;
    }
  }
];

const headers = [
  { label: 'ID', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Fax Number', key: 'faxNumber' },
  { label: 'Toll Number', key: 'tollNumber' },
  { label: 'Website', key: 'websiteUrl' },
  { label: 'Membership', key: 'membership.name' },
  { label: '# Active Users', key: 'activeUserCount' }
];

const exportSettings = { headers, filename: 'companies.csv' };

export const Companies: React.FC = () => {
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);

  const [includeInactive, setIncludeInactive] = useState(false);

  const { isOpen: isCompanyModalOpen, toggleModal: toggleCompanyModal } =
    useModal();

  const {
    isOpen: isCompanyFiltersModalOpen,
    toggleModal: toggleCompanyFiltersModal
  } = useModal();

  const { loading } = useGetBusinessCompaniesQuery({
    onCompleted: (data) => setCompanies(data.getCompanies)
  });

  const handleSelect = (company: Company) => {
    setCompanyId(company.id);
    toggleCompanyModal();
  };

  const handleCreate = () => {
    setCompanyId(null);
    toggleCompanyModal();
  };

  const data = useMemo(() => {
    return includeInactive
      ? companies
      : companies.filter((company) => !company.deactivatedAt);
  }, [includeInactive, companies]);

  return (
    <>
      <CompanyModal
        isOpen={isCompanyModalOpen}
        toggle={toggleCompanyModal}
        companyId={companyId}
      />
      <Table<Company>
        loading={loading}
        data={data}
        columns={columns}
        handleSelect={handleSelect}
        sortBy={{
          id: 'name',
          desc: false
        }}
      >
        <>
          <Modal
            centered
            isOpen={isCompanyFiltersModalOpen}
            toggle={toggleCompanyFiltersModal}
          >
            <ModalHeader className="pb-0 pl-4">Search Filters</ModalHeader>
            <ModalBody>
              <Toggle
                label="Include Inactive Companies"
                helperText="Enable to include inactive companies in the search results."
                checked={includeInactive}
                onChange={() => setIncludeInactive(!includeInactive)}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleCompanyFiltersModal}>Close</Button>
            </ModalFooter>
          </Modal>
          <CSVLink
            data={Object.values(data)}
            filename={exportSettings.filename}
            headers={exportSettings.headers}
            target="_blank"
          >
            <Button size="sm" color="primary">
              Export
            </Button>
          </CSVLink>
          <Button
            size="sm"
            color="default"
            className="ml-2"
            onClick={handleCreate}
          >
            Create
          </Button>
          <Button size="sm" onClick={toggleCompanyFiltersModal}>
            <Filter size={14} color="#606E80" /> Filter
          </Button>
        </>
      </Table>
    </>
  );
};
