import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAvailableResourcesQueryVariables = Types.Exact<{
  options: Types.GetAvailableResourcesOptions;
}>;


export type GetAvailableResourcesQuery = { getAvailableResources: Array<{ id: string, name: string, capacity: number, type: Types.ResourceType, images: Array<{ id: string, url: string }> }> };


export const GetAvailableResourcesDocument = gql`
    query GetAvailableResources($options: GetAvailableResourcesOptions!) {
  getAvailableResources(options: $options) {
    id
    name
    capacity
    type
    images {
      id
      url
    }
  }
}
    `;

/**
 * __useGetAvailableResourcesQuery__
 *
 * To run a query within a React component, call `useGetAvailableResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableResourcesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAvailableResourcesQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableResourcesQuery, GetAvailableResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableResourcesQuery, GetAvailableResourcesQueryVariables>(GetAvailableResourcesDocument, options);
      }
export function useGetAvailableResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableResourcesQuery, GetAvailableResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableResourcesQuery, GetAvailableResourcesQueryVariables>(GetAvailableResourcesDocument, options);
        }
export type GetAvailableResourcesQueryHookResult = ReturnType<typeof useGetAvailableResourcesQuery>;
export type GetAvailableResourcesLazyQueryHookResult = ReturnType<typeof useGetAvailableResourcesLazyQuery>;
export type GetAvailableResourcesQueryResult = Apollo.QueryResult<GetAvailableResourcesQuery, GetAvailableResourcesQueryVariables>;