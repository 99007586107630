import React from 'react';
import { TextInput, TextInputProps } from '../TextInput';
import { formatPhoneNumber, formatPhoneNumberNational } from '../../../utils';
import { AddOnType } from '../InputAddon';
import { Phone } from 'react-feather';

export type PhoneNumberInputProps = Omit<
  TextInputProps,
  'value' | 'onChange' | 'ref'
> & {
  value?: string | null;
  onChange: (value: string) => void;
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  ...rest
}) => {
  return (
    <TextInput
      label="Phone Number"
      placeholder="(555) 555-5555"
      inputAddon={{
        addonType: AddOnType.Prepend,
        value: <Phone color="#606E80" size="16" />
      }}
      value={formatPhoneNumberNational(value)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onChange(formatPhoneNumber(event.target.value, 'E.164'))
      }
      {...rest}
    />
  );
};
