import { pick } from 'lodash';
import {
  CreateCompanyLocationInput,
  UpdateCompanyLocationInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useDeleteCompanyLocationMutation,
  useCreateCompanyLocationMutation,
  useUpdateCompanyLocationMutation,
  useGetCompanyLocationLazyQuery
} from './__generated__/graphql';
import { useMemo } from 'react';
import { CompanyLocation } from './CompanyLocationsModal';

type UseCompanyLocationActionsOptions = {
  toggle: () => void;
  toggleDeleteCompanyLocationModal: () => void;
};

const baseCompanyLocationKeys: Array<
  keyof CreateCompanyLocationInput & keyof UpdateCompanyLocationInput
> = [
  'addressLine1',
  'addressLine2',
  'addressCity',
  'addressState',
  'addressZip',
  'label',
  'isPrimary'
];

const createCompanyLocationKeys: Array<keyof CreateCompanyLocationInput> = [
  ...baseCompanyLocationKeys,
  'companyId'
];

const updateCompanyLocationKeys: Array<keyof UpdateCompanyLocationInput> =
  baseCompanyLocationKeys;

export const useCompanyLocationActions = ({
  toggle,
  toggleDeleteCompanyLocationModal
}: UseCompanyLocationActionsOptions) => {
  const [
    getCompanyLocation,
    { data: getCompanyLocationData, loading: getCompanyLocationLoading }
  ] = useGetCompanyLocationLazyQuery();

  const companyLocation = useMemo(
    () => getCompanyLocationData?.getCompanyLocation,
    [getCompanyLocationData]
  );

  const refetchQueries = [namedOperations.Query.GetCompanyLocations];

  const [createCompanyLocation, { loading: createCompanyLocationLoading }] =
    useCreateCompanyLocationMutation({
      refetchQueries,
      onCompleted: () => {
        toggle();
        notify({
          message: 'Company location successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating company location!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateCompanyLocation, { loading: updateCompanyLocationLoading }] =
    useUpdateCompanyLocationMutation({
      refetchQueries,
      onCompleted: () => {
        toggle();
        notify({
          message: 'Company location successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating company location!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [deleteCompanyLocation, { loading: deleteCompanyLocationLoading }] =
    useDeleteCompanyLocationMutation({
      refetchQueries,
      onCompleted: () => {
        toggleDeleteCompanyLocationModal();
        toggle();
        notify({
          message: 'Company location successfully deleted!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deleting company location!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateCompanyLocation = async (data: CompanyLocation) => {
    await createCompanyLocation({
      variables: { data: pick(data, createCompanyLocationKeys) }
    });
  };

  const handleUpdateCompanyLocation = async (data: CompanyLocation) => {
    if (companyLocation) {
      await updateCompanyLocation({
        variables: {
          id: companyLocation.id,
          data: pick(data, updateCompanyLocationKeys)
        }
      });
    }
  };

  const handleDeleteCompanyLocation = async () => {
    if (companyLocation) {
      const options = {
        variables: {
          id: companyLocation.id
        }
      };

      await deleteCompanyLocation(options);
    }
  };

  return {
    companyLocation: getCompanyLocationData?.getCompanyLocation,
    getCompanyLocation,
    getCompanyLocationLoading,
    handleCreateCompanyLocation,
    createCompanyLocationLoading,
    handleUpdateCompanyLocation,
    updateCompanyLocationLoading,
    handleDeleteCompanyLocation,
    deleteCompanyLocationLoading
  };
};
