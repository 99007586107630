import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { useLogContext } from '../../../../contexts';
import { InteractionHistoryTab } from './InteractionHistoryTab';
import { CompanyInformationTab } from './CompanyInformationTab';
import { CompanyAlertsTable } from '../../../../components';
import { CompanyIntakeTab } from './CompanyIntakeTab';
import { CompanyTrainingTab } from './CompanyTrainingTab';

export const InformationCard: React.FC = () => {
  const { company, phoneNumber } = useLogContext();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = useMemo(
    () => [
      {
        id: 'interaction_history',
        label: 'Interaction History',
        isVisible: Boolean(phoneNumber),
        element: <InteractionHistoryTab />
      },
      {
        id: 'company_alerts',
        label: (
          <div className="d-flex">
            <div>Alerts</div>
            <Badge
              color="primary"
              className={classnames('ml-1', {
                'd-none': company?.activeAlertCount === 0
              })}
              style={{ width: '22.5px' }}
              pill
            >
              {company?.activeAlertCount}
            </Badge>
          </div>
        ),
        isVisible: Boolean(company),
        element: company && <CompanyAlertsTable companyId={company.id} />
      },
      {
        id: 'company_information',
        label: 'Company Information',
        isVisible: Boolean(company),
        element: <CompanyInformationTab />
      },
      {
        id: 'company_intake',
        label: 'Intake',
        isVisible: Boolean(company),
        element: <CompanyIntakeTab />
      },
      {
        id: 'company_training',
        label: 'Training',
        isVisible: Boolean(company),
        element: <CompanyTrainingTab />
      }
    ],
    [company, phoneNumber]
  );

  const visibleTabs = useMemo(
    () => tabs.filter((tab) => tab.isVisible),
    [tabs]
  );

  if (visibleTabs.length === 0) {
    return null;
  }

  return (
    <Card className="flex-grow-1 mb-0" style={{ maxHeight: '90vh' }}>
      <CardHeader style={{ border: 0 }} className="pb-2">
        <Nav tabs className="pb-0">
          {visibleTabs.map((tab, index) => (
            <NavItem key={tab.id}>
              <NavLink
                className={classnames({ active: activeTab === index })}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <hr className="m-0" />
      </CardHeader>
      <CardBody className="pt-3" style={{ overflow: 'scroll' }}>
        {visibleTabs[activeTab]?.element}
      </CardBody>
    </Card>
  );
};
