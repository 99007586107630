import { pick } from 'lodash';
import {
  CreateCompanyAlertInput,
  UpdateCompanyAlertInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useDeleteCompanyAlertMutation,
  useCreateCompanyAlertMutation,
  useUpdateCompanyAlertMutation,
  useGetCompanyAlertLazyQuery
} from './__generated__/graphql';
import { useMemo } from 'react';
import { CompanyAlert } from './CompanyAlertModal';

type UseCompanyAlertActionsOptions = {
  toggle: () => void;
  toggleDeleteCompanyAlertModal: () => void;
};

const baseCompanyAlertKeys: Array<
  keyof CreateCompanyAlertInput & keyof UpdateCompanyAlertInput
> = ['note', 'startAt', 'expiresAt'];

const createCompanyAlertKeys: Array<keyof CreateCompanyAlertInput> = [
  ...baseCompanyAlertKeys,
  'companyId'
];

const updateCompanyAlertKeys: Array<keyof UpdateCompanyAlertInput> =
  baseCompanyAlertKeys;

export const useCompanyAlertActions = ({
  toggle,
  toggleDeleteCompanyAlertModal
}: UseCompanyAlertActionsOptions) => {
  const [
    getCompanyAlert,
    { data: getCompanyAlertData, loading: getCompanyAlertLoading }
  ] = useGetCompanyAlertLazyQuery();

  const companyAlert = useMemo(
    () => getCompanyAlertData?.getCompanyAlert,
    [getCompanyAlertData]
  );

  const refetchQueries = [
    namedOperations.Query.GetCompanyAlerts,
    namedOperations.Query.GetCompanyAlertsCount,
    namedOperations.Query.GetCompanyForLog
  ];

  const [createCompanyAlert, { loading: createCompanyAlertLoading }] =
    useCreateCompanyAlertMutation({
      refetchQueries,
      onCompleted: () => {
        toggle();
        notify({
          message: 'Company alert successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating company alert!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateCompanyAlert, { loading: updateCompanyAlertLoading }] =
    useUpdateCompanyAlertMutation({
      refetchQueries,
      onCompleted: () => {
        toggle();
        notify({
          message: 'Company alert successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating company alert!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [deleteCompanyAlert, { loading: deleteCompanyAlertLoading }] =
    useDeleteCompanyAlertMutation({
      refetchQueries,
      onCompleted: () => {
        toggleDeleteCompanyAlertModal();
        toggle();
        notify({
          message: 'Company alert successfully deleted!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deleting company alert!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateCompanyAlert = async (data: CompanyAlert) => {
    await createCompanyAlert({
      variables: { data: pick(data, createCompanyAlertKeys) }
    });
  };

  const handleUpdateCompanyAlert = async (data: CompanyAlert) => {
    if (companyAlert) {
      await updateCompanyAlert({
        variables: {
          id: companyAlert.id,
          data: pick(data, updateCompanyAlertKeys)
        }
      });
    }
  };

  const handleDeleteCompanyAlert = async () => {
    if (companyAlert) {
      const options = {
        variables: {
          id: companyAlert.id
        }
      };

      await deleteCompanyAlert(options);
    }
  };

  return {
    companyAlert: getCompanyAlertData?.getCompanyAlert,
    getCompanyAlert,
    getCompanyAlertLoading,
    handleCreateCompanyAlert,
    createCompanyAlertLoading,
    handleUpdateCompanyAlert,
    updateCompanyAlertLoading,
    handleDeleteCompanyAlert,
    deleteCompanyAlertLoading
  };
};
