import { pick } from 'lodash';
import { UpdateLogInput } from '../../../../__generated__/graphql';
import { showErrorToast, showSuccessToast } from '../../../../utils/toast';
import {
  GetLogQuery,
  useCompleteLogMutation,
  useDeleteLogMutation,
  useGetLogLazyQuery,
  useUpdateLogMutation
} from '../__generated__/graphql';
import { useNavigate } from 'react-router-dom';

const baseLogKeys: Array<keyof UpdateLogInput> = [
  'firstName',
  'lastName',
  'phoneNumber',
  'email',
  'notes',
  'internalNotes',
  'status',
  'type',
  'tags',
  'addressLine1',
  'addressLine2',
  'addressCity',
  'addressState',
  'addressZip',
  'rating',
  'companyId',
  'appointmentAt',
  'fileUploadIds'
];

type UseLogActions = {
  fetchLog: (
    logId: string
  ) => Promise<GetLogQuery['getLog'] | null | undefined>;
  fetchLogLoading: boolean;
  updateLog: (logId: string, data: UpdateLogInput) => Promise<void>;
  updateLogLoading: boolean;
  completeLog: (logId: string, notifyUserIds: string[]) => Promise<void>;
  completeLogLoading: boolean;
  deleteLog: (logId: string) => Promise<void>;
  deleteLogLoading: boolean;
};

export const useLogActions = (): UseLogActions => {
  const navigate = useNavigate();

  const [getLog, { loading: fetchLogLoading }] = useGetLogLazyQuery();

  const fetchLog = async (
    logId: string
  ): Promise<GetLogQuery['getLog'] | null | undefined> => {
    try {
      const { data } = await getLog({ variables: { id: logId } });

      if (!data?.getLog) {
        showErrorToast('Error fetching log!');
      }

      return data?.getLog;
    } catch (error) {
      showErrorToast('Error fetching log!');
    }
  };

  const [updateLog, { loading: updateLogLoading }] = useUpdateLogMutation();

  const handleUpdateLog = async (logId: string, data: UpdateLogInput) => {
    try {
      await updateLog({
        variables: { id: logId, data: pick(data, baseLogKeys) }
      });
    } catch (error) {
      showErrorToast('Error updating log!');
    }
  };

  const [completeLog, { loading: completeLogLoading }] =
    useCompleteLogMutation();

  const handleCompleteLog = async (logId: string, notifyUserIds: string[]) => {
    try {
      await completeLog({ variables: { id: logId, notifyUserIds } });
      showSuccessToast('Log successfully completed!');
      navigate('/business/logs');
    } catch (error) {
      showErrorToast('Error completing log!');
    }
  };

  const [deleteLog, { loading: deleteLogLoading }] = useDeleteLogMutation();

  const handleDeleteLog = async (logId: string) => {
    try {
      await deleteLog({ variables: { id: logId } });
      showSuccessToast('Log successfully deleted!');
      navigate('/business/logs');
    } catch (error) {
      showErrorToast('Error deleting log!');
    }
  };

  return {
    fetchLog,
    fetchLogLoading,
    updateLog: handleUpdateLog,
    updateLogLoading,
    completeLog: handleCompleteLog,
    completeLogLoading,
    deleteLog: handleDeleteLog,
    deleteLogLoading
  };
};
