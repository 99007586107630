import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  ConfirmationModal,
  DateInput,
  ModalType,
  Pulse,
  TextArea,
  TimeInput
} from '../../../components';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateUserAppointmentMutation,
  useGetResourceForAppointmentLazyQuery
} from './__generated__/graphql';
import moment from 'moment';
import Lottie from 'lottie-react';
import calendarAnimation from '../../../assets/animations/calendar.json';
import { useScheduling } from '../../../contexts';
import { useModal } from '../../../hooks';

export const ScheduleBook: React.FC = () => {
  const { id: resourceId } = useParams();
  const navigate = useNavigate();
  const {
    isOpen: isAppointmentErrorModalOpen,
    toggleModal: toggleAppointmentErrorModal
  } = useModal();

  const [createAppointmentLoading, setCreateAppointmentLoading] =
    useState(false);

  const [notes, setNotes] = useState('');
  const [error, setError] = useState<string | null>(null);

  const [createAppointment] = useCreateUserAppointmentMutation();

  const [getResource, { data, loading: getResourceLoading }] =
    useGetResourceForAppointmentLazyQuery();

  const {
    isValidDate,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    minStartAt,
    minEndAt,
    maxStartAt,
    maxEndAt,
    missingOperatingHours,
    loading: businessHoursLoading
  } = useScheduling();

  useEffect(() => {
    if (resourceId) {
      getResource({ variables: { id: resourceId } });
    }
  }, [resourceId]);

  const resource = useMemo(() => data?.getResource, [data]);

  const handleCreateAppointment = async () => {
    setError(null);
    setCreateAppointmentLoading(true);
    if (resourceId) {
      await createAppointment({
        variables: {
          data: {
            resourceId,
            startAt: startAt.toISOString(),
            endAt: endAt.toISOString(),
            notes
          }
        },
        onCompleted: ({ createAppointment }) => {
          setTimeout(() => {
            setCreateAppointmentLoading(false);
            navigate(`/schedule/confirmation/${createAppointment.id}`);
          }, 1500);
        },
        onError: ({ message }) => {
          setTimeout(() => {
            setCreateAppointmentLoading(false);
            setError(message);
            toggleAppointmentErrorModal();
          }, 1500);
        }
      });
    }
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value.substring(0, 500));
  };

  const handleDateChange = (value: string | moment.Moment) => {
    const newDate = moment(value);

    if (newDate.isValid()) {
      setStartAt(newDate);
    }
  };

  if (!resource || getResourceLoading || businessHoursLoading) {
    return <Pulse />;
  }

  if (missingOperatingHours) {
    navigate('/schedule/search');
  }

  return (
    <>
      <Modal isOpen={createAppointmentLoading} centered>
        <ModalBody className="text-center">
          <Lottie animationData={calendarAnimation} loop />
          <div className="mt-4">
            <h2>Confirming Appointment</h2>
            <p className="text-muted">Please wait a few moments...</p>
          </div>
        </ModalBody>
      </Modal>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isAppointmentErrorModalOpen}
        toggle={toggleAppointmentErrorModal}
        title="Appointment Error"
        body={<p>{error}</p>}
        cancelText="Close"
      />
      <Row className="d-flex justify-content-center py-4">
        <Col xl="7" lg="6" md="12" className="pb-4">
          <Card className="h-100">
            <CardHeader className="p-0">
              <img
                key={resource.images[0].id}
                src={resource.images[0].url}
                alt={resource.name}
                className="rounded-top w-100"
                style={{ maxHeight: '250px', objectFit: 'cover' }}
              />
            </CardHeader>
            <CardBody>
              <CardTitle className="h1 mb-0">{resource.name}</CardTitle>
              <small className="text-muted text-uppercase font-weight-normal">
                {resource.type}
                {' — '}
                {resource.capacity}
                {' Seats'}
              </small>
              <hr />
              <CardText className="font-weight-bold text-default">
                Description
              </CardText>
              <CardText className="text-muted" style={{ lineHeight: 2 }}>
                {resource.description}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col xl="5" lg="6" md="12" className="pb-4">
          <Card className="h-100">
            <CardHeader className="bg-white pb-0">
              <CardTitle className="h2 text-center">
                Appointment Details
              </CardTitle>
            </CardHeader>
            <CardBody className="pb-0">
              <Row>
                <Col xs="12">
                  <DateInput
                    label="Date"
                    value={startAt}
                    onChange={handleDateChange}
                    isValidDate={isValidDate}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <TimeInput
                    label="Start Time"
                    selected={startAt.toDate()}
                    onChange={(value) => value && setStartAt(moment(value))}
                    minTime={minStartAt.toDate()}
                    maxTime={maxStartAt.toDate()}
                  />
                </Col>
                <Col xs="6">
                  <TimeInput
                    label="End Time"
                    selected={endAt.toDate()}
                    onChange={(value) => value && setEndAt(moment(value))}
                    minTime={minEndAt.toDate()}
                    maxTime={maxEndAt.toDate()}
                  />
                </Col>
              </Row>
              <TextArea
                label="Notes"
                placeholder="Enter important details about your appointment here..."
                value={notes}
                onChange={handleNotesChange}
                rows={5}
              />
              <div className="text-muted text-right h5 pt-1">
                {`${notes.length} / 500`}
              </div>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                block
                color="primary"
                loading={createAppointmentLoading}
                onClick={handleCreateAppointment}
              >
                Book Appointment
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};
