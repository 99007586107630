import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Alert, Container as Layout } from 'reactstrap';
import classnames from 'classnames';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationRoute } from '../../../types';
import { useBusinessSettings, useRole } from '../../../hooks';
import { UserRole } from '../../../__generated__/graphql';
import { MobileNavigation, Navigation } from '../Navigation';
import { SchedulingProvider } from '../../../contexts';

export type ProtectedRouteProps = Omit<
  ApplicationRoute,
  'path' | 'authenticationRequired'
>;

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  title,
  element,
  roles,
  fullWidth
}) => {
  const [isAuthenticated, setAuthenticated] = useState(false);

  const accessToken = localStorage.getItem('x-access-token') || '';

  useEffect(() => {
    setAuthenticated(!!accessToken);
  }, [accessToken]);

  const role = useRole();
  const settings = useBusinessSettings();

  if (!role || !settings) {
    return null;
  }

  if (
    isAuthenticated &&
    settings.maintenanceEnabled &&
    role !== UserRole.Business
  ) {
    return <Navigate to="/maintenance" />;
  }

  const hasRole = !roles?.length || roles?.includes(role);

  if (isAuthenticated && hasRole) {
    return (
      <SchedulingProvider>
        <div>
          <Alert
            isOpen={settings.maintenanceEnabled}
            className="border-0 rounded-0 mb-0 bg-primary"
          >
            <FontAwesomeIcon icon={faWarning} className="mr-2" />
            Maintenance Mode Enabled - User access is currently disabled.
          </Alert>
          <div className="d-flex wrapper">
            <Navigation role={role} />
            <div
              id="page-content-wrapper"
              className={classnames({ toggled: true })}
            >
              <div className="content-wrapper">
                <Layout fluid={fullWidth} className="h-100">
                  <MobileNavigation />
                  <div style={{ paddingBottom: '0px' }}>
                    {title && (
                      <div>
                        <h1>{title}</h1>
                        <hr className="mt--1 solid" />
                      </div>
                    )}
                    {element}
                  </div>
                </Layout>
              </div>
            </div>
          </div>
        </div>
      </SchedulingProvider>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Navigate to="/page-not-found" />;
};
