import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyAlertsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyAlertsQuery = { getCompanyAlerts: Array<{ id: string, note: string, startAt: string | null, expiresAt: string | null, createdAt: string }> };

export type GetCompanyAlertQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyAlertQuery = { getCompanyAlert: { id: string, companyId: string, note: string, startAt: string | null, expiresAt: string | null, createdAt: string } };

export type CreateCompanyAlertMutationVariables = Types.Exact<{
  data: Types.CreateCompanyAlertInput;
}>;


export type CreateCompanyAlertMutation = { createCompanyAlert: { id: string } };

export type UpdateCompanyAlertMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateCompanyAlertInput;
}>;


export type UpdateCompanyAlertMutation = { updateCompanyAlert: { id: string } };

export type DeleteCompanyAlertMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteCompanyAlertMutation = { deleteCompanyAlert: { id: string } };


export const GetCompanyAlertsDocument = gql`
    query GetCompanyAlerts($companyId: UUID!) {
  getCompanyAlerts(companyId: $companyId) {
    id
    note
    startAt
    expiresAt
    createdAt
  }
}
    `;

/**
 * __useGetCompanyAlertsQuery__
 *
 * To run a query within a React component, call `useGetCompanyAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAlertsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyAlertsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyAlertsQuery, GetCompanyAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyAlertsQuery, GetCompanyAlertsQueryVariables>(GetCompanyAlertsDocument, options);
      }
export function useGetCompanyAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAlertsQuery, GetCompanyAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyAlertsQuery, GetCompanyAlertsQueryVariables>(GetCompanyAlertsDocument, options);
        }
export type GetCompanyAlertsQueryHookResult = ReturnType<typeof useGetCompanyAlertsQuery>;
export type GetCompanyAlertsLazyQueryHookResult = ReturnType<typeof useGetCompanyAlertsLazyQuery>;
export type GetCompanyAlertsQueryResult = Apollo.QueryResult<GetCompanyAlertsQuery, GetCompanyAlertsQueryVariables>;
export const GetCompanyAlertDocument = gql`
    query GetCompanyAlert($id: UUID!) {
  getCompanyAlert(id: $id) {
    id
    companyId
    note
    startAt
    expiresAt
    createdAt
  }
}
    `;

/**
 * __useGetCompanyAlertQuery__
 *
 * To run a query within a React component, call `useGetCompanyAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAlertQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyAlertQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyAlertQuery, GetCompanyAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyAlertQuery, GetCompanyAlertQueryVariables>(GetCompanyAlertDocument, options);
      }
export function useGetCompanyAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAlertQuery, GetCompanyAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyAlertQuery, GetCompanyAlertQueryVariables>(GetCompanyAlertDocument, options);
        }
export type GetCompanyAlertQueryHookResult = ReturnType<typeof useGetCompanyAlertQuery>;
export type GetCompanyAlertLazyQueryHookResult = ReturnType<typeof useGetCompanyAlertLazyQuery>;
export type GetCompanyAlertQueryResult = Apollo.QueryResult<GetCompanyAlertQuery, GetCompanyAlertQueryVariables>;
export const CreateCompanyAlertDocument = gql`
    mutation CreateCompanyAlert($data: CreateCompanyAlertInput!) {
  createCompanyAlert(data: $data) {
    id
  }
}
    `;
export type CreateCompanyAlertMutationFn = Apollo.MutationFunction<CreateCompanyAlertMutation, CreateCompanyAlertMutationVariables>;

/**
 * __useCreateCompanyAlertMutation__
 *
 * To run a mutation, you first call `useCreateCompanyAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyAlertMutation, { data, loading, error }] = useCreateCompanyAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyAlertMutation, CreateCompanyAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyAlertMutation, CreateCompanyAlertMutationVariables>(CreateCompanyAlertDocument, options);
      }
export type CreateCompanyAlertMutationHookResult = ReturnType<typeof useCreateCompanyAlertMutation>;
export type CreateCompanyAlertMutationResult = Apollo.MutationResult<CreateCompanyAlertMutation>;
export type CreateCompanyAlertMutationOptions = Apollo.BaseMutationOptions<CreateCompanyAlertMutation, CreateCompanyAlertMutationVariables>;
export const UpdateCompanyAlertDocument = gql`
    mutation UpdateCompanyAlert($id: UUID!, $data: UpdateCompanyAlertInput!) {
  updateCompanyAlert(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateCompanyAlertMutationFn = Apollo.MutationFunction<UpdateCompanyAlertMutation, UpdateCompanyAlertMutationVariables>;

/**
 * __useUpdateCompanyAlertMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAlertMutation, { data, loading, error }] = useUpdateCompanyAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyAlertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyAlertMutation, UpdateCompanyAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyAlertMutation, UpdateCompanyAlertMutationVariables>(UpdateCompanyAlertDocument, options);
      }
export type UpdateCompanyAlertMutationHookResult = ReturnType<typeof useUpdateCompanyAlertMutation>;
export type UpdateCompanyAlertMutationResult = Apollo.MutationResult<UpdateCompanyAlertMutation>;
export type UpdateCompanyAlertMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyAlertMutation, UpdateCompanyAlertMutationVariables>;
export const DeleteCompanyAlertDocument = gql`
    mutation DeleteCompanyAlert($id: UUID!) {
  deleteCompanyAlert(id: $id) {
    id
  }
}
    `;
export type DeleteCompanyAlertMutationFn = Apollo.MutationFunction<DeleteCompanyAlertMutation, DeleteCompanyAlertMutationVariables>;

/**
 * __useDeleteCompanyAlertMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyAlertMutation, { data, loading, error }] = useDeleteCompanyAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyAlertMutation, DeleteCompanyAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyAlertMutation, DeleteCompanyAlertMutationVariables>(DeleteCompanyAlertDocument, options);
      }
export type DeleteCompanyAlertMutationHookResult = ReturnType<typeof useDeleteCompanyAlertMutation>;
export type DeleteCompanyAlertMutationResult = Apollo.MutationResult<DeleteCompanyAlertMutation>;
export type DeleteCompanyAlertMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyAlertMutation, DeleteCompanyAlertMutationVariables>;