import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { Power } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse
} from '../..';
import { CompanyLocationForm } from './CompanyLocationsForm';
import { useCompanyLocationActions } from './useCompanyLocationActions';
import { useModal } from '../../../hooks';

type CompanyLocationModalProps = {
  isOpen: boolean;
  companyLocationId: string | null;
  companyId: string;
  toggle: () => void;
};

export type CompanyLocation = {
  isPrimary: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressZip: string | null;
  label: string | null;
  companyId: string;
};

enum CompanyLocationMode {
  Create = 'Create',
  Update = 'Update'
}

export const CompanyLocationModal: React.FC<CompanyLocationModalProps> = (
  props
) => {
  const { isOpen, toggle, companyLocationId, companyId } = props;

  const [mode, setMode] = useState<CompanyLocationMode>(
    CompanyLocationMode.Create
  );
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isDeleteCompanyLocationModalOpen,
    toggleModal: toggleDeleteCompanyLocationModal
  } = useModal();

  const {
    companyLocation,
    getCompanyLocation,
    getCompanyLocationLoading,
    handleCreateCompanyLocation,
    createCompanyLocationLoading,
    handleUpdateCompanyLocation,
    updateCompanyLocationLoading,
    handleDeleteCompanyLocation,
    deleteCompanyLocationLoading
  } = useCompanyLocationActions({ toggle, toggleDeleteCompanyLocationModal });

  const defaultCompanyLocation: CompanyLocation = {
    isPrimary: false,
    addressLine1: null,
    addressLine2: null,
    addressCity: null,
    addressState: null,
    addressZip: null,
    label: null,
    companyId
  };

  const form = useForm<CompanyLocation>({
    defaultValues: defaultCompanyLocation
  });

  useEffect(() => {
    form.reset(companyLocation);
  }, [companyLocation]);

  useEffect(() => {
    setLoading(createCompanyLocationLoading || updateCompanyLocationLoading);
  }, [createCompanyLocationLoading, updateCompanyLocationLoading]);

  useEffect(() => {
    if (isOpen) {
      if (companyLocationId) {
        setMode(CompanyLocationMode.Update);
        getCompanyLocation({
          variables: {
            id: companyLocationId
          }
        });
      } else {
        setMode(CompanyLocationMode.Create);
        form.reset(defaultCompanyLocation);
      }
    }
  }, [companyLocationId, isOpen]);

  const handleSubmit = (data: CompanyLocation) => {
    if (mode === CompanyLocationMode.Update) {
      handleUpdateCompanyLocation(data);
    } else {
      handleCreateCompanyLocation(data);
    }
  };

  const menuItems = [
    {
      icon: <Power size={14} />,
      label: 'Delete',
      onClick: toggleDeleteCompanyLocationModal
    }
  ];

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isDeleteCompanyLocationModalOpen}
        toggle={toggleDeleteCompanyLocationModal}
        title="Delete Company Location"
        body={
          <p>
            Are you sure you want to delete this company location? This action
            can not be reversed.
          </p>
        }
        loading={deleteCompanyLocationLoading}
        confirmationText="Yes, delete"
        onConfirm={handleDeleteCompanyLocation}
      />
      <Modal toggle={!loading ? toggle : () => null} isOpen={isOpen} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="editCompanyLocation">
            {`${mode} Company Location`}
          </h5>
          {companyLocation && mode === CompanyLocationMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getCompanyLocationLoading ? (
          <Pulse />
        ) : (
          <>
            <CompanyLocationForm form={form} />
            <ModalFooter>
              <Button disabled={loading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={loading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {mode}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
