import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessLogsQueryVariables = Types.Exact<{
  options: Types.InputMaybe<Types.GetLogsOptions>;
}>;


export type GetBusinessLogsQuery = { getLogs: { totalCount: number, logs: Array<{ id: string, firstName: string | null, lastName: string | null, phoneNumber: string | null, type: Types.LogType | null, status: Types.LogStatus, tags: Array<Types.LogTag>, notes: string | null, internalNotes: string | null, createdAt: string, company: { id: string, name: string } | null, author: { id: string, firstName: string, lastName: string } }> } };


export const GetBusinessLogsDocument = gql`
    query GetBusinessLogs($options: GetLogsOptions) {
  getLogs(options: $options) {
    logs {
      id
      company {
        id
        name
      }
      author {
        id
        firstName
        lastName
      }
      firstName
      lastName
      phoneNumber
      type
      status
      tags
      notes
      internalNotes
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useGetBusinessLogsQuery__
 *
 * To run a query within a React component, call `useGetBusinessLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessLogsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetBusinessLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessLogsQuery, GetBusinessLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessLogsQuery, GetBusinessLogsQueryVariables>(GetBusinessLogsDocument, options);
      }
export function useGetBusinessLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessLogsQuery, GetBusinessLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessLogsQuery, GetBusinessLogsQueryVariables>(GetBusinessLogsDocument, options);
        }
export type GetBusinessLogsQueryHookResult = ReturnType<typeof useGetBusinessLogsQuery>;
export type GetBusinessLogsLazyQueryHookResult = ReturnType<typeof useGetBusinessLogsLazyQuery>;
export type GetBusinessLogsQueryResult = Apollo.QueryResult<GetBusinessLogsQuery, GetBusinessLogsQueryVariables>;