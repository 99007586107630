import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyNotificationPreferencesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyNotificationPreferencesQuery = { getCompany: { id: string, users: Array<{ id: string, firstName: string, lastName: string, email: string, deactivatedAt: string | null, notificationPreference: { id: string, logTypes: Array<Types.LogType> } }> } };

export type SetUserNotificationPreferenceMutationVariables = Types.Exact<{
  data: Types.SetNotificationPreferenceInput;
}>;


export type SetUserNotificationPreferenceMutation = { setNotificationPreference: { id: string } };


export const GetCompanyNotificationPreferencesDocument = gql`
    query GetCompanyNotificationPreferences($companyId: UUID!) {
  getCompany(id: $companyId) {
    id
    users {
      id
      firstName
      lastName
      email
      notificationPreference {
        id
        logTypes
      }
      deactivatedAt
    }
  }
}
    `;

/**
 * __useGetCompanyNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetCompanyNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNotificationPreferencesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyNotificationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyNotificationPreferencesQuery, GetCompanyNotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyNotificationPreferencesQuery, GetCompanyNotificationPreferencesQueryVariables>(GetCompanyNotificationPreferencesDocument, options);
      }
export function useGetCompanyNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyNotificationPreferencesQuery, GetCompanyNotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyNotificationPreferencesQuery, GetCompanyNotificationPreferencesQueryVariables>(GetCompanyNotificationPreferencesDocument, options);
        }
export type GetCompanyNotificationPreferencesQueryHookResult = ReturnType<typeof useGetCompanyNotificationPreferencesQuery>;
export type GetCompanyNotificationPreferencesLazyQueryHookResult = ReturnType<typeof useGetCompanyNotificationPreferencesLazyQuery>;
export type GetCompanyNotificationPreferencesQueryResult = Apollo.QueryResult<GetCompanyNotificationPreferencesQuery, GetCompanyNotificationPreferencesQueryVariables>;
export const SetUserNotificationPreferenceDocument = gql`
    mutation SetUserNotificationPreference($data: SetNotificationPreferenceInput!) {
  setNotificationPreference(data: $data) {
    id
  }
}
    `;
export type SetUserNotificationPreferenceMutationFn = Apollo.MutationFunction<SetUserNotificationPreferenceMutation, SetUserNotificationPreferenceMutationVariables>;

/**
 * __useSetUserNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useSetUserNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNotificationPreferenceMutation, { data, loading, error }] = useSetUserNotificationPreferenceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetUserNotificationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<SetUserNotificationPreferenceMutation, SetUserNotificationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserNotificationPreferenceMutation, SetUserNotificationPreferenceMutationVariables>(SetUserNotificationPreferenceDocument, options);
      }
export type SetUserNotificationPreferenceMutationHookResult = ReturnType<typeof useSetUserNotificationPreferenceMutation>;
export type SetUserNotificationPreferenceMutationResult = Apollo.MutationResult<SetUserNotificationPreferenceMutation>;
export type SetUserNotificationPreferenceMutationOptions = Apollo.BaseMutationOptions<SetUserNotificationPreferenceMutation, SetUserNotificationPreferenceMutationVariables>;