import React, { useEffect, useState } from 'react';
import { Lock } from 'react-feather';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import Logo from '../../assets/images/logo.png';
import Success from '../../assets/images/success.png';
import { useUpdateUserPasswordMutation } from './__generated__/graphql';
import { Button } from '../../components';
import { RequestPasswordReset } from './RequestPasswordReset';

export const ResetPassword: React.FC = () => {
  const [seachParams] = useSearchParams();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordReset, setPasswordReset] = useState(false);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    document.body.classList.add('login-background');
    return () => {
      document.body.classList.remove('login-background');
    };
  });

  const [resetPassword, { loading }] = useUpdateUserPasswordMutation({
    variables: {
      data: {
        token: seachParams.get('token')!,
        newPassword: password
      }
    },
    onCompleted: () => setPasswordReset(true),
    onError: ({ message }) => {
      if (message === 'Invalid token') {
        setError('This password reset link is invalid');
      } else {
        setError(message);
      }
    }
  });

  const onSubmit = () => {
    setError(null);

    if (!password.trim()) {
      setError('Please enter a new password');
    } else if (!passwordConfirmation.trim()) {
      setError('Please enter the password confirmation');
    } else if (password.length < 6) {
      setError('Password must be at least 6 characters');
    } else if (password !== passwordConfirmation) {
      setError('Password confirmation does not match');
    } else {
      resetPassword();
    }
  };

  if (!seachParams.get('token')) {
    return <RequestPasswordReset />;
  }

  return (
    <Container>
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Col xl="4" lg="3" md="6">
          <Card className="p-3 pl-4 pr-4 m-0">
            <CardHeader className="text-center">
              <img src={Logo} width="200" alt="logo" className="img-fluid" />
            </CardHeader>
            <CardBody>
              {passwordReset ? (
                <>
                  <h3>Password Reset</h3>
                  <p>Your password has been successfully reset!</p>
                  <div className="text-center mb-2">
                    <img
                      src={Success}
                      alt="mail"
                      className="img-fluid"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                  <Link to="/">
                    <Button block color="primary">
                      Login
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <h3>Create new password</h3>
                  <p>Please enter your new password</p>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Lock color="#606E80" size={18} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        name="password"
                        autoComplete="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Lock color="#606E80" size={18} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        name="passwordConfirmation"
                        autoComplete="passwordConfirmation"
                        placeholder="Password Confirmation"
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                        value={passwordConfirmation}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  {error && (
                    <Alert color="danger" className="text-center p-2">
                      {error}
                    </Alert>
                  )}
                  <Button
                    block
                    color="primary"
                    loading={loading}
                    onClick={onSubmit}
                  >
                    Reset Password
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
