import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessAnnouncementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessAnnouncementsQuery = { getAnnouncements: Array<{ id: string, title: string, body: string, startDate: string, endDate: string }> };

export type GetBusinessAnnouncementQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetBusinessAnnouncementQuery = { getAnnouncement: { id: string, title: string, body: string, startDate: string, endDate: string } };

export type CreateBusinessAnnouncementMutationVariables = Types.Exact<{
  data: Types.CreateAnnouncementInput;
}>;


export type CreateBusinessAnnouncementMutation = { createAnnouncement: { id: string } };

export type UpdateBusinessAnnouncementMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateAnnouncementInput;
}>;


export type UpdateBusinessAnnouncementMutation = { updateAnnouncement: { id: string } };

export type DeleteBusinessAnnouncementMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteBusinessAnnouncementMutation = { deleteAnnouncement: { id: string } };


export const GetBusinessAnnouncementsDocument = gql`
    query GetBusinessAnnouncements {
  getAnnouncements {
    id
    title
    body
    startDate
    endDate
  }
}
    `;

/**
 * __useGetBusinessAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetBusinessAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessAnnouncementsQuery, GetBusinessAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessAnnouncementsQuery, GetBusinessAnnouncementsQueryVariables>(GetBusinessAnnouncementsDocument, options);
      }
export function useGetBusinessAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessAnnouncementsQuery, GetBusinessAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessAnnouncementsQuery, GetBusinessAnnouncementsQueryVariables>(GetBusinessAnnouncementsDocument, options);
        }
export type GetBusinessAnnouncementsQueryHookResult = ReturnType<typeof useGetBusinessAnnouncementsQuery>;
export type GetBusinessAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetBusinessAnnouncementsLazyQuery>;
export type GetBusinessAnnouncementsQueryResult = Apollo.QueryResult<GetBusinessAnnouncementsQuery, GetBusinessAnnouncementsQueryVariables>;
export const GetBusinessAnnouncementDocument = gql`
    query GetBusinessAnnouncement($id: UUID!) {
  getAnnouncement(id: $id) {
    id
    title
    body
    startDate
    endDate
  }
}
    `;

/**
 * __useGetBusinessAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetBusinessAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessAnnouncementQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessAnnouncementQuery, GetBusinessAnnouncementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessAnnouncementQuery, GetBusinessAnnouncementQueryVariables>(GetBusinessAnnouncementDocument, options);
      }
export function useGetBusinessAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessAnnouncementQuery, GetBusinessAnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessAnnouncementQuery, GetBusinessAnnouncementQueryVariables>(GetBusinessAnnouncementDocument, options);
        }
export type GetBusinessAnnouncementQueryHookResult = ReturnType<typeof useGetBusinessAnnouncementQuery>;
export type GetBusinessAnnouncementLazyQueryHookResult = ReturnType<typeof useGetBusinessAnnouncementLazyQuery>;
export type GetBusinessAnnouncementQueryResult = Apollo.QueryResult<GetBusinessAnnouncementQuery, GetBusinessAnnouncementQueryVariables>;
export const CreateBusinessAnnouncementDocument = gql`
    mutation CreateBusinessAnnouncement($data: CreateAnnouncementInput!) {
  createAnnouncement(data: $data) {
    id
  }
}
    `;
export type CreateBusinessAnnouncementMutationFn = Apollo.MutationFunction<CreateBusinessAnnouncementMutation, CreateBusinessAnnouncementMutationVariables>;

/**
 * __useCreateBusinessAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateBusinessAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessAnnouncementMutation, { data, loading, error }] = useCreateBusinessAnnouncementMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusinessAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessAnnouncementMutation, CreateBusinessAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessAnnouncementMutation, CreateBusinessAnnouncementMutationVariables>(CreateBusinessAnnouncementDocument, options);
      }
export type CreateBusinessAnnouncementMutationHookResult = ReturnType<typeof useCreateBusinessAnnouncementMutation>;
export type CreateBusinessAnnouncementMutationResult = Apollo.MutationResult<CreateBusinessAnnouncementMutation>;
export type CreateBusinessAnnouncementMutationOptions = Apollo.BaseMutationOptions<CreateBusinessAnnouncementMutation, CreateBusinessAnnouncementMutationVariables>;
export const UpdateBusinessAnnouncementDocument = gql`
    mutation UpdateBusinessAnnouncement($id: UUID!, $data: UpdateAnnouncementInput!) {
  updateAnnouncement(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateBusinessAnnouncementMutationFn = Apollo.MutationFunction<UpdateBusinessAnnouncementMutation, UpdateBusinessAnnouncementMutationVariables>;

/**
 * __useUpdateBusinessAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessAnnouncementMutation, { data, loading, error }] = useUpdateBusinessAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusinessAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessAnnouncementMutation, UpdateBusinessAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessAnnouncementMutation, UpdateBusinessAnnouncementMutationVariables>(UpdateBusinessAnnouncementDocument, options);
      }
export type UpdateBusinessAnnouncementMutationHookResult = ReturnType<typeof useUpdateBusinessAnnouncementMutation>;
export type UpdateBusinessAnnouncementMutationResult = Apollo.MutationResult<UpdateBusinessAnnouncementMutation>;
export type UpdateBusinessAnnouncementMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessAnnouncementMutation, UpdateBusinessAnnouncementMutationVariables>;
export const DeleteBusinessAnnouncementDocument = gql`
    mutation DeleteBusinessAnnouncement($id: UUID!) {
  deleteAnnouncement(id: $id) {
    id
  }
}
    `;
export type DeleteBusinessAnnouncementMutationFn = Apollo.MutationFunction<DeleteBusinessAnnouncementMutation, DeleteBusinessAnnouncementMutationVariables>;

/**
 * __useDeleteBusinessAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessAnnouncementMutation, { data, loading, error }] = useDeleteBusinessAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessAnnouncementMutation, DeleteBusinessAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessAnnouncementMutation, DeleteBusinessAnnouncementMutationVariables>(DeleteBusinessAnnouncementDocument, options);
      }
export type DeleteBusinessAnnouncementMutationHookResult = ReturnType<typeof useDeleteBusinessAnnouncementMutation>;
export type DeleteBusinessAnnouncementMutationResult = Apollo.MutationResult<DeleteBusinessAnnouncementMutation>;
export type DeleteBusinessAnnouncementMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessAnnouncementMutation, DeleteBusinessAnnouncementMutationVariables>;