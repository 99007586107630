import React, { forwardRef } from 'react';
import { InputGroup } from 'reactstrap';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { AddOnType, InputAddon, InputAddonProps } from '../InputAddon';
import { Clock } from 'react-feather';
import moment from 'moment-timezone';

export type TimeInputProps = InputWrapperProps & {
  inputAddon?: InputAddonProps;
  onChange?: (value: Date | null) => void;
} & ReactDatePickerProps;

const defaultInputAddon = {
  addonType: AddOnType.Prepend,
  value: <Clock color="#606E80" size="16" />
};

export const TimeInput = forwardRef<ReactDatePicker, TimeInputProps>(
  (
    {
      label,
      helperText,
      error,
      inputAddon = defaultInputAddon,
      selected,
      minTime,
      maxTime,
      onChange,
      ...rest
    },
    ref
  ) => {
    const userTimezone = moment.tz.guess();

    const userTimezoneOffsetMinutes = moment.tz
      .zone(userTimezone)
      ?.utcOffset(moment().valueOf());

    const estTimezoneOffsetMinutes = moment.tz
      .zone('America/New_York')
      ?.utcOffset(moment().valueOf());

    const hoursDifferenceToEST =
      ((estTimezoneOffsetMinutes ?? 0) - (userTimezoneOffsetMinutes ?? 0)) / 60;

    const adjustDateToEST = (date: Date | null) => {
      if (date) {
        onChange(moment(date).add(hoursDifferenceToEST, 'hours').toDate());
      }
    };

    const convertDateToLocalTimezone = (date?: Date | null) => {
      return date
        ? moment(date).subtract(hoursDifferenceToEST, 'hours').toDate()
        : undefined;
    };

    return (
      <InputWrapper label={label} helperText={helperText} error={error}>
        <InputGroup className="input-group-alternative">
          {inputAddon?.addonType === AddOnType.Prepend && (
            <InputAddon {...inputAddon} />
          )}
          <div className="rdt">
            <ReactDatePicker
              ref={ref}
              className="form-control"
              placeholderText="Select Time"
              timeIntervals={15}
              onChangeRaw={() => null}
              shouldCloseOnSelect
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              disabledKeyboardNavigation
              onFocus={(e) => e.target.blur()}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              selected={convertDateToLocalTimezone(selected)}
              minTime={convertDateToLocalTimezone(minTime)}
              maxTime={convertDateToLocalTimezone(maxTime)}
              onChange={adjustDateToEST}
              {...rest}
            />
          </div>
          {inputAddon?.addonType === AddOnType.Append && (
            <InputAddon {...inputAddon} />
          )}
        </InputGroup>
      </InputWrapper>
    );
  }
);

TimeInput.displayName = 'TimeInput';
