/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import moment, { MomentInput } from 'moment-timezone';
import ReactDateTime from 'react-datetime';
import Color from 'color';
import {
  CalendarProps,
  Event,
  momentLocalizer,
  SlotInfo
} from 'react-big-calendar';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Calendar, DEFAULT_HEX_COLOR, Pulse } from '../../../components';
import {
  GetBusinessCalendarQuery,
  useGetBusinessCalendarLazyQuery
} from './__generated__/graphql';
import { AppointmentModal } from './AppointmentModal';
import { socket } from '../../../utils/socket';

export type PartialAppointmentData = {
  startAt: string;
  endAt: string;
  resourceId: string;
};

export type CalendarEvent = Event &
  GetBusinessCalendarQuery['getAppointments'][0];

const generateDefaultAppointmentInfo = (): PartialAppointmentData => ({
  startAt: moment().add(1, 'hour').startOf('hour').toISOString(),
  endAt: moment().add(2, 'hour').startOf('hour').toISOString(),
  resourceId: ''
});

export const BusinessCalendar: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [resources, setResources] = useState<CalendarProps['resources']>([]);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [isOpen, setModal] = useState(false);
  const [partialAppointmentData, setPartialAppointmentData] =
    useState<PartialAppointmentData | null>(null);

  useEffect(() => {
    const input = document.querySelector('.rdt input');

    if (input) {
      input.classList.remove('form-control');
    }
  });

  useEffect(() => {
    socket.on('appointment:created', ({ startAt }) => {
      if (moment(startAt).isSame(selectedDate, 'day')) {
        getBusinessCalendar();
      }
    });

    socket.on('appointment:updated', ({ appointmentId }) => {
      if (events.some((event) => event.id === appointmentId)) {
        getBusinessCalendar();
      }
    });

    socket.on('appointment:cancelled', ({ appointmentId }) => {
      if (events.some((event) => event.id === appointmentId)) {
        getBusinessCalendar();
      }
    });

    return () => {
      socket.off('appointment:created');
      socket.off('appointment:updated');
      socket.off('appointment:cancelled');
    };
  }, [events]);

  useEffect(() => {
    getBusinessCalendar();
  }, [selectedDate]);

  const [getBusinessCalendar, { loading: getBusinessCalendarLoading }] =
    useGetBusinessCalendarLazyQuery({
      variables: {
        options: {
          dateRange: {
            startAt: selectedDate.startOf('day').toISOString(),
            endAt: selectedDate.endOf('day').toISOString()
          }
        }
      },
      onCompleted: (data) => {
        setEvents(formatEvents(data.getAppointments));
        setResources(
          data.getResources.map(({ id, name: title }) => ({ id, title }))
        );
      }
    });

  const formatEvents = (
    appointments: GetBusinessCalendarQuery['getAppointments']
  ) =>
    appointments.map((appointment) => {
      const hexColor = Color(DEFAULT_HEX_COLOR);
      let color = hexColor.darken(0.5).desaturate(0.5);
      if (appointment.status === 'COMPLETED') {
        color = Color(color).darken(0.3);
      }
      const backgroundColor = hexColor.darken(hexColor.luminosity() * 0.3);
      return {
        ...appointment,
        start: moment(appointment.startAt).toDate(),
        end: moment(appointment.endAt).toDate(),
        resourceId: appointment.resource.id,
        title: (
          <>
            <h6 style={{ color: color.toString() }} className="mb-0">
              {`${moment(appointment.startAt).format('h:mm A')} - ${moment(appointment.endAt).format('h:mm A')}`}
            </h6>
            <div
              className="my-1 badge"
              style={{ backgroundColor: backgroundColor.toString() }}
            >
              {`${appointment.user.firstName} ${appointment.user.lastName}`}
            </div>
            <h6 style={{ color: color.toString() }} className="mb-0">
              {appointment.notes && (
                <div className="mb-1 font-weight-normal">
                  <span className="font-weight-bold text-underline">Notes</span>
                  {': '}
                  {appointment.notes}
                </div>
              )}
              {appointment.internalNotes && (
                <div className="mb-1 font-weight-normal">
                  <span className="font-weight-bold text-underline">
                    Internal Notes
                  </span>
                  {': '}
                  {appointment.internalNotes}
                </div>
              )}
            </h6>
          </>
        )
      };
    });

  const handleSelectDate = (date: moment.Moment | Date) => {
    setSelectedDate(moment(date));
  };

  const handleSelect = (event: any) => {
    setAppointmentId(event.id);
    setPartialAppointmentData(null);
    setModal(true);
  };

  const handleCreate = (slot?: SlotInfo) => {
    setAppointmentId(null);
    if (slot) {
      setPartialAppointmentData({
        startAt: moment(slot.start).toISOString(),
        endAt: moment(slot.end).toISOString(),
        resourceId: slot.resourceId as string
      });
    } else {
      setPartialAppointmentData(generateDefaultAppointmentInfo());
    }
    setModal(true);
  };

  return (
    <>
      <AppointmentModal
        isOpen={isOpen}
        toggle={() => setModal(!isOpen)}
        appointmentId={appointmentId}
        initialValues={partialAppointmentData}
      />
      <Row>
        <Col>
          <Card>
            {getBusinessCalendarLoading && (
              <Pulse className="spinner-over-blur" />
            )}
            <CardHeader className="py-4">
              <Row>
                <Col md="3" xs="12" className="custom-padding">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleSelectDate(moment())}
                  >
                    Today
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      handleSelectDate(moment(selectedDate).subtract(1, 'day'))
                    }
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      handleSelectDate(moment(selectedDate).add(1, 'day'))
                    }
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </Col>
                <Col md="6" xs="12" className="d-flex justify-content-center">
                  <ReactDateTime
                    className="rdt-special"
                    timeFormat={false}
                    value={selectedDate}
                    onChange={(date: MomentInput) =>
                      setSelectedDate(moment(date))
                    }
                    inputProps={{ readOnly: true }}
                    dateFormat="dddd, MMMM Do, YYYY"
                    closeOnSelect
                    closeOnClickOutside
                  />
                </Col>
              </Row>
            </CardHeader>
            <div className={getBusinessCalendarLoading ? 'blur' : ''}>
              <CardBody>
                <Calendar
                  localizer={momentLocalizer(moment)}
                  events={events}
                  resources={resources}
                  date={selectedDate.toDate()}
                  onNavigate={handleSelectDate}
                  onSelectEvent={handleSelect}
                  onSelectSlot={handleCreate}
                  selectable
                />
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
