import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { DateInput, Pulse, SelectInput } from '../../../components';
import { generateNumberOptions } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useGetAvailableResourcesLazyQuery } from './__generated__/graphql';
import { TimeInput } from '../../../components';
import Sorry from '../../../assets/images/sorry.png';
import moment from 'moment-timezone';
import { useScheduling } from '../../../contexts';
import { useBusinessSettings } from '../../../hooks';

export const ScheduleSearch: React.FC = () => {
  const [capacity, setCapacity] = useState(1);

  const settings = useBusinessSettings();

  const navigate = useNavigate();

  const {
    isValidDate,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    minStartAt,
    minEndAt,
    maxStartAt,
    maxEndAt,
    missingOperatingHours,
    loading: businessHoursLoading
  } = useScheduling();

  const [
    getAvailableResources,
    { data, loading: getAvailableResourcesLoading }
  ] = useGetAvailableResourcesLazyQuery();

  const resources = useMemo(() => data?.getAvailableResources, [data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getAvailableResources({
        variables: {
          options: {
            dateRange: {
              startAt: startAt.toISOString(),
              endAt: endAt.toISOString()
            },
            capacity
          }
        }
      });
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, [startAt, endAt, capacity, getAvailableResources]);

  const handleDateChange = (value: string | moment.Moment) => {
    const newDate = moment(value);

    if (newDate.isValid()) {
      setStartAt(newDate);
    }
  };

  const handleSelectCapacity = (capacity: number) => setCapacity(capacity);

  const handleSelectResource = (resourceId: string) =>
    navigate(`/schedule/book/${resourceId}`, {
      state: {
        dateRange: {
          startAt: startAt.toISOString(),
          endAt: endAt.toISOString()
        }
      }
    });

  if (businessHoursLoading || !settings) {
    return <Pulse />;
  }

  const isDisabled =
    missingOperatingHours || !settings.appointmentBookingEnabled;

  return (
    <>
      {!isDisabled && (
        <Row>
          <Col>
            <Card>
              <CardBody className="pb-0">
                <Row>
                  <Col lg="4">
                    <DateInput
                      label="Date"
                      value={startAt}
                      onChange={handleDateChange}
                      isValidDate={isValidDate}
                    />
                  </Col>
                  <Col lg="3" sm="4" xs="6">
                    <TimeInput
                      label="Start Time"
                      selected={startAt.toDate()}
                      onChange={(value) => value && setStartAt(moment(value))}
                      minTime={minStartAt.toDate()}
                      maxTime={maxStartAt.toDate()}
                    />
                  </Col>
                  <Col lg="3" sm="4" xs="6">
                    <TimeInput
                      label="End Time"
                      selected={endAt.toDate()}
                      onChange={(value) => value && setEndAt(moment(value))}
                      minTime={minEndAt.toDate()}
                      maxTime={maxEndAt.toDate()}
                    />
                  </Col>
                  <Col lg="2" sm="4" xs="12">
                    <SelectInput
                      label="Capacity"
                      placeholder="Select Capacity"
                      options={generateNumberOptions(8)}
                      value={capacity}
                      onChange={handleSelectCapacity}
                      isSearchable={false}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        {!resources || getAvailableResourcesLoading ? (
          <Pulse />
        ) : (
          <>
            {!isDisabled &&
              resources.map((resource) => (
                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  key={resource.id}
                  onClick={() => handleSelectResource(resource.id)}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <Card>
                    <CardBody className="p-0">
                      {resource.images.map((image) => (
                        <img
                          src={image.url}
                          alt={resource.name}
                          key={image.id}
                          className="img-fluid rounded-top"
                        />
                      ))}
                    </CardBody>
                    <CardFooter>
                      <h3 className="font-weight-bold mb-0">{resource.name}</h3>
                      <small className="text-muted text-uppercase font-weight-normal">
                        {`${resource.type} — ${resource.capacity} Seats`}
                      </small>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            {isDisabled && (
              <>
                <Col xs="12" className="text-center">
                  <img
                    src={Sorry}
                    alt="Sorry"
                    style={{
                      objectFit: 'contain',
                      maxHeight: '100%',
                      maxWidth: '100%'
                    }}
                    height={300}
                  />
                </Col>
                <Col className="text-center text-muted">
                  <h2>Appointment Booking Unavailable</h2>
                  <p>
                    Appointment booking is temporarily unavailable. Please try
                    again later or contact us for further assistance.
                  </p>
                </Col>
              </>
            )}
            {!resources.length && (
              <>
                <Col xs="12" className="text-center">
                  <img
                    src={Sorry}
                    alt="Sorry"
                    style={{
                      objectFit: 'contain',
                      maxHeight: '100%',
                      maxWidth: '100%'
                    }}
                    height={300}
                  />
                </Col>
                <Col className="text-center text-muted">
                  <h2>No Matches Found</h2>
                  <p>
                    Try adjusting your search by changing your date, times, or
                    capacity.
                  </p>
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    </>
  );
};
