import { pick } from 'lodash';
import {
  CreateResourceInput,
  UpdateResourceInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useActivateBusinessResourceMutation,
  useCreateBusinessResourceMutation,
  useDeactivateBusinessResourceMutation,
  useGetBusinessResourceLazyQuery,
  useUpdateBusinessResourceMutation
} from './__generated__/graphql';
import { useMemo } from 'react';
import { Resource } from './ResourceModal';

type UseResourceActionsOptions = {
  toggle: () => void;
  toggleResourceActivationModal: () => void;
};

const baseResourceKeys: Array<
  keyof CreateResourceInput & keyof UpdateResourceInput
> = ['name', 'description', 'capacity', 'type'];

export const useResourceActions = ({
  toggle,
  toggleResourceActivationModal
}: UseResourceActionsOptions) => {
  const [getResource, { data: getResourceData, loading: getResourceLoading }] =
    useGetBusinessResourceLazyQuery();

  const resource = useMemo(
    () => getResourceData?.getResource,
    [getResourceData]
  );

  const [createResource, { loading: createResourceLoading }] =
    useCreateBusinessResourceMutation({
      refetchQueries: [namedOperations.Query.GetBusinessResources],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Resource successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating resource!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateResource, { loading: updateResourceLoading }] =
    useUpdateBusinessResourceMutation({
      refetchQueries: [namedOperations.Query.GetBusinessResources],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Resource successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating resource!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [activateResource, { loading: activateResourceLoading }] =
    useActivateBusinessResourceMutation({
      refetchQueries: [namedOperations.Query.GetBusinessResources],
      onCompleted: () => {
        toggleResourceActivationModal();
        toggle();
        notify({
          message: 'Resource successfully activated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error activating resource!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [deactivateResource, { loading: deactivateResourceLoading }] =
    useDeactivateBusinessResourceMutation({
      refetchQueries: [namedOperations.Query.GetBusinessResources],
      onCompleted: () => {
        toggleResourceActivationModal();
        toggle();
        notify({
          message: 'Resource successfully deactivated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deactivating resource!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateResource = async (data: Resource) => {
    await createResource({
      variables: { data: pick(data, baseResourceKeys) }
    });
  };

  const handleUpdateResource = async (data: Resource) => {
    if (resource) {
      await updateResource({
        variables: { id: resource.id, data: pick(data, baseResourceKeys) }
      });
    }
  };

  const handleToggleResource = async () => {
    if (resource) {
      const options = {
        variables: {
          id: resource.id
        }
      };

      if (resource.deactivatedAt) {
        await activateResource(options);
      } else {
        await deactivateResource(options);
      }
    }
  };

  return {
    resource: getResourceData?.getResource,
    getResource,
    getResourceLoading,
    handleCreateResource,
    createResourceLoading,
    handleUpdateResource,
    updateResourceLoading,
    handleToggleResource,
    toggleResourceLoading: activateResourceLoading || deactivateResourceLoading
  };
};
