import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import { DateInput, TextArea } from '../..';
import { CompanyAlert } from './CompanyAlertModal';
import moment from 'moment';

type CompanyAlertFormProps = {
  form: UseFormReturn<CompanyAlert>;
};

export const CompanyAlertForm: React.FC<CompanyAlertFormProps> = ({
  form: {
    register,
    control,
    watch,
    formState: { errors }
  }
}) => {
  const [startAt] = watch(['startAt']);

  return (
    <ModalBody>
      <Row>
        <Col xs="12">
          <Controller
            name="startAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Start Date"
                onChange={(date) =>
                  onChange(moment(date).startOf('day').toISOString())
                }
                value={value ?? undefined}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Controller
            name="expiresAt"
            control={control}
            rules={{
              validate: {
                afterStartDate: (value) => {
                  if (startAt && value) {
                    if (moment(value).isSameOrBefore(startAt)) {
                      return 'Expiration date must be greater than the start date';
                    }
                  }
                }
              }
            }}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Expiration Date"
                error={errors.expiresAt?.message}
                onChange={(date) =>
                  onChange(moment(date).startOf('day').toISOString())
                }
                value={value ?? undefined}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label="Note"
            error={errors.note?.message}
            placeholder="Enter a note here..."
            rows={4}
            {...register('note', {
              required: 'Note is required'
            })}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
