import React, { useEffect, useMemo } from 'react';
import v from 'voca';
import { toast } from 'react-toastify';
import {
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import { Calendar, Clock, Info, MapPin, User, XCircle } from 'react-feather';
import moment from 'moment';
import {
  UpdateAppointmentInput,
  namedOperations
} from '../../__generated__/graphql';
import {
  DropdownMenuItem,
  Pulse,
  TextArea,
  DropdownMenu,
  Button,
  ConfirmationModal,
  ModalType,
  TimeInput
} from '../../components';
import {
  useCancelUserAppointmentMutation,
  useGetUserAppointmentLazyQuery,
  useUpdateUserAppointmentMutation
} from './__generated__/graphql';
import { useModal } from '../../hooks';
import { Controller, useForm } from 'react-hook-form';
import { pick } from 'lodash';

interface UserAppointmentModalProps {
  isOpen: boolean;
  appointmentId: string | null;
  toggle: () => void;
}

type Appointment = {
  startAt: string;
  endAt: string;
  notes: string | null;
};

const baseAppointmentKeys: Array<keyof UpdateAppointmentInput> = [
  'notes',
  'startAt',
  'endAt'
];

export const UserAppointmentModal: React.FC<UserAppointmentModalProps> = ({
  isOpen,
  toggle,
  appointmentId
}) => {
  const {
    isOpen: isCancelUserAppointmentModalOpen,
    toggleModal: toggleCancelUserAppointmentModal
  } = useModal();

  const {
    isOpen: isAppointmentErrorModalOpen,
    toggleModal: toggleAppointmentErrorModal
  } = useModal();

  const form = useForm<Appointment>();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    register,
    handleSubmit
  } = form;

  const [startAt, endAt] = watch(['startAt', 'endAt']);

  useEffect(() => {
    const start = moment(startAt);

    const newEndAt = moment(endAt)
      .year(start.year())
      .month(start.month())
      .date(start.date());

    setValue('endAt', newEndAt.toISOString());
  }, [startAt]);

  const [getUserAppointment, { data, loading: getUserAppointmentLoading }] =
    useGetUserAppointmentLazyQuery();

  const [updateAppointment, { loading: updateAppointmentLoading }] =
    useUpdateUserAppointmentMutation({
      refetchQueries: [namedOperations.Query.GetUserCalendar],
      onCompleted: () => {
        toast.success('Appointment successfully updated!', {
          className: 'bg-primary'
        });
        toggle();
      },
      onError: () => toggleAppointmentErrorModal()
    });

  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useCancelUserAppointmentMutation({
      refetchQueries: [namedOperations.Query.GetUserCalendar],
      onCompleted: () => {
        toast.success('Appointment successfully cancelled!', {
          className: 'bg-primary'
        });
        toggleCancelUserAppointmentModal();
        toggle();
      }
    });

  useEffect(() => {
    if (isOpen && appointmentId) {
      getUserAppointment({ variables: { id: appointmentId } });
    }
  }, [isOpen, appointmentId]);

  useEffect(() => {
    if (data?.getAppointment) {
      form.reset(pick(data.getAppointment, baseAppointmentKeys));
    }
  }, [data]);

  const appointment = useMemo(() => data?.getAppointment, [data]);

  const handleUpdateAppointment = (data: Appointment) => {
    if (appointmentId) {
      updateAppointment({
        variables: {
          id: appointmentId,
          data
        }
      });
    }
  };

  const handleCancelAppointment = async () => {
    if (appointmentId) {
      await cancelAppointment({
        variables: {
          id: appointmentId
        }
      });
    }
  };

  const actions: DropdownMenuItem[] = [
    {
      icon: <XCircle size={16} />,
      label: 'Cancel Appointment',
      onClick: toggleCancelUserAppointmentModal
    }
  ];

  const loading = !appointment || getUserAppointmentLoading;

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isCancelUserAppointmentModalOpen}
        toggle={toggleCancelUserAppointmentModal}
        title="Cancel Appointment"
        body={
          <p>
            Are you sure you want to cancel this appointment? This action can
            not be reversed.
          </p>
        }
        loading={cancelAppointmentLoading}
        confirmationText="Yes, cancel"
        cancelText="No, back"
        onConfirm={handleCancelAppointment}
      />
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isAppointmentErrorModalOpen}
        toggle={toggleAppointmentErrorModal}
        title="Appointment Error"
        body={
          <p>
            We are unable to accommodate this request. This room is unavailable
            for the requested time. Please try another time or contact us for
            further assistance.
          </p>
        }
        cancelText="Close"
      />
      <Modal
        toggle={!updateAppointmentLoading ? toggle : () => null}
        isOpen={isOpen}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="editAppointment">
            Appointment Details
          </h5>
          {!loading && <DropdownMenu items={actions} />}
        </div>
        {loading ? (
          <Pulse />
        ) : (
          <>
            <ModalBody>
              <Row>
                <Col xs="12">
                  <ListGroup className="list my--3" flush>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="avatar rounded-circle bg-primary">
                            <User />
                          </div>
                        </Col>
                        <div className="col ml--2 mb-0">
                          <h4 className="">User</h4>
                          <h5 className="text-sm mb-0 text-muted font-weight-normal">
                            {`
                      ${appointment.user.firstName} ${appointment.user.lastName}
                    `}
                          </h5>
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="avatar rounded-circle bg-primary">
                            <MapPin />
                          </div>
                        </Col>
                        <div className="col ml--2 mb-0">
                          <h4 className="">Room</h4>
                          <h5 className="text-sm mb-0 text-muted font-weight-normal">
                            {`
                      ${appointment.resource.name}
                      –
                      ${v.titleCase(appointment.resource.type)}
                      –
                      ${appointment.resource.capacity} Seats
                    `}
                          </h5>
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="avatar rounded-circle bg-primary">
                            <Calendar />
                          </div>
                        </Col>
                        <div className="col ml--2 mb-0">
                          <h4 className="">Date</h4>
                          <h5 className="text-sm mb-0 text-muted font-weight-normal">
                            {moment(appointment.startAt).format(
                              'dddd, MMMM Do YYYY'
                            )}
                          </h5>
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="avatar rounded-circle bg-primary">
                            <Clock />
                          </div>
                        </Col>
                        <div className="col ml--2 mb-0">
                          <Row>
                            <Col className="mb--4">
                              <Controller
                                name="startAt"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TimeInput
                                    label="Start Time"
                                    selected={moment(value).toDate()}
                                    onChange={(value) =>
                                      value &&
                                      onChange(moment(value).toISOString())
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col className="mb--4">
                              <Controller
                                name="endAt"
                                control={control}
                                rules={{
                                  validate: {
                                    afterStartDate: (value) =>
                                      moment(value).isAfter(startAt) ||
                                      'End time must be greater than the start time'
                                  }
                                }}
                                render={({ field: { value, onChange } }) => (
                                  <TimeInput
                                    label="End Time"
                                    error={errors.endAt?.message}
                                    selected={moment(value).toDate()}
                                    onChange={(value) =>
                                      value &&
                                      onChange(moment(value).toISOString())
                                    }
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="avatar rounded-circle bg-primary">
                            <Info />
                          </div>
                        </Col>
                        <div className="col ml--2 mb-0">
                          <TextArea
                            label="Notes"
                            placeholder="Enter important details about your appointment here..."
                            rows={2}
                            {...register('notes')}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button disabled={updateAppointmentLoading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={updateAppointmentLoading}
                onClick={handleSubmit(handleUpdateAppointment)}
              >
                Save Changes
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
