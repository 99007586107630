import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import {
  AddOnType,
  DateInput,
  SelectInput,
  TextArea,
  TextInput
} from '../../../components';
import { Globe, Mail } from 'react-feather';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Company } from './CompanyModal';
import { PhoneNumberInput } from '../../../components';
import { useMembershipOptions } from '../../../hooks';
import moment from 'moment';
import { phoneTierOptions } from '../../../constants';

export const GeneralInformation: React.FC = () => {
  const membershipOptions = useMembershipOptions();

  const {
    register,
    control,
    setValue,
    formState: { errors }
  } = useFormContext<Company>();

  return (
    <>
      <Row>
        <Col xs="6">
          <TextInput
            label="Name"
            placeholder="Name"
            error={errors.name?.message}
            {...register('name', {
              required: 'Name is required'
            })}
          />
        </Col>
        <Col xs="6">
          <TextInput
            label="Email"
            inputAddon={{
              addonType: AddOnType.Prepend,
              value: <Mail color="#606E80" size="16" />
            }}
            placeholder="hello@officedivvy.com"
            error={errors.email?.message}
            {...register('email')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              validate: (value) => {
                if (!value) {
                  return true;
                }

                return (
                  isValidPhoneNumber(value, 'US') || 'Phone number is invalid'
                );
              }
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneNumberInput
                error={errors.phoneNumber?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col xs="6">
          <Controller
            control={control}
            name="faxNumber"
            rules={{
              validate: (value) => {
                if (!value) {
                  return true;
                }

                return (
                  isValidPhoneNumber(value, 'US') || 'Fax number is invalid'
                );
              }
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneNumberInput
                label="Fax Number"
                error={errors.faxNumber?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Controller
            control={control}
            name="tollNumber"
            rules={{
              validate: (value) => {
                if (!value) {
                  return true;
                }

                return (
                  isValidPhoneNumber(value, 'US') || 'Toll number is invalid'
                );
              }
            }}
            render={({ field: { onChange, value } }) => (
              <PhoneNumberInput
                label="Fax Number"
                error={errors.tollNumber?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col xs="6">
          <TextInput
            label="Website"
            error={errors.websiteUrl?.message}
            inputAddon={{
              addonType: AddOnType.Prepend,
              value: <Globe color="#606E80" size="16" />
            }}
            placeholder="https://officedivvy.com"
            {...register('websiteUrl')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Controller
            name="memberSince"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Member Since"
                error={errors.memberSince?.message}
                dateFormat="MMMM Do, YYYY"
                onChange={(value) =>
                  onChange(moment(value).format('YYYY-MM-DD'))
                }
                value={value ?? undefined}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Controller
            control={control}
            name="membershipId"
            rules={{
              required: 'Membership is required'
            }}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="Membership"
                error={errors.membershipId?.message}
                placeholder="Select Membership"
                options={membershipOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col xs="6">
          <Controller
            control={control}
            name="phoneTier"
            render={({ field: { value, onChange } }) => (
              <SelectInput
                label="Phone Tier"
                error={errors.phoneTier?.message}
                placeholder="Select Phone Tier"
                options={phoneTierOptions}
                value={value}
                onChange={onChange}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' || event.key === 'Delete') {
                    setValue('phoneTier', null);
                  }
                }}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label="Notes"
            placeholder="Enter important information here..."
            error={errors.notes?.message}
            {...register('notes')}
          />
        </Col>
      </Row>
    </>
  );
};
