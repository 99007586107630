import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import { DateInput, TextArea, TextInput } from '../../../components';
import { Announcement } from './AnnouncementModal';
import { isAfter, parseISO } from 'date-fns';
import moment from 'moment-timezone';

type AnnouncementFormProps = {
  form: UseFormReturn<Announcement>;
};

export const AnnouncementForm: React.FC<AnnouncementFormProps> = ({
  form: {
    register,
    control,
    watch,
    formState: { errors }
  }
}) => {
  const startDate = watch('startDate');

  const handleDateChange = (date: moment.Moment | string) => {
    return moment(date).format('YYYY-MM-DD');
  };

  return (
    <ModalBody>
      <Row>
        <Col xs="12">
          <TextInput
            label="Title"
            error={errors.title?.message}
            placeholder="Title"
            {...register('title', {
              required: 'Title is required'
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label="Body"
            error={errors.body?.message}
            placeholder="Enter a short description here..."
            rows={3}
            {...register('body', {
              required: 'Body is required'
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Start Date"
                error={errors.startDate?.message}
                value={value}
                onChange={(value) => onChange(handleDateChange(value))}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Controller
            name="endDate"
            control={control}
            rules={{
              validate: {
                afterStartDate: (value) =>
                  isAfter(parseISO(value), parseISO(startDate)) ||
                  'End date must be greater than the start date'
              }
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <DateInput
                  label="End Date"
                  error={errors.endDate?.message}
                  value={value}
                  onChange={(value) => onChange(handleDateChange(value))}
                />
              );
            }}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
