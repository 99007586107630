import { pick } from 'lodash';
import {
  CreateCompanyInput,
  UpdateCompanyInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useActivateBusinessCompanyMutation,
  useCreateBusinessCompanyMutation,
  useDeactivateBusinessCompanyMutation,
  useGetBusinessCompanyLazyQuery,
  useUpdateBusinessCompanyMutation
} from './__generated__/graphql';
import { useMemo } from 'react';
import { Company } from './CompanyModal';

type UseCompanyActionsOptions = {
  toggle: () => void;
  toggleCompanyActivationModal: () => void;
};

const baseCompanyKeys: Array<
  keyof CreateCompanyInput & keyof UpdateCompanyInput
> = [
  'name',
  'email',
  'phoneNumber',
  'faxNumber',
  'tollNumber',
  'websiteUrl',
  'notes',
  'intake',
  'training',
  'memberSince',
  'phoneTier',
  'membershipId'
];

export const useCompanyActions = ({
  toggle,
  toggleCompanyActivationModal
}: UseCompanyActionsOptions) => {
  const [getCompany, { data: getCompanyData, loading: getCompanyLoading }] =
    useGetBusinessCompanyLazyQuery();

  const company = useMemo(() => getCompanyData?.getCompany, [getCompanyData]);

  const [createCompany, { loading: createCompanyLoading }] =
    useCreateBusinessCompanyMutation({
      refetchQueries: [namedOperations.Query.GetBusinessCompanies],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Company successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating company!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateCompany, { loading: updateCompanyLoading }] =
    useUpdateBusinessCompanyMutation({
      refetchQueries: [namedOperations.Query.GetBusinessCompanies],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Company successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating company!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [activateCompany, { loading: activateCompanyLoading }] =
    useActivateBusinessCompanyMutation({
      refetchQueries: [namedOperations.Query.GetBusinessCompanies],
      onCompleted: () => {
        toggleCompanyActivationModal();
        toggle();
        notify({
          message: 'Company successfully activated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error activating company!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [deactivateCompany, { loading: deactivateCompanyLoading }] =
    useDeactivateBusinessCompanyMutation({
      refetchQueries: [namedOperations.Query.GetBusinessCompanies],
      onCompleted: () => {
        toggleCompanyActivationModal();
        toggle();
        notify({
          message: 'Company successfully deactivated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deactivating company!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateCompany = async (data: Company) => {
    await createCompany({
      variables: { data: pick(data, baseCompanyKeys) }
    });
  };

  const handleUpdateCompany = async (data: Company) => {
    if (company) {
      await updateCompany({
        variables: { id: company.id, data: pick(data, baseCompanyKeys) }
      });
    }
  };

  const handleToggleCompany = async () => {
    if (company) {
      const options = {
        variables: {
          id: company.id
        }
      };

      if (company.deactivatedAt) {
        await activateCompany(options);
      } else {
        await deactivateCompany(options);
      }
    }
  };

  return {
    company: getCompanyData?.getCompany,
    getCompany,
    getCompanyLoading,
    handleCreateCompany,
    createCompanyLoading,
    handleUpdateCompany,
    updateCompanyLoading,
    handleToggleCompany,
    toggleCompanyLoading: activateCompanyLoading || deactivateCompanyLoading
  };
};
