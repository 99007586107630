import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { Power } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse
} from '../..';
import { CompanyAlertForm } from './CompanyAlertForm';
import { useCompanyAlertActions } from './useCompanyAlertActions';
import { useModal } from '../../../hooks';

type CompanyAlertModalProps = {
  isOpen: boolean;
  companyAlertId: string | null;
  companyId: string;
  toggle: () => void;
};

export type CompanyAlert = {
  note: string;
  startAt: string | null;
  expiresAt: string | null;
  companyId: string;
};

enum CompanyAlertMode {
  Create = 'Create',
  Update = 'Update'
}

export const CompanyAlertModal: React.FC<CompanyAlertModalProps> = (props) => {
  const { isOpen, toggle, companyAlertId, companyId } = props;

  const [mode, setMode] = useState<CompanyAlertMode>(CompanyAlertMode.Create);
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isDeleteCompanyAlertModalOpen,
    toggleModal: toggleDeleteCompanyAlertModal
  } = useModal();

  const {
    companyAlert,
    getCompanyAlert,
    getCompanyAlertLoading,
    handleCreateCompanyAlert,
    createCompanyAlertLoading,
    handleUpdateCompanyAlert,
    updateCompanyAlertLoading,
    handleDeleteCompanyAlert,
    deleteCompanyAlertLoading
  } = useCompanyAlertActions({ toggle, toggleDeleteCompanyAlertModal });

  const defaultCompanyAlert: CompanyAlert = {
    note: '',
    startAt: null,
    expiresAt: null,
    companyId
  };

  const form = useForm<CompanyAlert>({
    defaultValues: defaultCompanyAlert
  });

  useEffect(() => {
    form.reset(companyAlert);
  }, [companyAlert]);

  useEffect(() => {
    setLoading(createCompanyAlertLoading || updateCompanyAlertLoading);
  }, [createCompanyAlertLoading, updateCompanyAlertLoading]);

  useEffect(() => {
    if (isOpen) {
      if (companyAlertId) {
        setMode(CompanyAlertMode.Update);
        getCompanyAlert({
          variables: {
            id: companyAlertId
          }
        });
      } else {
        setMode(CompanyAlertMode.Create);
        form.reset(defaultCompanyAlert);
      }
    }
  }, [companyAlertId, isOpen]);

  const handleSubmit = (data: CompanyAlert) => {
    if (mode === CompanyAlertMode.Update) {
      handleUpdateCompanyAlert(data);
    } else {
      handleCreateCompanyAlert(data);
    }
  };

  const menuItems = [
    {
      icon: <Power size={14} />,
      label: 'Delete',
      onClick: toggleDeleteCompanyAlertModal
    }
  ];

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isDeleteCompanyAlertModalOpen}
        toggle={toggleDeleteCompanyAlertModal}
        title="Delete Company Alert"
        body={
          <p>
            Are you sure you want to delete this company alert? This action can
            not be reversed.
          </p>
        }
        loading={deleteCompanyAlertLoading}
        confirmationText="Yes, delete"
        onConfirm={handleDeleteCompanyAlert}
      />
      <Modal toggle={!loading ? toggle : () => null} isOpen={isOpen} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="editCompanyAlert">
            {`${mode} Company Alert`}
          </h5>
          {companyAlert && mode === CompanyAlertMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getCompanyAlertLoading ? (
          <Pulse />
        ) : (
          <>
            <CompanyAlertForm form={form} />
            <ModalFooter>
              <Button disabled={loading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={loading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {mode}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
