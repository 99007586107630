import { pick } from 'lodash';
import {
  CreateBlockoutInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import { useCreateBusinessBlockoutMutation } from './__generated__/graphql';
import { Blockout } from './BlockoutModal';

type UseBlockoutActionsOptions = {
  toggle: () => void;
};

const baseBlockoutKeys: Array<keyof CreateBlockoutInput> = [
  'date',
  'description'
];

export const useBlockoutActions = ({ toggle }: UseBlockoutActionsOptions) => {
  const [createBlockout, { loading: createBlockoutLoading }] =
    useCreateBusinessBlockoutMutation({
      refetchQueries: [namedOperations.Query.GetBusinessBlockouts],
      onCompleted: () => {
        toggle();
        notify({
          message: 'Blockout successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating blockout!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreateBlockout = async (data: Blockout) => {
    await createBlockout({
      variables: { data: pick(data, baseBlockoutKeys) }
    });
  };

  return {
    handleCreateBlockout,
    createBlockoutLoading
  };
};
