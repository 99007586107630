import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import { SelectInput } from '../..';
import { CompanyNotificationPreference } from './CompanyNotificationPreferenceModal';
import { UserWithNotificationPreference } from './CompanyNotificationPreferencesTable';
import { logTypeOptions } from '../../../constants';

type CompanyNotificationPreferenceFormProps = {
  form: UseFormReturn<CompanyNotificationPreference>;
  user: UserWithNotificationPreference;
};

export const CompanyNotificationPreferenceForm: React.FC<
  CompanyNotificationPreferenceFormProps
> = ({ user, form: { control } }) => {
  return (
    <ModalBody>
      <Row>
        <Col xs="12">
          <div className="form-control-label">User</div>
          <p>
            {user.firstName} {user.lastName} ({user.email})
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Controller
            name="logTypes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                isMulti
                label="Log Types"
                placeholder="Select Types"
                options={logTypeOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
