import React from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { useGetDashboardBlockoutsQuery } from './__generated__/graphql';
import { format, parseISO } from 'date-fns';
import { Info } from 'react-feather';

export const BlockoutsCard: React.FC = () => {
  const { data } = useGetDashboardBlockoutsQuery();
  const blockouts = data?.getBlockouts;

  if (!blockouts?.length) {
    return null;
  }

  return (
    <>
      <UncontrolledTooltip target="blockouts-tooltip" placement="top">
        The office will be closed on the following dates
      </UncontrolledTooltip>
      <div className="d-flex align-items-center">
        <h2>Blockouts</h2>
        <Info
          color="#172b4d"
          size={20}
          className="mb-2 ml-1"
          id="blockouts-tooltip"
        />
      </div>
      <Card>
        <CardBody>
          {blockouts.slice(0, 3).map((blockout, index) => (
            <div key={blockout.id}>
              <div className="d-flex align-items-center">
                <div className="d-block text-center">
                  <h4
                    className="text-sm mb-0 font-weight-normal"
                    style={{ minWidth: '25px' }}
                  >
                    {format(parseISO(blockout.date), 'MMM')}
                  </h4>
                  <h2 className="mb-0">
                    {format(parseISO(blockout.date), 'd')}
                  </h2>
                </div>
                <div className="ml-4">
                  <p className="text-small mb-0">{blockout.description}</p>
                </div>
              </div>
              {index < blockouts.slice(0, 3).length - 1 && <hr />}
            </div>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default BlockoutsCard;
