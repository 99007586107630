import React, { useEffect, useMemo } from 'react';
import v from 'voca';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap';
import moment from 'moment-timezone';
import { Calendar, Info, MapPin } from 'react-feather';
import { Pulse } from '../../../components';
import { useGetAppointmentConfirmationLazyQuery } from './__generated__/graphql';
import { AppointmentStatus } from '../../../__generated__/graphql';

export const ScheduleConfirmation: React.FC = () => {
  const { id: appointmentId } = useParams();

  const [getAppointmentConfirmation, { data, loading }] =
    useGetAppointmentConfirmationLazyQuery();

  const appointment = useMemo(() => data?.getAppointment, [data]);

  useEffect(() => {
    if (appointmentId) {
      getAppointmentConfirmation({
        variables: {
          id: appointmentId
        }
      });
    }
  }, [appointmentId]);

  if (loading || !appointment) {
    return <Pulse />;
  }

  const confirmationCode = `#${appointment.id.slice(0, 8).toUpperCase()}`;

  return (
    <Row>
      <Col sm="12">
        {[AppointmentStatus.Cancelled, AppointmentStatus.Completed].includes(
          appointment.status
        ) && (
          <Alert color="primary" className="text-white">
            <Row className="align-items-center">
              <Col className="col-auto">
                <Info />
              </Col>
              <div className="col">
                This appointment has been {v.lowerCase(appointment.status)}. You
                are no longer able to make changes or cancel this appointment.
              </div>
            </Row>
          </Alert>
        )}
      </Col>
      <Col>
        <Card className="rounded">
          <CardHeader>
            <Row>
              <Col>
                <CardTitle className="h3 mb-0">Appointment Details</CardTitle>
              </Col>
              <Col className="text-right">
                <CardTitle className="text-muted mb-0">
                  {confirmationCode}
                </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <ListGroup className="list my--3" flush>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <div className="avatar rounded-circle bg-primary">
                      <MapPin />
                    </div>
                  </Col>
                  <div className="col ml--2 mb-0">
                    <h4 className="">Room</h4>
                    <h5 className="text-sm mb-0 text-muted font-weight-normal">
                      {`
                        ${appointment.resource.name}
                        –
                        ${v.titleCase(appointment.resource.type)}
                        –
                        ${appointment.resource.capacity} Seats
                      `}
                    </h5>
                  </div>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <div className="avatar rounded-circle bg-primary">
                      <Calendar />
                    </div>
                  </Col>
                  <div className="col ml--2 mb-0">
                    <h4 className="">Date</h4>
                    <h5 className="text-sm mb-0 text-muted font-weight-normal">
                      {`
                        ${moment(appointment.startAt).format('dddd, MMMM Do YYYY')},
                        ${moment(appointment.startAt).format('h:mm A')}
                        –
                        ${moment(appointment.endAt).format('h:mm A')}
                      `}
                    </h5>
                  </div>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <div className="avatar rounded-circle bg-primary">
                      <Info />
                    </div>
                  </Col>
                  <div className="col ml--2 mb-0">
                    <h4 className="">Notes</h4>
                    <h5 className="text-sm mb-0 text-muted font-weight-normal">
                      {appointment.notes || '–'}
                    </h5>
                  </div>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
