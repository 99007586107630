import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import {
  GetCompanyLocationsQuery,
  useGetCompanyLocationsQuery
} from './__generated__/graphql';
import { useModal } from '../../../hooks';
import { CompanyLocationModal } from './CompanyLocationsModal';
import { CheckCircle, PlusCircle } from 'react-feather';
import { Pulse } from '../../core';
import { pick } from 'lodash';

type CompanyLocationsTableProps = {
  companyId: string;
};

type CompanyLocation = GetCompanyLocationsQuery['getCompanyLocations'][0];

export const CompanyLocationsTable: React.FC<CompanyLocationsTableProps> = ({
  companyId
}) => {
  const [companyLocationId, setCompanyLocationId] = useState<string | null>(
    null
  );
  const [companyLocations, setCompanyLocations] = useState<CompanyLocation[]>(
    []
  );

  const {
    isOpen: isCompanyLocationModalOpen,
    toggleModal: toggleCompanyLocationModal
  } = useModal();

  const { loading: companyLocationsLoading } = useGetCompanyLocationsQuery({
    onCompleted: (data) => setCompanyLocations(data.getCompanyLocations),
    variables: {
      companyId
    }
  });

  const handleSelect = (id: string) => {
    setCompanyLocationId(id);
    toggleCompanyLocationModal();
  };

  const handleCreate = () => {
    setCompanyLocationId(null);
    toggleCompanyLocationModal();
  };

  if (companyLocationsLoading) {
    return <Pulse />;
  }

  return (
    <>
      <CompanyLocationModal
        isOpen={isCompanyLocationModalOpen}
        toggle={toggleCompanyLocationModal}
        companyLocationId={companyLocationId}
        companyId={companyId}
      />
      <Row>
        <Col xs="12">
          <Button
            color="primary"
            size="sm"
            className="mb-4"
            onClick={handleCreate}
          >
            New Location <PlusCircle size={16} color="white" />
          </Button>
          {companyLocations.length === 0 && (
            <p className="mb-0">No locations found for this company.</p>
          )}
          {companyLocations.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Primary</th>
                </tr>
              </thead>
              <tbody>
                {companyLocations.map((companyLocation) => (
                  <tr
                    key={companyLocation.id}
                    onClick={() => handleSelect(companyLocation.id)}
                  >
                    <td className="wrap-cell">
                      {Object.values(
                        pick(companyLocation, [
                          'addressLine1',
                          'addressLine2',
                          'addressCity',
                          'addressState',
                          'addressZip'
                        ])
                      )
                        .filter((l) => l)
                        .join(', ')}
                    </td>
                    <td>
                      {companyLocation.isPrimary && <CheckCircle size={18} />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
