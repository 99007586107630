import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useGetBusinessHoursQuery } from './__generated__/graphql';
import moment from 'moment-timezone';
import { useBusinessSettings } from '../../hooks';

moment.tz.setDefault('America/New_York');

const buffer = 15;

const defaultBusinessHours = [
  { openTime: '00:00:00', closeTime: '00:00:00' },
  { openTime: '00:00:00', closeTime: '00:00:00' },
  { openTime: '00:00:00', closeTime: '00:00:00' },
  { openTime: '00:00:00', closeTime: '00:00:00' },
  { openTime: '00:00:00', closeTime: '00:00:00' },
  { openTime: '00:00:00', closeTime: '00:00:00' },
  { openTime: '00:00:00', closeTime: '00:00:00' }
];

type SchedulingsProviderProps = {
  children: React.ReactNode;
};

export type SchedulingContext = {
  startAt: moment.Moment;
  endAt: moment.Moment;
  minStartAt: moment.Moment;
  minEndAt: moment.Moment;
  maxStartAt: moment.Moment;
  maxEndAt: moment.Moment;
  isValidDate: (date: moment.Moment) => boolean;
  setStartAt: (date: moment.Moment) => void;
  setEndAt: (date: moment.Moment) => void;
  missingOperatingHours: boolean;
  loading: boolean;
};

const initialContext: SchedulingContext = {
  startAt: moment(),
  endAt: moment(),
  minStartAt: moment(),
  minEndAt: moment(),
  maxStartAt: moment(),
  maxEndAt: moment(),
  isValidDate: () => true,
  setStartAt: () => null,
  setEndAt: () => null,
  missingOperatingHours: false,
  loading: false
};

const SchedulingContext = createContext<SchedulingContext>(initialContext);

export const SchedulingProvider: React.FC<SchedulingsProviderProps> = ({
  children
}) => {
  const [startAt, setStartAt] = useState<moment.Moment>(moment());
  const [endAt, setEndAt] = useState<moment.Moment>(moment());
  const [minStartAt, setMinStartAt] = useState<moment.Moment>(moment());
  const [minEndAt, setMinEndAt] = useState<moment.Moment>(moment());
  const [maxStartAt, setMaxStartAt] = useState<moment.Moment>(moment());
  const [maxEndAt, setMaxEndAt] = useState<moment.Moment>(moment());

  const { data, loading: blockoutsLoading } = useGetBusinessHoursQuery();

  const blockouts = useMemo(
    () => data?.getBusiness.blockouts || [],
    [data?.getBusiness.blockouts]
  );

  const businessHours = useMemo(
    () => data?.getBusiness.businessHours || defaultBusinessHours,
    [data?.getBusiness.businessHours]
  );

  const settings = useBusinessSettings();

  /**
   * This function calculates the current day of the week, adjusting for isoWeekday
   * which returns 1 for Monday and 7 for Sunday, by subtracting 1 to
   * align with the 0-indexed businessHours array.
   */
  const getDayIndex = (date = moment()) => date.isoWeekday() - 1;

  /**
   * Initializes the start and end times for scheduling by setting them to the
   * business's opening and closing times for the current day.
   */
  const initializeDateRange = () => {
    const date = moment();
    let invalidDate = true;
    while (invalidDate) {
      if (isValidDate(date)) {
        const dayOfWeek = getDayIndex(date);
        const { openTime } = businessHours[dayOfWeek];
        const startAt = moment(openTime, 'HH:mm:ss').set({
          year: date.year(),
          month: date.month(),
          date: date.date()
        });

        const endAt = moment(startAt).add(60, 'minutes');

        setStartAt(startAt);
        setEndAt(endAt);
        invalidDate = false;
      } else {
        date.add(1, 'days');
      }
    }
  };

  // Calculates the earliest possible start time for an appointment based on current date, time and business hours.
  const calculateMinStartAt = () => {
    const currentDayOfWeek = getDayIndex(startAt);
    const { openTime } = businessHours[currentDayOfWeek];
    const now = moment().startOf('minute');

    let newMinStartAt = moment(startAt)
      .hour(moment(openTime, 'HH:mm:ss').hour())
      .minute(moment(openTime, 'HH:mm:ss').minute())
      .second(moment(openTime, 'HH:mm:ss').second());

    // Adjusts the start time to the next quarter-hour if within business hours.
    if (now.isSame(startAt, 'day')) {
      // Calculate how far we are into the current buffer-minute block
      const remainder = now.minutes() % buffer;
      // Calculate minutes to add to round up to the next quarter hour. If we're exactly on a quarter, add 0.
      const minutesToAdd = remainder === 0 ? 0 : buffer - remainder;
      if (now.clone().add(minutesToAdd, 'minutes').isAfter(newMinStartAt)) {
        newMinStartAt = now.clone().add(minutesToAdd, 'minutes');
      }
    }

    const newEndAt = moment(endAt)
      .year(startAt.year())
      .month(startAt.month())
      .date(startAt.date());

    // Ensures that the start time is not before the new minimum start time.
    if (startAt.isBefore(newMinStartAt)) {
      setStartAt(newMinStartAt);
      // Adjusts the end time to maintain a minimum buffer.
      if (newMinStartAt.isSameOrAfter(newEndAt)) {
        setEndAt(newMinStartAt.clone().add(buffer, 'minutes'));
      }
    } else {
      if (startAt.isSameOrAfter(newEndAt)) {
        setEndAt(startAt.clone().add(buffer, 'minutes'));
      } else {
        setEndAt(newEndAt);
      }
    }

    setMinStartAt(newMinStartAt);
  };

  const calculateMaxStartAt = () => {
    setMaxStartAt(moment(maxEndAt).subtract(buffer, 'minutes'));
  };

  const calculateMinEndAt = () => {
    setMinEndAt(moment(minStartAt).add(buffer, 'minutes'));
  };

  const calculateMaxEndAt = () => {
    const currentDayOfWeek = getDayIndex(endAt);

    const { closeTime } = businessHours[currentDayOfWeek];

    const newMaxEndAt = moment(endAt).set({
      hour: moment(closeTime, 'HH:mm:ss').hour(),
      minute: moment(closeTime, 'HH:mm:ss').minute(),
      second: moment(closeTime, 'HH:mm:ss').second()
    });

    if (moment(endAt).isAfter(newMaxEndAt)) {
      setEndAt(newMaxEndAt);
    }

    setMaxEndAt(newMaxEndAt);
  };

  const calculateStartAt = () => {
    if (moment(endAt).isSameOrBefore(startAt)) {
      setStartAt(moment(endAt).subtract(buffer, 'minutes'));
    }
  };

  const isValidDate = (date: moment.Moment) => {
    const isBlockedOut = blockouts.some(
      (blockout) => blockout.date === date.format('YYYY-MM-DD')
    );

    const currentDayOfWeek = getDayIndex(date);

    const { openTime, closeTime } = businessHours[currentDayOfWeek];

    const isOpen = openTime !== '00:00:00' && closeTime !== '00:00:00';

    const isFutureDate =
      (date.isSame(moment(), 'day') &&
        moment().isBefore(
          moment(date).set({
            hour: moment(closeTime, 'HH:mm:ss').hour(),
            minute: moment(closeTime, 'HH:mm:ss').minute() - buffer,
            second: moment(closeTime, 'HH:mm:ss').second()
          })
        )) ||
      moment().isBefore(date);

    const isWithinAppointmentLeadTime = date.isBefore(
      moment().add(settings?.appointmentMaxBookingDays ?? 90, 'days')
    );

    return (
      !isBlockedOut && isOpen && isFutureDate && isWithinAppointmentLeadTime
    );
  };

  const missingOperatingHours = useMemo(
    () =>
      businessHours.every(
        (businessHour) =>
          businessHour.openTime === '00:00:00' &&
          businessHour.closeTime === '00:00:00'
      ) || businessHours.length !== 7,
    [businessHours]
  );

  useEffect(() => {
    if (!missingOperatingHours && settings) {
      initializeDateRange();
    }
  }, [data, missingOperatingHours, settings]);

  useEffect(() => {
    calculateMinStartAt();
  }, [startAt]);

  useEffect(() => {
    calculateStartAt();
    calculateMaxEndAt();
  }, [endAt]);

  useEffect(() => {
    calculateMaxStartAt();
  }, [maxEndAt]);

  useEffect(() => {
    calculateMinEndAt();
  }, [minStartAt]);

  const value = {
    isValidDate,
    setStartAt,
    setEndAt,
    startAt,
    endAt,
    minStartAt,
    minEndAt,
    maxStartAt,
    maxEndAt,
    missingOperatingHours,
    loading: blockoutsLoading
  };

  return (
    <SchedulingContext.Provider value={value}>
      {children}
    </SchedulingContext.Provider>
  );
};

export const useScheduling = () => useContext(SchedulingContext);
