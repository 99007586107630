import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import {
  GetCompanyAlertsQuery,
  useGetCompanyAlertsQuery
} from './__generated__/graphql';
import { useModal } from '../../../hooks';
import { CompanyAlertModal } from './CompanyAlertModal';
import { PlusCircle } from 'react-feather';
import moment from 'moment';
import classNames from 'classnames';
import { Pulse } from '../../core';

type CompanyAlertsTableProps = {
  companyId: string;
};

type CompanyAlert = GetCompanyAlertsQuery['getCompanyAlerts'][0];

export const CompanyAlertsTable: React.FC<CompanyAlertsTableProps> = ({
  companyId
}) => {
  const [companyAlertId, setCompanyAlertId] = useState<string | null>(null);
  const [companyAlerts, setCompanyAlerts] = useState<CompanyAlert[]>([]);

  const {
    isOpen: isCompanyAlertModalOpen,
    toggleModal: toggleCompanyAlertModal
  } = useModal();

  const { loading: companyAlertsLoading } = useGetCompanyAlertsQuery({
    onCompleted: (data) => setCompanyAlerts(data.getCompanyAlerts),
    variables: {
      companyId
    }
  });

  const handleSelect = (id: string) => {
    setCompanyAlertId(id);
    toggleCompanyAlertModal();
  };

  const handleCreate = () => {
    setCompanyAlertId(null);
    toggleCompanyAlertModal();
  };

  const isExpired = (date: string | null) => {
    if (!date) {
      return false;
    }

    if (moment(date).isBefore(moment())) {
      return true;
    }

    return false;
  };

  const getStatus = (companyAlert: CompanyAlert) => {
    const now = moment();

    if (!companyAlert.startAt && !companyAlert.expiresAt) {
      return 'Active';
    }

    if (companyAlert.startAt && moment(companyAlert.startAt).isAfter(now)) {
      return 'Scheduled';
    }

    if (
      companyAlert.expiresAt &&
      moment(companyAlert.expiresAt).isBefore(now)
    ) {
      return 'Expired';
    }

    return 'Active';
  };

  if (companyAlertsLoading) {
    return <Pulse />;
  }

  return (
    <>
      <CompanyAlertModal
        isOpen={isCompanyAlertModalOpen}
        toggle={toggleCompanyAlertModal}
        companyAlertId={companyAlertId}
        companyId={companyId}
      />
      <Row>
        <Col xs="12">
          <Button
            color="primary"
            size="sm"
            className="mb-4"
            onClick={handleCreate}
          >
            New Alert <PlusCircle size={16} color="white" />
          </Button>
          {companyAlerts.length === 0 && (
            <p className="mb-0">No alerts found for this company.</p>
          )}
          {companyAlerts.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Note</th>
                  <th>Date Created</th>
                  <th>Start Date</th>
                  <th>Expiration Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {companyAlerts.map((companyAlert) => (
                  <tr
                    key={companyAlert.id}
                    onClick={() => handleSelect(companyAlert.id)}
                    className={classNames({
                      'text-muted': isExpired(companyAlert.expiresAt)
                    })}
                  >
                    <td className="wrap-cell">{companyAlert.note}</td>
                    <td className="wrap-cell">
                      {moment(companyAlert.createdAt).format('MMMM Do YYYY')}
                    </td>
                    <td className="wrap-cell">
                      {companyAlert.startAt
                        ? moment(companyAlert.startAt).format('MMMM Do YYYY')
                        : '—'}
                    </td>
                    <td className="wrap-cell">
                      {companyAlert.expiresAt
                        ? moment(companyAlert.expiresAt).format('MMMM Do YYYY')
                        : '—'}
                    </td>
                    <td>{getStatus(companyAlert)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </Col>
      </Row>
    </>
  );
};
