import React, { useState } from 'react';
import voca from 'voca';
import { Column } from 'react-table';
import { Button } from 'reactstrap';
import {
  GetBusinessResourcesQuery,
  useGetBusinessResourcesQuery
} from './__generated__/graphql';
import { Table, Toggle } from '../../../components';
import { useModal } from '../../../hooks';
import { ResourceModal } from './ResourceModal';

type Resource = GetBusinessResourcesQuery['getResources'][0];

const columns: Column<Resource>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Capacity',
    accessor: 'capacity'
  },
  {
    Header: 'Type',
    accessor: (resource) => voca.titleCase(resource.type),
    disableSortBy: true
  },
  {
    Header: 'Active',
    accessor: 'deactivatedAt',
    disableSortBy: true,
    Cell: ({ value: deactivatedAt }) => {
      return <Toggle checked={!deactivatedAt} disabled />;
    }
  }
];

export const Resources: React.FC = () => {
  const [resourceId, setResourceId] = useState<string | null>(null);
  const [resources, setResources] = useState<Resource[]>([]);

  const { isOpen: isResourceModalOpen, toggleModal: toggleResourceModal } =
    useModal();

  const { loading } = useGetBusinessResourcesQuery({
    onCompleted: (data) => setResources(data.getResources)
  });

  const handleSelect = (resource: Resource) => {
    setResourceId(resource.id);
    toggleResourceModal();
  };

  const handleCreate = () => {
    setResourceId(null);
    toggleResourceModal();
  };

  return (
    <>
      <ResourceModal
        isOpen={isResourceModalOpen}
        toggle={toggleResourceModal}
        resourceId={resourceId}
      />
      <Table<Resource>
        loading={loading}
        data={resources}
        columns={columns}
        handleSelect={handleSelect}
        sortBy={{
          id: 'name',
          desc: false
        }}
      >
        <Button
          size="sm"
          color="default"
          className="ml-2"
          onClick={handleCreate}
        >
          Create
        </Button>
      </Table>
    </>
  );
};
