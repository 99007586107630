import moment from 'moment';
import {
  ResourceType,
  UserRole,
  CompanyPhoneTier,
  LogType,
  LogStatus,
  LogTag
} from './__generated__/graphql';
import { Option } from './types';

export const logType: Record<LogType, string> = {
  DROPOFF: 'Dropoff',
  EMAIL: 'Email',
  PHONE_CALL: 'Phone Call',
  FAX: 'Fax',
  SMS: 'Text / SMS',
  TRAFFIC: 'Traffic',
  VOICEMAIL: 'Voicemail',
  AWARENESS: 'Awareness',
  OTHER: 'Other'
};

export const logStatus: Record<LogStatus, string> = {
  NEW: 'New',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  DELETED: 'Deleted'
};

export const logTag: Record<LogTag, string> = {
  APPOINTMENT_SCHEDULED: 'Appointment Scheduled',
  APPOINTMENT_CANCELLED: 'Appointment Cancelled',
  CALI: 'Cali',
  CONCIERGED: 'Concierged',
  CONNECTED: 'Connected',
  LIVE: 'Live',
  NAME_VERIFIED: 'Name Verified',
  NOTIFIED: 'Notified',
  NO_ACTION: 'No Action',
  PROSPECT: 'Prospect',
  ROBOT: 'Robot'
};

const createOptionsFromEnum = (enumObject: object): Option[] =>
  Object.entries(enumObject).map(([key, value]) => ({
    value,
    label: key
  }));

const createOptionsFromObject = (object: object): Option[] =>
  Object.entries(object).map(([key, value]) => ({
    value: key,
    label: value
  }));

export const userRoleOptions: Option[] = [
  { label: 'Default', value: UserRole.Client },
  { label: 'Administrator', value: UserRole.Business }
];
export const resourceTypeOptions: Option[] =
  createOptionsFromEnum(ResourceType);
export const logTypeOptions: Option[] = createOptionsFromObject(logType);
export const logStatusOptions: Option[] = createOptionsFromObject(logStatus);
export const logTagOptions: Option[] = createOptionsFromObject(logTag);

export const phoneTierOptions: Option[] = [
  {
    value: null,
    label: 'N/A'
  },
  {
    value: CompanyPhoneTier.White,
    label: '⬜ — White'
  },
  {
    value: CompanyPhoneTier.Blue,
    label: '🟦 — Blue'
  },
  {
    value: CompanyPhoneTier.Black,
    label: '⬛ — Black'
  }
];

export const monthOptions: Option[] = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' }
];

export const generateYearOptions = (): Option[] => {
  const currentYear = moment().year();
  const startYear = 2018;
  const yearOptions: Option[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearOptions.push({ label: `${year}`, value: `${year}` });
  }

  return yearOptions;
};

export const yearOptions: Option[] = generateYearOptions();
