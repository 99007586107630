import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (
  phoneNumber: string | null | undefined,
  format: 'E.164' | 'INTERNATIONAL'
) => {
  if (!phoneNumber) {
    return '';
  }

  if (isValidPhoneNumber(phoneNumber, 'US')) {
    return parsePhoneNumber(phoneNumber, 'US').format(format);
  }

  return phoneNumber;
};

export const formatPhoneNumberNational = (
  phoneNumber: string | null | undefined
) => {
  if (!phoneNumber) {
    return '';
  }

  if (isValidPhoneNumber(phoneNumber, 'US')) {
    return parsePhoneNumber(phoneNumber, 'US').formatNational();
  }

  return phoneNumber;
};

export const generateNumberOptions = (limit: number) =>
  new Array(limit).fill(null).map((_, index) => ({
    value: index + 1,
    label: index + 1
  }));

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
