import React, { forwardRef } from 'react';
import { InputGroup } from 'reactstrap';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';

export type TextAreaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  'value'
> & {
  value?: string | number | readonly string[] | undefined | null;
} & InputWrapperProps;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, helperText, error, value, ...rest }, ref) => {
    return (
      <InputWrapper label={label} helperText={helperText} error={error}>
        <InputGroup className="input-group-alternative">
          <textarea
            ref={ref}
            className="form-control"
            style={{ resize: 'none' }}
            rows={4}
            value={value ?? undefined}
            {...rest}
          />
        </InputGroup>
      </InputWrapper>
    );
  }
);

TextArea.displayName = 'TextArea';
