import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  GetBusinessLogsQuery,
  GetBusinessLogsQueryVariables,
  useGetBusinessLogsLazyQuery
} from './__generated__/graphql';
import {
  SortDirection,
  LogField,
  GetLogsOptions,
  LogType,
  LogStatus
} from '../../__generated__/graphql';
import moment from 'moment';
import { socket } from '../../utils/socket';

type Log = GetBusinessLogsQuery['getLogs']['logs'][0];

export type SetFilter = (
  key: keyof LogFilters,
  value: LogFilters[keyof LogFilters]
) => void;

type UseLogsResult = {
  logs: Log[];
  totalCount: number;
  pageCount: number;
  currentPage: number;
  pageSize: number;
  loading: boolean;
  goToPage: (page: number) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  filters: GetLogsOptions;
  setFilter: SetFilter;
};

type LogFilters = {
  startAt: string;
  endAt: string;
  sortField: LogField;
  sortDirection: SortDirection;
  type: LogType | null;
  status: LogStatus | null;
  companyId: string | null;
  authorId: string | null;
  keywords: string | null;
};

export const useLogs = (): UseLogsResult => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(25);

  const [cursor, setCursor] = useState<string | null>(null);

  const [filters, setFilters] = useState<LogFilters>({
    startAt: moment().startOf('day').toISOString(),
    endAt: moment().endOf('day').toISOString(),
    sortDirection: SortDirection.Desc,
    sortField: LogField.CreatedAt,
    type: null,
    status: null,
    companyId: null,
    authorId: null,
    keywords: null
  });

  const setFilter: SetFilter = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value
    }));

    setCursor(null);
    setCurrentPage(0);
    logsCache.current = [];
  };

  const logsCache = useRef<Log[]>([]);

  const [getLogs, { loading }] = useGetBusinessLogsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const {
        getLogs: { logs: newLogs, totalCount }
      } = data;

      const newCache = [...logsCache.current, ...newLogs];

      logsCache.current = newCache;

      setLogs(
        newCache.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
      );
      setTotalCount(totalCount);
      setCursor(newLogs[newLogs.length - 1]?.id ?? null);
    }
  });

  const variables: GetBusinessLogsQueryVariables = useMemo(
    () => ({
      options: {
        pagination: {
          cursor,
          take: 100
        },
        orderBy: {
          field: filters.sortField,
          sort: filters.sortDirection
        },
        filters: {
          authorId: filters.authorId ?? undefined,
          companyId: filters.companyId ?? undefined,
          type: filters.type ?? undefined,
          status: filters.status ?? undefined
        },
        dateRange: {
          startAt: filters.startAt,
          endAt: filters.endAt
        },
        keywords: filters.keywords
      }
    }),
    [cursor, filters]
  );

  const fetchLogs = useCallback(() => {
    if (logsCache.current.length > currentPage * pageSize) {
      setLogs(
        logsCache.current.slice(
          currentPage * pageSize,
          (currentPage + 1) * pageSize
        )
      );
    } else {
      getLogs({
        variables: variables
      });
    }
  }, [currentPage, variables, getLogs]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  useEffect(() => {
    socket.on('log:created', (createdLog) => {
      const createdAt = moment(createdLog.insertedAt);
      const startAt = moment(filters.startAt);
      const endAt = moment(filters.endAt);

      if (createdAt.isBetween(startAt, endAt)) {
        setLogs((prevLogs) => {
          const newLogs = [...prevLogs, createdLog].sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt))
          );
          logsCache.current = newLogs;
          return newLogs.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          );
        });

        setTotalCount((prev) => prev + 1);
      }
    });

    socket.on('log:updated', (updatedLog) => {
      setLogs((prevLogs) => {
        const newLogs = prevLogs.map((log) =>
          log.id === updatedLog.id ? updatedLog : log
        );
        logsCache.current = newLogs;
        return newLogs.slice(
          currentPage * pageSize,
          (currentPage + 1) * pageSize
        );
      });
    });

    socket.on('log:deleted', ({ id: logId }) => {
      setLogs((prevLogs) => {
        const newLogs = prevLogs.filter((log) => log.id !== logId);
        logsCache.current = newLogs;
        return newLogs.slice(
          currentPage * pageSize,
          (currentPage + 1) * pageSize
        );
      });

      setTotalCount((prev) => prev - 1);
    });

    return () => {
      socket.off('log:created');
      socket.off('log:updated');
      socket.off('log:deleted');
    };
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  const goToPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const hasNextPage = currentPage + 1 < pageCount;
  const hasPreviousPage = currentPage > 0;

  return {
    logs,
    totalCount,
    pageCount,
    currentPage,
    pageSize,
    loading,
    goToPage,
    hasNextPage,
    hasPreviousPage,
    filters: variables.options!,
    setFilter
  };
};
