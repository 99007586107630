import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessHoursQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessHoursQuery = { getBusiness: { id: string, blockouts: Array<{ id: string, date: string }>, businessHours: Array<{ id: string, day: Types.DayOfWeek, openTime: string, closeTime: string }> } };


export const GetBusinessHoursDocument = gql`
    query GetBusinessHours {
  getBusiness {
    id
    blockouts {
      id
      date
    }
    businessHours {
      id
      day
      openTime
      closeTime
    }
  }
}
    `;

/**
 * __useGetBusinessHoursQuery__
 *
 * To run a query within a React component, call `useGetBusinessHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessHoursQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessHoursQuery, GetBusinessHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessHoursQuery, GetBusinessHoursQueryVariables>(GetBusinessHoursDocument, options);
      }
export function useGetBusinessHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessHoursQuery, GetBusinessHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessHoursQuery, GetBusinessHoursQueryVariables>(GetBusinessHoursDocument, options);
        }
export type GetBusinessHoursQueryHookResult = ReturnType<typeof useGetBusinessHoursQuery>;
export type GetBusinessHoursLazyQueryHookResult = ReturnType<typeof useGetBusinessHoursLazyQuery>;
export type GetBusinessHoursQueryResult = Apollo.QueryResult<GetBusinessHoursQuery, GetBusinessHoursQueryVariables>;