import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMembershipOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMembershipOptionsQuery = { getBusiness: { id: string, memberships: Array<{ id: string, name: string, emoji: string }> } };


export const GetMembershipOptionsDocument = gql`
    query GetMembershipOptions {
  getBusiness {
    id
    memberships {
      id
      name
      emoji
    }
  }
}
    `;

/**
 * __useGetMembershipOptionsQuery__
 *
 * To run a query within a React component, call `useGetMembershipOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembershipOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetMembershipOptionsQuery, GetMembershipOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembershipOptionsQuery, GetMembershipOptionsQueryVariables>(GetMembershipOptionsDocument, options);
      }
export function useGetMembershipOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipOptionsQuery, GetMembershipOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembershipOptionsQuery, GetMembershipOptionsQueryVariables>(GetMembershipOptionsDocument, options);
        }
export type GetMembershipOptionsQueryHookResult = ReturnType<typeof useGetMembershipOptionsQuery>;
export type GetMembershipOptionsLazyQueryHookResult = ReturnType<typeof useGetMembershipOptionsLazyQuery>;
export type GetMembershipOptionsQueryResult = Apollo.QueryResult<GetMembershipOptionsQuery, GetMembershipOptionsQueryVariables>;