import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { UserRole } from '../../../__generated__/graphql';
import { Lock, Power } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse
} from '../../../components';
import { UserForm } from './UserForm';
import { useUserActions } from './useUserActions';
import { useModal } from '../../../hooks';

type UserModalProps = {
  isOpen: boolean;
  userId: string | null;
  toggle: () => void;
};

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  birthdate: string | null;
  title: string | null;
  role: UserRole;
  companyId: string;
};

const defaultUser: User = {
  companyId: '',
  firstName: '',
  lastName: '',
  email: '',
  birthdate: null,
  phoneNumber: null,
  title: null,
  role: UserRole.Client
};

enum UserMode {
  Create = 'Create',
  Update = 'Update'
}

export const UserModal: React.FC<UserModalProps> = (props) => {
  const { isOpen, toggle, userId } = props;

  const [mode, setMode] = useState<UserMode>(UserMode.Create);
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isUserActivationModalOpen,
    toggleModal: toggleUserActivationModal
  } = useModal();

  const {
    user,
    getUser,
    getUserLoading,
    handleCreateUser,
    createUserLoading,
    handleUpdateUser,
    updateUserLoading,
    handleToggleUser,
    toggleUserLoading,
    handleSendPasswordReset
  } = useUserActions({ toggle, toggleUserActivationModal });

  const form = useForm<User>({
    defaultValues: defaultUser
  });

  useEffect(() => {
    form.reset(user);
  }, [user]);

  useEffect(() => {
    setLoading(createUserLoading || updateUserLoading);
  }, [createUserLoading, updateUserLoading]);

  useEffect(() => {
    if (isOpen) {
      if (userId) {
        setMode(UserMode.Update);
        getUser({
          variables: {
            id: userId
          }
        });
      } else {
        setMode(UserMode.Create);
        form.reset(defaultUser);
      }
    }
  }, [userId, isOpen]);

  const handleSubmit = (data: User) => {
    if (mode === UserMode.Update) {
      handleUpdateUser(data);
    } else {
      handleCreateUser(data);
    }
  };

  const menuItems = [
    {
      icon: <Power size={14} />,
      label: user?.deactivatedAt ? 'Activate' : 'Deactivate',
      onClick: toggleUserActivationModal
    },
    {
      icon: <Lock size={14} />,
      label: 'Reset Password',
      onClick: handleSendPasswordReset,
      hide: !!user?.deactivatedAt
    }
  ];

  const isUserActive = useMemo(
    () => !user?.deactivatedAt,
    [isUserActivationModalOpen]
  );

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isUserActivationModalOpen && !!user?.company.deactivatedAt}
        toggle={toggleUserActivationModal}
        title="Activate User"
        body={
          <p>
            Unable to activate{' '}
            <span className="font-weight-bold text-default">
              {`${user?.firstName} ${user?.lastName}`}
            </span>
            {', '}
            the associated company{', '}
            <span className="font-weight-bold text-default">
              {user?.company.name}
            </span>
            {', '}
            has been deactivated. To continue please activate the associated
            company and try again.
          </p>
        }
        cancelText="Close"
      />
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isUserActivationModalOpen && !user?.company.deactivatedAt}
        toggle={toggleUserActivationModal}
        title={isUserActive ? 'Deactivate User' : 'Activate User'}
        body={
          <p>
            {`You are about to ${isUserActive ? 'deactivate' : 'activate'} `}
            <span className="font-weight-bold text-default">
              {`${user?.firstName} ${user?.lastName}.`}
            </span>{' '}
            {`Access to their account will be ${isUserActive ? 'revoked' : 'restored'}, are you sure you want to do this?`}
          </p>
        }
        loading={toggleUserLoading}
        confirmationText={isUserActive ? 'Yes, deactivate' : 'Yes, activate'}
        onConfirm={handleToggleUser}
      />
      <Modal toggle={!loading ? toggle : () => null} isOpen={isOpen} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="editUser">
            {`${mode} User`}
          </h5>
          {user && mode === UserMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getUserLoading ? (
          <Pulse />
        ) : (
          <>
            <UserForm form={form} isEditting={mode === UserMode.Update} />
            <ModalFooter>
              <Button disabled={loading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={loading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {mode}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
