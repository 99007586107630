import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserCalendarQueryVariables = Types.Exact<{
  options: Types.InputMaybe<Types.GetAppointmentsOptions>;
}>;


export type GetUserCalendarQuery = { getAppointments: Array<{ id: string, notes: string | null, startAt: string, endAt: string, status: Types.AppointmentStatus, resource: { id: string, name: string }, user: { id: string, firstName: string, lastName: string } }> };

export type GetUserAppointmentQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetUserAppointmentQuery = { getAppointment: { id: string, notes: string | null, startAt: string, endAt: string, status: Types.AppointmentStatus, resource: { id: string, name: string, type: Types.ResourceType, capacity: number }, user: { id: string, firstName: string, lastName: string } } };

export type UpdateUserAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateAppointmentInput;
}>;


export type UpdateUserAppointmentMutation = { updateAppointment: { id: string } };

export type CancelUserAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type CancelUserAppointmentMutation = { cancelAppointment: { id: string } };


export const GetUserCalendarDocument = gql`
    query GetUserCalendar($options: GetAppointmentsOptions) {
  getAppointments(options: $options) {
    id
    notes
    startAt
    endAt
    status
    resource {
      id
      name
    }
    user {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetUserCalendarQuery__
 *
 * To run a query within a React component, call `useGetUserCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCalendarQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUserCalendarQuery(baseOptions?: Apollo.QueryHookOptions<GetUserCalendarQuery, GetUserCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCalendarQuery, GetUserCalendarQueryVariables>(GetUserCalendarDocument, options);
      }
export function useGetUserCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCalendarQuery, GetUserCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCalendarQuery, GetUserCalendarQueryVariables>(GetUserCalendarDocument, options);
        }
export type GetUserCalendarQueryHookResult = ReturnType<typeof useGetUserCalendarQuery>;
export type GetUserCalendarLazyQueryHookResult = ReturnType<typeof useGetUserCalendarLazyQuery>;
export type GetUserCalendarQueryResult = Apollo.QueryResult<GetUserCalendarQuery, GetUserCalendarQueryVariables>;
export const GetUserAppointmentDocument = gql`
    query GetUserAppointment($id: UUID!) {
  getAppointment(id: $id) {
    id
    notes
    startAt
    endAt
    status
    resource {
      id
      name
      type
      capacity
    }
    user {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetUserAppointmentQuery__
 *
 * To run a query within a React component, call `useGetUserAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetUserAppointmentQuery, GetUserAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAppointmentQuery, GetUserAppointmentQueryVariables>(GetUserAppointmentDocument, options);
      }
export function useGetUserAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAppointmentQuery, GetUserAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAppointmentQuery, GetUserAppointmentQueryVariables>(GetUserAppointmentDocument, options);
        }
export type GetUserAppointmentQueryHookResult = ReturnType<typeof useGetUserAppointmentQuery>;
export type GetUserAppointmentLazyQueryHookResult = ReturnType<typeof useGetUserAppointmentLazyQuery>;
export type GetUserAppointmentQueryResult = Apollo.QueryResult<GetUserAppointmentQuery, GetUserAppointmentQueryVariables>;
export const UpdateUserAppointmentDocument = gql`
    mutation UpdateUserAppointment($id: UUID!, $data: UpdateAppointmentInput!) {
  updateAppointment(id: $id, data: $data, options: {notifyUser: true}) {
    id
  }
}
    `;
export type UpdateUserAppointmentMutationFn = Apollo.MutationFunction<UpdateUserAppointmentMutation, UpdateUserAppointmentMutationVariables>;

/**
 * __useUpdateUserAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateUserAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAppointmentMutation, { data, loading, error }] = useUpdateUserAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAppointmentMutation, UpdateUserAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAppointmentMutation, UpdateUserAppointmentMutationVariables>(UpdateUserAppointmentDocument, options);
      }
export type UpdateUserAppointmentMutationHookResult = ReturnType<typeof useUpdateUserAppointmentMutation>;
export type UpdateUserAppointmentMutationResult = Apollo.MutationResult<UpdateUserAppointmentMutation>;
export type UpdateUserAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateUserAppointmentMutation, UpdateUserAppointmentMutationVariables>;
export const CancelUserAppointmentDocument = gql`
    mutation CancelUserAppointment($id: UUID!) {
  cancelAppointment(id: $id, options: {notifyUser: true}) {
    id
  }
}
    `;
export type CancelUserAppointmentMutationFn = Apollo.MutationFunction<CancelUserAppointmentMutation, CancelUserAppointmentMutationVariables>;

/**
 * __useCancelUserAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelUserAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserAppointmentMutation, { data, loading, error }] = useCancelUserAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelUserAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelUserAppointmentMutation, CancelUserAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUserAppointmentMutation, CancelUserAppointmentMutationVariables>(CancelUserAppointmentDocument, options);
      }
export type CancelUserAppointmentMutationHookResult = ReturnType<typeof useCancelUserAppointmentMutation>;
export type CancelUserAppointmentMutationResult = Apollo.MutationResult<CancelUserAppointmentMutation>;
export type CancelUserAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelUserAppointmentMutation, CancelUserAppointmentMutationVariables>;