import React from 'react';
import { Quill } from '../../../../components';
import { useLogContext } from '../../../../contexts';

export const CompanyTrainingTab: React.FC = () => {
  const { company } = useLogContext();

  if (!company?.training) {
    return (
      <p className="mb-0">No training information found for this company.</p>
    );
  }

  return (
    <Quill
      className="quill-disabled ql-h100"
      value={company.training}
      readOnly
    />
  );
};
