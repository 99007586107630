import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyUsageQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID']['input'];
  dateRange: Types.DateRangeOptions;
}>;


export type GetCompanyUsageQuery = { getCompanyUsageReport: { companyId: string, hoursUtilized: number, hoursAvailable: number, hoursAllocated: number, hoursOverLimit: number, percentUtilized: number, isOverLimit: boolean } };


export const GetCompanyUsageDocument = gql`
    query GetCompanyUsage($companyId: UUID!, $dateRange: DateRangeOptions!) {
  getCompanyUsageReport(companyId: $companyId, dateRange: $dateRange) {
    companyId
    hoursUtilized
    hoursAvailable
    hoursAllocated
    hoursOverLimit
    percentUtilized
    isOverLimit
  }
}
    `;

/**
 * __useGetCompanyUsageQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsageQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetCompanyUsageQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyUsageQuery, GetCompanyUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUsageQuery, GetCompanyUsageQueryVariables>(GetCompanyUsageDocument, options);
      }
export function useGetCompanyUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsageQuery, GetCompanyUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUsageQuery, GetCompanyUsageQueryVariables>(GetCompanyUsageDocument, options);
        }
export type GetCompanyUsageQueryHookResult = ReturnType<typeof useGetCompanyUsageQuery>;
export type GetCompanyUsageLazyQueryHookResult = ReturnType<typeof useGetCompanyUsageLazyQuery>;
export type GetCompanyUsageQueryResult = Apollo.QueryResult<GetCompanyUsageQuery, GetCompanyUsageQueryVariables>;