import React from 'react';
import { Row, Col } from 'reactstrap';
import { CheckCircle, MinusCircle } from 'react-feather';
import { useLogContext } from '../../../../contexts';
import { formatPhoneNumberNational } from '../../../../utils';

export const CompanyInformationTab: React.FC = () => {
  const { company } = useLogContext();

  if (!company) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col xs="6">
          <div className="form-control-label">Name</div>
          <p>{company.name}</p>
        </Col>
        <Col xs="6">
          <div className="form-control-label">Email</div>
          <p>{company.email || '-'}</p>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <div className="form-control-label">Phone Number</div>
          <p>
            {company.phoneNumber
              ? formatPhoneNumberNational(company.phoneNumber)
              : '-'}
          </p>
        </Col>
        <Col xs="6">
          <div className="form-control-label">Fax Number</div>
          <p>
            {company.faxNumber
              ? formatPhoneNumberNational(company.faxNumber)
              : '-'}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <div className="form-control-label">Toll Number</div>
          <p>
            {company.tollNumber
              ? formatPhoneNumberNational(company.tollNumber)
              : '-'}
          </p>
        </Col>
        <Col xs="6">
          <div className="form-control-label">Website</div>
          <p>{company.websiteUrl || '-'}</p>
        </Col>
      </Row>
      <h3>Users</h3>
      <hr className="mt-0" />
      <Row>
        {company.users.length === 0 && (
          <Col>
            <p className="mt--1">No users found for this company.</p>
          </Col>
        )}
        {company.users
          .filter((user) => user.deactivatedAt === null)
          .map((user) => (
            <Col xs="6" key={user.id} className="mb-3">
              <div className="form-control-label">
                {`${user.firstName} ${user.lastName}`}{' '}
                {user.title && `(${user.title})`}
              </div>
              {user.email && <p className="mb-0">{user.email}</p>}
              {user.phoneNumber && (
                <p className="mb-0">
                  {formatPhoneNumberNational(user.phoneNumber)}
                </p>
              )}
            </Col>
          ))}
      </Row>
      <h3>Locations</h3>
      <hr className="mt-0" />
      <Row>
        {company.locations.length === 0 && (
          <Col>
            <p className="mt--1 mb-0">No locations found for this company.</p>
          </Col>
        )}
        {company.locations.map((location) => (
          <Col xs="6" key={location.id} className="mb-3">
            <div className="form-control-label">
              {location.isPrimary ? (
                <>
                  <CheckCircle size={16} className="mr-2" />
                  Primary
                </>
              ) : (
                <>
                  <MinusCircle size={16} className="mr-2" />
                  Secondary
                </>
              )}
            </div>
            <p className="mb-0">
              {[location.addressLine1, location.addressLine2]
                .filter((a) => a)
                .join(', ')}
            </p>
            <p className="mb-0">
              {[
                location.addressCity,
                location.addressState,
                location.addressZip
              ]
                .filter((a) => a)
                .join(', ')}
            </p>
          </Col>
        ))}
      </Row>
    </div>
  );
};
