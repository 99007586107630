import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserOptionsQueryVariables = Types.Exact<{
  includeInactive: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetUserOptionsQuery = { getUsers: Array<{ id: string, companyId: string, firstName: string, lastName: string, role: Types.UserRole }> };


export const GetUserOptionsDocument = gql`
    query GetUserOptions($includeInactive: Boolean) {
  getUsers(includeInactive: $includeInactive) {
    id
    companyId
    firstName
    lastName
    role
  }
}
    `;

/**
 * __useGetUserOptionsQuery__
 *
 * To run a query within a React component, call `useGetUserOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOptionsQuery({
 *   variables: {
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useGetUserOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOptionsQuery, GetUserOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOptionsQuery, GetUserOptionsQueryVariables>(GetUserOptionsDocument, options);
      }
export function useGetUserOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOptionsQuery, GetUserOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOptionsQuery, GetUserOptionsQueryVariables>(GetUserOptionsDocument, options);
        }
export type GetUserOptionsQueryHookResult = ReturnType<typeof useGetUserOptionsQuery>;
export type GetUserOptionsLazyQueryHookResult = ReturnType<typeof useGetUserOptionsLazyQuery>;
export type GetUserOptionsQueryResult = Apollo.QueryResult<GetUserOptionsQuery, GetUserOptionsQueryVariables>;