import React, { useEffect, useState } from 'react';
import moment, { MomentInput } from 'moment-timezone';
import ReactDateTime from 'react-datetime';
import Color from 'color';
import { Event, momentLocalizer } from 'react-big-calendar';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useModal, useUser } from '../../hooks';
import {
  GetUserCalendarQuery,
  useGetUserCalendarLazyQuery
} from './__generated__/graphql';
import { Calendar, DEFAULT_HEX_COLOR, Pulse } from '../../components';
import { UserAppointmentModal } from './UserAppointmentModal';

type Appointment = GetUserCalendarQuery['getAppointments'][0];

export const UserCalendar: React.FC = () => {
  const user = useUser();

  const [selectedDate, setSelectedDate] = useState(moment());
  const [events, setEvents] = useState<Event[]>([]);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);

  const {
    isOpen: isUserAppointmentModalOpen,
    toggleModal: toggleUserAppointmentModal
  } = useModal();

  useEffect(() => {
    if (!isUserAppointmentModalOpen) {
      setAppointmentId(null);
    }
  }, [isUserAppointmentModalOpen]);

  useEffect(() => {
    const input = document.querySelector('.rdt input');

    if (input) {
      input.classList.remove('form-control');
    }

    setTimeout(() => {
      const timeIndicator =
        document.querySelector('.rbc-current-time-indicator') ||
        document.querySelector('.rbc-event');

      if (timeIndicator) {
        const body = document.querySelector('body');
        body!.setAttribute('style', 'position: fixed;');
        timeIndicator.scrollIntoView({ behavior: 'smooth', block: 'center' });
        body!.setAttribute('style', 'position: relative;');
      }
    }, 500);
  }, [selectedDate]);

  const [getUserCalendar, { loading: getUserCalendarLoading }] =
    useGetUserCalendarLazyQuery();

  useEffect(() => {
    if (user) {
      getUserCalendar({
        variables: {
          options: {
            dateRange: {
              startAt: selectedDate.startOf('day').toISOString(),
              endAt: selectedDate.endOf('day').toISOString()
            },
            filters: {
              companyId: user.company.id
            }
          }
        },
        notifyOnNetworkStatusChange: true,
        pollInterval: 60000,
        onCompleted: ({ getAppointments: data }) => {
          setEvents(formatEvents(data));
        }
      });
    }
  }, [selectedDate, user]);

  const formatEvents = (appointments: Appointment[]) =>
    appointments.map((appointment) => {
      const hexColor = Color(DEFAULT_HEX_COLOR);
      let color = hexColor.darken(0.5).desaturate(0.5);
      if (appointment.status === 'COMPLETED') {
        color = Color(color).darken(0.3);
      }
      const backgroundColor = hexColor.darken(hexColor.luminosity() * 0.3);
      return {
        ...appointment,
        start: moment(appointment.startAt).toDate(),
        end: moment(appointment.endAt).toDate(),
        resourceId: appointment.resource.id,
        title: (
          <>
            <div
              className="my-1 badge"
              style={{ backgroundColor: backgroundColor.toString() }}
            >
              {`${appointment.user.firstName} ${appointment.user.lastName}`}
            </div>
            <h6 style={{ color: color.toString() }} className="mb-0">
              {`${moment(appointment.startAt).format('h:mm A')} - ${moment(appointment.endAt).format('h:mm A')}`}
            </h6>
            <h6 style={{ color: color.toString() }} className="mb-0">
              {appointment.resource.name}
            </h6>
          </>
        )
      };
    });

  const handleSelectDate = (date: moment.Moment | Date) =>
    setSelectedDate(moment(date));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectEvent = (event: any) => {
    if (moment().isBefore(moment(event.endAt))) {
      setAppointmentId(event.id);
      toggleUserAppointmentModal();
    }
  };

  return (
    <>
      <UserAppointmentModal
        isOpen={isUserAppointmentModalOpen}
        toggle={toggleUserAppointmentModal}
        appointmentId={appointmentId}
      />
      <Row>
        <Col>
          <Card>
            {getUserCalendarLoading && <Pulse className="spinner-over-blur" />}
            <CardHeader className="py-4">
              <Row>
                <Col md="3" xs="12" className="custom-padding">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleSelectDate(moment())}
                  >
                    Today
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      handleSelectDate(moment(selectedDate).subtract(1, 'day'))
                    }
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      handleSelectDate(moment(selectedDate).add(1, 'day'))
                    }
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </Col>
                <Col md="6" xs="12" className="d-flex justify-content-center">
                  <ReactDateTime
                    className="rdt-special"
                    timeFormat={false}
                    value={selectedDate}
                    onChange={(date: MomentInput) =>
                      setSelectedDate(moment(date))
                    }
                    inputProps={{ readOnly: true }}
                    dateFormat="dddd, MMMM Do, YYYY"
                    closeOnSelect
                    closeOnClickOutside
                  />
                </Col>
              </Row>
            </CardHeader>
            <div className={getUserCalendarLoading ? 'blur' : ''}>
              <CardBody style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                <Calendar
                  localizer={momentLocalizer(moment)}
                  events={events}
                  date={selectedDate.toDate()}
                  onNavigate={handleSelectDate}
                  onSelectEvent={handleSelectEvent}
                  step={15}
                  timeslots={2}
                />
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
