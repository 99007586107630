import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { PlanUsageCard, Pulse } from '../../components';
import moment from 'moment-timezone';
import { AnnouncementsCard } from './AnnouncementsCard';
import BlockoutsCard from './BlockoutsCard';
import { AppointmentsCard } from './AppointmentsCard';
import { useUser } from '../../hooks';

export const greetings = [
  'Welcome back',
  'Welcome',
  'Hola 💃🏻',
  'Bonjour 🇫🇷',
  'Good day',
  'Nice to see you',
  'Have a great day',
  'Keep up the great work',
  'Howdy 🤠',
  'Greetings 🤝',
  'Great to see you 😊',
  'Long time, no see ⌛',
  'Hi there',
  'Ahoy',
  'Salutations',
  'Aloha 🌸',
  'Namaste 🧘',
  `Happy ${moment().format('dddd')}`,
  'G’day',
  'Hey there'
];

export const Dashboard: React.FC = () => {
  const [greeting, setGreeting] = useState<string | null>(null);

  useEffect(() => {
    setGreeting(greetings[Math.floor(Math.random() * greetings.length)]);
  }, []);

  const user = useUser();

  if (!user) {
    return <Pulse />;
  }

  return (
    <>
      <Row>
        <Col>
          <h3 className="mb-3">{`${greeting}, ${user.firstName}`}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <AnnouncementsCard />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <AppointmentsCard />
          <BlockoutsCard />
        </Col>
        <Col lg="6">
          <PlanUsageCard
            companyId={user.company.id}
            dateRange={{
              startAt: moment().startOf('month').toISOString(),
              endAt: moment().endOf('month').toISOString()
            }}
          />
        </Col>
      </Row>
    </>
  );
};
