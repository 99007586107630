import { Navigate } from 'react-router-dom';
import {
  Account,
  Business,
  Dashboard,
  Login,
  Logout,
  Maintenance,
  ResetPassword,
  Schedule,
  PageNotFound
} from '../../../pages';
import { ApplicationRoute } from '../../../types';
import { UserRole } from '../../../__generated__/graphql';
import { UserCalendar } from '../../../pages/Calendar';
import { BusinessCalendar } from '../../../pages/Business/Calendar';
import { FileViewer } from '../FileViewer';

const routes: ApplicationRoute[] = [
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
    authenticationRequired: true
  },
  {
    path: '/login',
    element: <Login />,
    authenticationRequired: false
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    authenticationRequired: false
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    title: 'Dashboard',
    authenticationRequired: true
  },
  {
    path: '/calendar',
    element: <UserCalendar />,
    title: 'Calendar',
    authenticationRequired: true
  },
  {
    path: '/schedule/search',
    element: <Schedule.Search />,
    title: 'Search',
    authenticationRequired: true
  },
  {
    path: '/schedule/book/:id',
    element: <Schedule.Book />,
    title: 'Schedule',
    authenticationRequired: true
  },
  {
    path: '/schedule/confirmation/:id',
    element: <Schedule.Confirmation />,
    title: 'Appointment Confirmation',
    authenticationRequired: true
  },
  {
    path: '/business/calendar',
    element: <BusinessCalendar />,
    title: 'Calendar',
    authenticationRequired: true,
    roles: [UserRole.Business],
    fullWidth: true
  },
  {
    path: '/business/logs',
    element: <Business.CaptainsLog />,
    title: `Captain's Log`,
    authenticationRequired: true,
    roles: [UserRole.Business],
    fullWidth: true
  },
  {
    path: '/business/logs/:id',
    element: <Business.CaptainsLog />,
    title: `Captain's Log`,
    authenticationRequired: true,
    roles: [UserRole.Business],
    fullWidth: true
  },
  {
    path: '/business/users',
    element: <Business.Users />,
    title: 'Users',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/companies',
    element: <Business.Companies />,
    title: 'Companies',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/resources',
    element: <Business.Resources />,
    title: 'Resources',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/announcements',
    element: <Business.Announcements />,
    title: 'Announcements',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/blockouts',
    element: <Business.Blockouts />,
    title: 'Blockouts',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/reports/company-usage/:id?',
    element: <Business.Reports.CompanyUsage />,
    title: 'Company Usage Report',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/reports/captains-log',
    element: <Business.Reports.CaptainsLog />,
    title: `Captain's Log Report`,
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/business/settings',
    element: <Business.Settings />,
    title: 'Settings',
    authenticationRequired: true,
    roles: [UserRole.Business]
  },
  {
    path: '/account',
    element: <Account />,
    title: 'Account',
    authenticationRequired: true
  },
  {
    path: '/logout',
    element: <Logout />,
    authenticationRequired: false
  },
  {
    path: '/businesses/:businessId/uploads/:fileUploadId',
    element: <FileViewer />,
    authenticationRequired: false
  },
  {
    path: '/page-not-found',
    element: <PageNotFound />,
    title: '404 Error',
    authenticationRequired: true
  },
  {
    path: '/maintenance',
    element: <Maintenance />,
    authenticationRequired: false
  },
  {
    path: '/*',
    element: <Navigate to="/page-not-found" />,
    authenticationRequired: false
  }
];

export default routes;
