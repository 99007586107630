import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './utils/reportWebVitals';
import client from './utils/apolloClient';

import { App } from './components';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/argon-dashboard-pro-react.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <App />
    <ToastContainer
      autoClose={3000}
      icon={false}
      theme="colored"
      pauseOnHover={false}
    />
  </ApolloProvider>
);

reportWebVitals();
