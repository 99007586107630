import React, { useState } from 'react';
import { Mail } from 'react-feather';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row
} from 'reactstrap';
import Logo from '../../assets/images/logo.png';
import Mailbox from '../../assets/images/mailbox.png';
import { useTriggerPasswordResetMutation } from './__generated__/graphql';
import { AddOnType, Button, TextInput } from '../../components';

export const RequestPasswordReset: React.FC = () => {
  const [email, setEmail] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const [triggerPasswordReset, { loading }] = useTriggerPasswordResetMutation({
    variables: {
      email
    },
    onCompleted: () => setRequestSent(true),
    onError: () => setRequestSent(true)
  });

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const handleSubmit = () => {
    setError(null);

    if (!email.trim() || !validateEmail(email)) {
      setError('Please enter a valid email');
    } else {
      triggerPasswordReset();
    }
  };

  return (
    <Container>
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Col xl="4" lg="3" md="6">
          <Card className="p-3 pl-4 pr-4 m-0">
            <CardHeader className="text-center">
              <img src={Logo} width="200" alt="logo" className="img-fluid" />
            </CardHeader>
            <CardBody>
              {requestSent ? (
                <div>
                  <h3>Check your Email</h3>
                  <p>
                    If an account is associated with the entered email address{' '}
                    <span className="font-weight-bold">{email}</span>,
                    we&apos;ll send a password reset link your way.
                  </p>
                  <div className="text-center mb-2">
                    <img
                      src={Mailbox}
                      alt="mail"
                      className="img-fluid"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <h3>Reset Password</h3>
                  <p>Request an email reset link</p>
                  <FormGroup>
                    <TextInput
                      type="text"
                      name="email"
                      autoComplete="email"
                      placeholder="Email"
                      inputAddon={{
                        addonType: AddOnType.Prepend,
                        value: <Mail color="#606E80" size={18} />
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </FormGroup>
                  {error && (
                    <Alert color="danger" className="text-center p-2">
                      {error}
                    </Alert>
                  )}
                  <Button
                    block
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit}
                    className="mb-3"
                  >
                    Reset Password
                  </Button>
                </>
              )}
              <div className="mt-3 text-center">
                <p className="d-inline">Remember your password?</p>
                <a href="/login">
                  <u>
                    <p className="d-inline text-primary pl-1">Login</p>
                  </u>
                </a>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
