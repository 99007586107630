import React from 'react';
import voca from 'voca';
import moment from 'moment-timezone';
import { Row, Col, FormGroup, CardBody, Card } from 'reactstrap';
import { GetAccountInformationQuery } from './__generated__/graphql';

type CompanyInformationCardProps = {
  company: GetAccountInformationQuery['currentUser']['company'];
};

export const CompanyInformationCard: React.FC<CompanyInformationCardProps> = ({
  company
}) => {
  const formatMemberSince = (memberSince: string | null) =>
    memberSince ? moment(memberSince).format('MMMM Do, YYYY') : '';

  const fields = [
    { label: 'Name', value: voca.capitalize(company.name) },
    {
      label: 'Plan',
      value: `${company.membership.name} ${company.membership.emoji}`
    },
    { label: 'Users', value: company.activeUserCount.toString() },
    {
      label: 'Joined',
      value: formatMemberSince(company.memberSince)
    }
  ].filter(({ value }) => value);

  return (
    <>
      <h2>Company Information</h2>
      <Card className="flex-fill">
        <CardBody>
          <Row>
            {fields.map(({ label, value }) => (
              <Col xs="12" key={label}>
                <FormGroup>
                  <div className="h4">{label}</div>
                  <h5 className="text-muted font-weight-normal">{value}</h5>
                </FormGroup>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
