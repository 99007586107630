import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessUsersQuery = { getUsers: Array<{ id: string, firstName: string, lastName: string, email: string, phoneNumber: string | null, title: string | null, role: Types.UserRole, deactivatedAt: string | null, company: { id: string, name: string } }> };

export type GetBusinessUserQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetBusinessUserQuery = { getUser: { id: string, firstName: string, lastName: string, email: string, phoneNumber: string | null, title: string | null, role: Types.UserRole, birthdate: string | null, companyId: string, deactivatedAt: string | null, company: { id: string, name: string, deactivatedAt: string | null } } };

export type CreateBusinessUserMutationVariables = Types.Exact<{
  data: Types.CreateUserInput;
}>;


export type CreateBusinessUserMutation = { createUser: { id: string } };

export type UpdateBusinessUserMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateUserInput;
}>;


export type UpdateBusinessUserMutation = { updateUser: { id: string } };

export type ActivateBusinessUserMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ActivateBusinessUserMutation = { activateUser: { id: string } };

export type DeactivateBusinessUserMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeactivateBusinessUserMutation = { deactivateUser: { id: string } };

export type SendPasswordResetMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type SendPasswordResetMutation = { resetPassword: string };


export const GetBusinessUsersDocument = gql`
    query GetBusinessUsers {
  getUsers(includeInactive: true) {
    id
    firstName
    lastName
    email
    phoneNumber
    title
    role
    company {
      id
      name
    }
    deactivatedAt
  }
}
    `;

/**
 * __useGetBusinessUsersQuery__
 *
 * To run a query within a React component, call `useGetBusinessUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessUsersQuery, GetBusinessUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessUsersQuery, GetBusinessUsersQueryVariables>(GetBusinessUsersDocument, options);
      }
export function useGetBusinessUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessUsersQuery, GetBusinessUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessUsersQuery, GetBusinessUsersQueryVariables>(GetBusinessUsersDocument, options);
        }
export type GetBusinessUsersQueryHookResult = ReturnType<typeof useGetBusinessUsersQuery>;
export type GetBusinessUsersLazyQueryHookResult = ReturnType<typeof useGetBusinessUsersLazyQuery>;
export type GetBusinessUsersQueryResult = Apollo.QueryResult<GetBusinessUsersQuery, GetBusinessUsersQueryVariables>;
export const GetBusinessUserDocument = gql`
    query GetBusinessUser($id: UUID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    email
    phoneNumber
    title
    role
    birthdate
    companyId
    deactivatedAt
    company {
      id
      name
      deactivatedAt
    }
  }
}
    `;

/**
 * __useGetBusinessUserQuery__
 *
 * To run a query within a React component, call `useGetBusinessUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessUserQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessUserQuery, GetBusinessUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessUserQuery, GetBusinessUserQueryVariables>(GetBusinessUserDocument, options);
      }
export function useGetBusinessUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessUserQuery, GetBusinessUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessUserQuery, GetBusinessUserQueryVariables>(GetBusinessUserDocument, options);
        }
export type GetBusinessUserQueryHookResult = ReturnType<typeof useGetBusinessUserQuery>;
export type GetBusinessUserLazyQueryHookResult = ReturnType<typeof useGetBusinessUserLazyQuery>;
export type GetBusinessUserQueryResult = Apollo.QueryResult<GetBusinessUserQuery, GetBusinessUserQueryVariables>;
export const CreateBusinessUserDocument = gql`
    mutation CreateBusinessUser($data: CreateUserInput!) {
  createUser(data: $data) {
    id
  }
}
    `;
export type CreateBusinessUserMutationFn = Apollo.MutationFunction<CreateBusinessUserMutation, CreateBusinessUserMutationVariables>;

/**
 * __useCreateBusinessUserMutation__
 *
 * To run a mutation, you first call `useCreateBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessUserMutation, { data, loading, error }] = useCreateBusinessUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessUserMutation, CreateBusinessUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessUserMutation, CreateBusinessUserMutationVariables>(CreateBusinessUserDocument, options);
      }
export type CreateBusinessUserMutationHookResult = ReturnType<typeof useCreateBusinessUserMutation>;
export type CreateBusinessUserMutationResult = Apollo.MutationResult<CreateBusinessUserMutation>;
export type CreateBusinessUserMutationOptions = Apollo.BaseMutationOptions<CreateBusinessUserMutation, CreateBusinessUserMutationVariables>;
export const UpdateBusinessUserDocument = gql`
    mutation UpdateBusinessUser($id: UUID!, $data: UpdateUserInput!) {
  updateUser(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateBusinessUserMutationFn = Apollo.MutationFunction<UpdateBusinessUserMutation, UpdateBusinessUserMutationVariables>;

/**
 * __useUpdateBusinessUserMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserMutation, { data, loading, error }] = useUpdateBusinessUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserMutation, UpdateBusinessUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessUserMutation, UpdateBusinessUserMutationVariables>(UpdateBusinessUserDocument, options);
      }
export type UpdateBusinessUserMutationHookResult = ReturnType<typeof useUpdateBusinessUserMutation>;
export type UpdateBusinessUserMutationResult = Apollo.MutationResult<UpdateBusinessUserMutation>;
export type UpdateBusinessUserMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserMutation, UpdateBusinessUserMutationVariables>;
export const ActivateBusinessUserDocument = gql`
    mutation ActivateBusinessUser($id: UUID!) {
  activateUser(id: $id) {
    id
  }
}
    `;
export type ActivateBusinessUserMutationFn = Apollo.MutationFunction<ActivateBusinessUserMutation, ActivateBusinessUserMutationVariables>;

/**
 * __useActivateBusinessUserMutation__
 *
 * To run a mutation, you first call `useActivateBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBusinessUserMutation, { data, loading, error }] = useActivateBusinessUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBusinessUserMutation, ActivateBusinessUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBusinessUserMutation, ActivateBusinessUserMutationVariables>(ActivateBusinessUserDocument, options);
      }
export type ActivateBusinessUserMutationHookResult = ReturnType<typeof useActivateBusinessUserMutation>;
export type ActivateBusinessUserMutationResult = Apollo.MutationResult<ActivateBusinessUserMutation>;
export type ActivateBusinessUserMutationOptions = Apollo.BaseMutationOptions<ActivateBusinessUserMutation, ActivateBusinessUserMutationVariables>;
export const DeactivateBusinessUserDocument = gql`
    mutation DeactivateBusinessUser($id: UUID!) {
  deactivateUser(id: $id) {
    id
  }
}
    `;
export type DeactivateBusinessUserMutationFn = Apollo.MutationFunction<DeactivateBusinessUserMutation, DeactivateBusinessUserMutationVariables>;

/**
 * __useDeactivateBusinessUserMutation__
 *
 * To run a mutation, you first call `useDeactivateBusinessUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateBusinessUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateBusinessUserMutation, { data, loading, error }] = useDeactivateBusinessUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateBusinessUserMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateBusinessUserMutation, DeactivateBusinessUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateBusinessUserMutation, DeactivateBusinessUserMutationVariables>(DeactivateBusinessUserDocument, options);
      }
export type DeactivateBusinessUserMutationHookResult = ReturnType<typeof useDeactivateBusinessUserMutation>;
export type DeactivateBusinessUserMutationResult = Apollo.MutationResult<DeactivateBusinessUserMutation>;
export type DeactivateBusinessUserMutationOptions = Apollo.BaseMutationOptions<DeactivateBusinessUserMutation, DeactivateBusinessUserMutationVariables>;
export const SendPasswordResetDocument = gql`
    mutation SendPasswordReset($email: String!) {
  resetPassword(email: $email)
}
    `;
export type SendPasswordResetMutationFn = Apollo.MutationFunction<SendPasswordResetMutation, SendPasswordResetMutationVariables>;

/**
 * __useSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetMutation, { data, loading, error }] = useSendPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetMutation, SendPasswordResetMutationVariables>(SendPasswordResetDocument, options);
      }
export type SendPasswordResetMutationHookResult = ReturnType<typeof useSendPasswordResetMutation>;
export type SendPasswordResetMutationResult = Apollo.MutationResult<SendPasswordResetMutation>;
export type SendPasswordResetMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetMutation, SendPasswordResetMutationVariables>;