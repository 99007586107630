import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyForLogQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyForLogQuery = { getCompany: { id: string, name: string, email: string | null, phoneNumber: string | null, faxNumber: string | null, tollNumber: string | null, websiteUrl: string | null, notes: string | null, intake: string | null, training: string | null, memberSince: string | null, activeAlertCount: number, deactivatedAt: string | null, locations: Array<{ id: string, addressLine1: string | null, addressLine2: string | null, addressCity: string | null, addressState: string | null, addressZip: string | null, isPrimary: boolean }>, users: Array<{ id: string, firstName: string, lastName: string, email: string, phoneNumber: string | null, title: string | null, deactivatedAt: string | null }> } };

export type GetInteractionHistoryQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
}>;


export type GetInteractionHistoryQuery = { getInteractionHistory: { firstName: string | null, lastName: string | null, email: string | null, isNameVerified: boolean, logs: Array<{ id: string, status: Types.LogStatus, type: Types.LogType | null, notes: string | null, createdAt: string, author: { id: string, firstName: string, lastName: string }, company: { id: string, name: string } | null }> } };


export const GetCompanyForLogDocument = gql`
    query GetCompanyForLog($id: UUID!) {
  getCompany(id: $id) {
    id
    name
    email
    phoneNumber
    faxNumber
    tollNumber
    websiteUrl
    notes
    intake
    training
    memberSince
    activeAlertCount
    deactivatedAt
    locations {
      id
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      isPrimary
    }
    users {
      id
      firstName
      lastName
      email
      phoneNumber
      title
      deactivatedAt
    }
  }
}
    `;

/**
 * __useGetCompanyForLogQuery__
 *
 * To run a query within a React component, call `useGetCompanyForLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyForLogQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyForLogQuery, GetCompanyForLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyForLogQuery, GetCompanyForLogQueryVariables>(GetCompanyForLogDocument, options);
      }
export function useGetCompanyForLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyForLogQuery, GetCompanyForLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyForLogQuery, GetCompanyForLogQueryVariables>(GetCompanyForLogDocument, options);
        }
export type GetCompanyForLogQueryHookResult = ReturnType<typeof useGetCompanyForLogQuery>;
export type GetCompanyForLogLazyQueryHookResult = ReturnType<typeof useGetCompanyForLogLazyQuery>;
export type GetCompanyForLogQueryResult = Apollo.QueryResult<GetCompanyForLogQuery, GetCompanyForLogQueryVariables>;
export const GetInteractionHistoryDocument = gql`
    query GetInteractionHistory($phoneNumber: String!) {
  getInteractionHistory(phoneNumber: $phoneNumber) {
    firstName
    lastName
    email
    isNameVerified
    logs {
      id
      status
      type
      notes
      author {
        id
        firstName
        lastName
      }
      company {
        id
        name
      }
      createdAt
    }
  }
}
    `;

/**
 * __useGetInteractionHistoryQuery__
 *
 * To run a query within a React component, call `useGetInteractionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInteractionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInteractionHistoryQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useGetInteractionHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetInteractionHistoryQuery, GetInteractionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInteractionHistoryQuery, GetInteractionHistoryQueryVariables>(GetInteractionHistoryDocument, options);
      }
export function useGetInteractionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInteractionHistoryQuery, GetInteractionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInteractionHistoryQuery, GetInteractionHistoryQueryVariables>(GetInteractionHistoryDocument, options);
        }
export type GetInteractionHistoryQueryHookResult = ReturnType<typeof useGetInteractionHistoryQuery>;
export type GetInteractionHistoryLazyQueryHookResult = ReturnType<typeof useGetInteractionHistoryLazyQuery>;
export type GetInteractionHistoryQueryResult = Apollo.QueryResult<GetInteractionHistoryQuery, GetInteractionHistoryQueryVariables>;