import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  GetCompanyForLogQuery,
  GetInteractionHistoryQuery,
  useGetCompanyForLogLazyQuery,
  useGetInteractionHistoryLazyQuery
} from './__generated__/graphql';

type LogProviderProps = {
  children: React.ReactNode;
};

export type LogContext = {
  logId: string | null;
  setLogId: (logId: string | null) => void;
  companyId: string | null;
  setCompanyId: (companyId: string | null) => void;
  company?: GetCompanyForLogQuery['getCompany'] | null;
  phoneNumber: string | null;
  setPhoneNumber: (phoneNumber: string | null) => void;
  interactionHistory?:
    | GetInteractionHistoryQuery['getInteractionHistory']
    | null;
  loadingInteractionHistory: boolean;
};

const initialContext: LogContext = {
  logId: null,
  setLogId: () => {},
  companyId: null,
  setCompanyId: () => {},
  company: null,
  phoneNumber: null,
  setPhoneNumber: () => {},
  interactionHistory: null,
  loadingInteractionHistory: false
};

const LogContext = createContext<LogContext>(initialContext);

export const LogProvider: React.FC<LogProviderProps> = ({ children }) => {
  const [logId, setLogId] = useState<string | null>(null);

  const [companyId, setCompanyId] = useState<string | null>(null);
  const [company, setCompany] = useState<
    GetCompanyForLogQuery['getCompany'] | null
  >();

  const [getCompany] = useGetCompanyForLogLazyQuery({
    onCompleted: (data) => setCompany(data.getCompany)
  });

  useEffect(() => {
    if (companyId) {
      getCompany({
        variables: {
          id: companyId
        }
      });
    }
  }, [companyId]);

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [interactionHistory, setInteractionHistory] = useState<
    GetInteractionHistoryQuery['getInteractionHistory'] | null
  >();

  const [getInteractionHistory, { loading: loadingInteractionHistory }] =
    useGetInteractionHistoryLazyQuery({
      onCompleted: (data) => setInteractionHistory(data.getInteractionHistory)
    });

  useEffect(() => {
    if (phoneNumber) {
      getInteractionHistory({
        variables: {
          phoneNumber
        }
      });
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (!companyId) {
      setCompany(null);
    }
  }, [companyId]);

  useEffect(() => {
    if (!logId) {
      setCompanyId(null);
      setPhoneNumber(null);
      setInteractionHistory(null);
      setCompany(null);
    }
  }, [logId]);

  const state: LogContext = {
    logId,
    setLogId,
    companyId,
    setCompanyId,
    company,
    phoneNumber,
    setPhoneNumber,
    interactionHistory,
    loadingInteractionHistory
  };

  return <LogContext.Provider value={state}>{children}</LogContext.Provider>;
};

export const useLogContext = () => useContext(LogContext);
