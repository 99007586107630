import { ToastOptions, toast } from 'react-toastify';

export enum NotificationSeverity {
  Success = 'Success',
  Error = 'Error'
}

export type NotificationOptions = {
  message: string;
  options?: ToastOptions;
  severity: NotificationSeverity;
};

export const notify = ({ message, options, severity }: NotificationOptions) => {
  if (severity === NotificationSeverity.Success) {
    toast.success(message, {
      className: 'bg-primary',
      ...options
    });
  } else if (severity === NotificationSeverity.Error) {
    toast.error(message, {
      className: 'bg-danger',
      ...options
    });
  }
};

export const showErrorToast = (message: string) => {
  notify({ message, severity: NotificationSeverity.Error });
};

export const showSuccessToast = (message: string) => {
  notify({ message, severity: NotificationSeverity.Success });
};
