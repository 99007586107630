import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  GetBusinessHoursSettingsQuery,
  useGetBusinessHoursSettingsQuery,
  useUpdateBusinessHoursMutation
} from './__generated__/graphql';
import { Button, Pulse, TimeInput, Toggle } from '../../../components';
import {
  UpdateBusinessHourInput,
  namedOperations
} from '../../../__generated__/graphql';
import { pick } from 'lodash';
import { NotificationSeverity, notify } from '../../../utils/toast';

const baseBusinessHourkeys: Array<keyof UpdateBusinessHourInput> = [
  'id',
  'day',
  'openTime',
  'closeTime'
];

type BusinessHour =
  GetBusinessHoursSettingsQuery['getBusiness']['businessHours'];

export const BusinessHours: React.FC = () => {
  const [businessHours, setBusinessHours] = useState<BusinessHour>([]);

  useGetBusinessHoursSettingsQuery({
    onCompleted: (data) => setBusinessHours(data.getBusiness.businessHours)
  });

  const [updateBusinessHours, { loading: updateBusinessHoursLoading }] =
    useUpdateBusinessHoursMutation({
      refetchQueries: [namedOperations.Query.GetBusinessHoursSettings],
      onCompleted: () => {
        notify({
          message: 'Business hours successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating business hours!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleUpdateBusinessHours = () => {
    updateBusinessHours({
      variables: {
        data: businessHours.map((item) => pick(item, baseBusinessHourkeys))
      }
    });
  };

  const handleChange = (
    id: string,
    { openTime, closeTime }: { openTime: string; closeTime: string }
  ) => {
    setBusinessHours(
      businessHours.map((businessHour) => {
        if (businessHour.id === id) {
          return {
            ...businessHour,
            openTime,
            closeTime
          };
        }

        return businessHour;
      })
    );
  };

  const toggleBusinessHours = (id: string) => {
    const businessHour = businessHours.find(
      (businessHour) => businessHour.id === id
    );

    if (!businessHour) {
      throw new Error('Business hour not found');
    }

    if (
      businessHour.openTime === '00:00:00' &&
      businessHour.closeTime === '00:00:00'
    ) {
      handleChange(businessHour.id, {
        openTime: '09:00:00',
        closeTime: '17:00:00'
      });
    } else {
      handleChange(businessHour.id, {
        openTime: '00:00:00',
        closeTime: '00:00:00'
      });
    }
  };

  if (!businessHours.length) {
    return <Pulse />;
  }

  return (
    <Row>
      <Col xs="12" md="12" lg="8">
        <h2>Business Hours</h2>
        <Card>
          <CardBody>
            <Row style={{ overflow: 'scroll' }}>
              <Col>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="form-control-label">
                      Standard Business Hours
                    </div>
                    <p className="text-muted">
                      Configure the standard hours of operation for this
                      business
                    </p>
                  </div>
                </div>
                <div>
                  <table
                    style={{
                      borderSpacing: '0 1rem',
                      borderCollapse: 'separate'
                    }}
                  >
                    <tbody>
                      {businessHours.map((businessHour) => {
                        const startAt = moment(
                          businessHour.openTime,
                          'HH:mm:ss'
                        );

                        const endAt = moment(
                          businessHour.closeTime,
                          'HH:mm:ss'
                        );

                        const isClosed =
                          startAt.format('HH:mm') === '00:00' &&
                          endAt.format('HH:mm') === '00:00';

                        return (
                          <tr
                            key={businessHour.id}
                            style={{
                              backgroundColor: 'white',
                              cursor: 'default'
                            }}
                          >
                            <td className="form-control-label pr-5">
                              {businessHour.day}
                            </td>
                            <td className="pr-5">
                              <div className="d-flex align-items-center">
                                <Toggle
                                  checked={!isClosed}
                                  onChange={() =>
                                    toggleBusinessHours(businessHour.id)
                                  }
                                />
                                <div className="ml-2 text-default text-sm">
                                  {isClosed ? 'Closed' : 'Open'}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <TimeInput
                                  disabled={isClosed}
                                  selected={startAt.toDate()}
                                  onChange={(value) =>
                                    handleChange(businessHour.id, {
                                      openTime:
                                        moment(value).format('HH:mm:ss'),
                                      closeTime: businessHour.closeTime
                                    })
                                  }
                                  minTime={moment().startOf('day').toDate()}
                                  maxTime={endAt
                                    .clone()
                                    .subtract(15, 'minutes')
                                    .toDate()}
                                />
                                <div className="mx-3 text-default text-sm">
                                  to
                                </div>
                                <TimeInput
                                  disabled={isClosed}
                                  selected={endAt.toDate()}
                                  onChange={(value) =>
                                    handleChange(businessHour.id, {
                                      openTime: businessHour.openTime,
                                      closeTime:
                                        moment(value).format('HH:mm:ss')
                                    })
                                  }
                                  minTime={startAt
                                    .clone()
                                    .add(15, 'minutes')
                                    .toDate()}
                                  maxTime={moment().endOf('day').toDate()}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Button
          color="primary"
          loading={updateBusinessHoursLoading}
          onClick={handleUpdateBusinessHours}
        >
          Save Changes
        </Button>
      </Col>
    </Row>
  );
};
