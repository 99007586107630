import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { AlertTriangle } from 'react-feather';
import {
  Card,
  CardBody,
  Row,
  Col,
  Progress,
  Button,
  UncontrolledCollapse
} from 'reactstrap';
import { useGetCompanyUsageQuery } from './__generated__/graphql';
import { Pulse } from '../../core';
import classNames from 'classnames';

type PlanUsageCardProps = {
  companyId: string;
  dateRange: {
    startAt: string;
    endAt: string;
  };
  expand?: boolean;
};

export const PlanUsageCard: React.FC<PlanUsageCardProps> = ({
  companyId,
  dateRange,
  expand
}) => {
  const { data } = useGetCompanyUsageQuery({
    variables: {
      companyId,
      dateRange
    }
  });

  const companyUsage = useMemo(() => data?.getCompanyUsageReport, [data]);

  return (
    <>
      <h2>Plan Usage</h2>
      <Card
        className={`${companyUsage?.isOverLimit ? 'bg-gradient-danger' : 'bg-gradient-primary'} border-0 flex-fill`}
      >
        <CardBody>
          {!companyUsage ? (
            <Pulse />
          ) : (
            <>
              <Row>
                <Col>
                  <span className="text-white">{`${companyUsage.hoursUtilized}/${companyUsage.hoursAllocated} Hours`}</span>
                </Col>
                <Col className="text-right">
                  <small className="text-white mb-0 card-title">
                    {moment(dateRange.startAt).format('MMMM YYYY')}
                  </small>
                </Col>
              </Row>
              <Progress
                max="100"
                className="mt-3 progress-md"
                value={companyUsage.percentUtilized}
                color={companyUsage.isOverLimit ? 'danger' : 'primary'}
                animated
              />
              <Button
                id="buttonToggler"
                size="sm"
                className={classNames({ 'd-none': !!expand })}
              >
                See Details
              </Button>
              <UncontrolledCollapse
                toggler="#buttonToggler"
                defaultOpen={expand}
              >
                <div className="text-white mb-0 pt-2">
                  <hr className="bg-white my-2 mb-3" />
                  <Row className="text-center">
                    <Col xs="4">
                      <div className="h3 text-white mb-0">
                        {companyUsage.hoursAllocated}
                      </div>
                      <small>Plan Hours</small>
                    </Col>
                    <Col xs="4">
                      <div className="h3 text-white mb-0">
                        {companyUsage.hoursUtilized}
                      </div>
                      <small>Used</small>
                    </Col>
                    <Col xs="4">
                      <div className="h3 text-white mb-0">
                        {companyUsage.hoursAvailable}
                      </div>
                      <small>Remaining</small>
                    </Col>
                    {companyUsage.isOverLimit && (
                      <Col xs="12">
                        <hr className="bg-white" />
                        <small>
                          <AlertTriangle size={16} className="mr-1" />
                          {`This plan has exceeded its limit by ${companyUsage.hoursOverLimit} hours.`}
                        </small>
                      </Col>
                    )}
                  </Row>
                </div>
              </UncontrolledCollapse>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};
