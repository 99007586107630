import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetFileUploadLazyQuery } from './__generated__/graphql';

export const FileViewer = () => {
  const { businessId, fileUploadId } = useParams();
  const navigate = useNavigate();

  const [getFileUpload, { data, loading, error }] = useGetFileUploadLazyQuery();

  useEffect(() => {
    if (fileUploadId && businessId) {
      getFileUpload({
        variables: {
          businessId: businessId,
          id: fileUploadId
        }
      });
    }
  }, [fileUploadId, businessId]);

  const fileUpload = data?.getFileUpload;

  useEffect(() => {
    if (fileUpload) {
      document.title = fileUpload?.fileName;
    }
  }, [fileUpload]);

  if (error) {
    navigate('/page-not-found');
    return null;
  }

  if (!fileUpload || loading) {
    return null;
  }

  const { url } = fileUpload;

  return (
    <iframe
      src={url}
      style={{ width: '100%', height: '100vh', border: 'none' }}
    />
  );
};
