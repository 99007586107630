import { Button } from '../Button';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { useRef } from 'react';
import { FileUpload, useFileUpload } from '../../../hooks';
import { Spinner } from '../Spinner';

type FileUploadInputProps = InputWrapperProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    onCompleted?: (uploadedFiles: FileUpload[]) => void;
  };

export const FileUploadInput: React.FC<FileUploadInputProps> = ({
  label,
  helperText,
  disabled,
  onCompleted
}) => {
  const { uploadFiles, loading } = useFileUpload();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (files) {
      const uploadedFiles = await uploadFiles(files);
      onCompleted?.(uploadedFiles);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <InputWrapper label={label} helperText={helperText}>
      <div className="d-flex align-items-center">
        <Button
          color="default"
          size="sm"
          onClick={handleFileUploadClick}
          disabled={disabled}
        >
          Add Files
        </Button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
          onChange={handleFileChange}
          disabled={disabled}
        />
        {loading && <Spinner stroke="black" />}
      </div>
    </InputWrapper>
  );
};
