import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLogQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetLogQuery = { getLog: { id: string, authorId: string, companyId: string | null, notes: string | null, internalNotes: string | null, tags: Array<Types.LogTag>, type: Types.LogType | null, status: Types.LogStatus, firstName: string | null, lastName: string | null, email: string | null, phoneNumber: string | null, addressLine1: string | null, addressLine2: string | null, addressCity: string | null, addressState: string | null, addressZip: string | null, rating: number | null, appointmentAt: string | null, createdAt: string, updatedAt: string, author: { id: string, firstName: string, lastName: string }, company: { id: string, users: Array<{ id: string, deactivatedAt: string | null, notificationPreference: { id: string, logTypes: Array<Types.LogType> } }> } | null, fileUploads: Array<{ id: string, fileName: string, url: string }>, notifications: Array<{ id: string, metadata: any, timestamp: string, user: { id: string, firstName: string, lastName: string, email: string } }> } };

export type CreateLogMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateLogMutation = { createLog: { id: string } };

export type UpdateLogMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateLogInput;
}>;


export type UpdateLogMutation = { updateLog: { id: string } };

export type CompleteLogMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  notifyUserIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type CompleteLogMutation = { completeLog: { id: string } };

export type DeleteLogMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteLogMutation = { deleteLog: { id: string } };

export type CleanLogNotesMutationVariables = Types.Exact<{
  notes: Types.Scalars['String']['input'];
}>;


export type CleanLogNotesMutation = { cleanNotes: string };


export const GetLogDocument = gql`
    query GetLog($id: UUID!) {
  getLog(id: $id) {
    id
    authorId
    companyId
    notes
    internalNotes
    tags
    type
    status
    firstName
    lastName
    email
    phoneNumber
    addressLine1
    addressLine2
    addressCity
    addressState
    addressZip
    rating
    appointmentAt
    createdAt
    updatedAt
    author {
      id
      firstName
      lastName
    }
    company {
      id
      users {
        id
        notificationPreference {
          id
          logTypes
        }
        deactivatedAt
      }
    }
    fileUploads {
      id
      fileName
      url
    }
    notifications {
      id
      metadata
      timestamp
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useGetLogQuery__
 *
 * To run a query within a React component, call `useGetLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLogQuery(baseOptions: Apollo.QueryHookOptions<GetLogQuery, GetLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogQuery, GetLogQueryVariables>(GetLogDocument, options);
      }
export function useGetLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogQuery, GetLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogQuery, GetLogQueryVariables>(GetLogDocument, options);
        }
export type GetLogQueryHookResult = ReturnType<typeof useGetLogQuery>;
export type GetLogLazyQueryHookResult = ReturnType<typeof useGetLogLazyQuery>;
export type GetLogQueryResult = Apollo.QueryResult<GetLogQuery, GetLogQueryVariables>;
export const CreateLogDocument = gql`
    mutation CreateLog {
  createLog {
    id
  }
}
    `;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, options);
      }
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const UpdateLogDocument = gql`
    mutation UpdateLog($id: UUID!, $data: UpdateLogInput!) {
  updateLog(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateLogMutationFn = Apollo.MutationFunction<UpdateLogMutation, UpdateLogMutationVariables>;

/**
 * __useUpdateLogMutation__
 *
 * To run a mutation, you first call `useUpdateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLogMutation, { data, loading, error }] = useUpdateLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLogMutation, UpdateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLogMutation, UpdateLogMutationVariables>(UpdateLogDocument, options);
      }
export type UpdateLogMutationHookResult = ReturnType<typeof useUpdateLogMutation>;
export type UpdateLogMutationResult = Apollo.MutationResult<UpdateLogMutation>;
export type UpdateLogMutationOptions = Apollo.BaseMutationOptions<UpdateLogMutation, UpdateLogMutationVariables>;
export const CompleteLogDocument = gql`
    mutation CompleteLog($id: UUID!, $notifyUserIds: [UUID!]!) {
  completeLog(id: $id, notifyUserIds: $notifyUserIds) {
    id
  }
}
    `;
export type CompleteLogMutationFn = Apollo.MutationFunction<CompleteLogMutation, CompleteLogMutationVariables>;

/**
 * __useCompleteLogMutation__
 *
 * To run a mutation, you first call `useCompleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeLogMutation, { data, loading, error }] = useCompleteLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notifyUserIds: // value for 'notifyUserIds'
 *   },
 * });
 */
export function useCompleteLogMutation(baseOptions?: Apollo.MutationHookOptions<CompleteLogMutation, CompleteLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteLogMutation, CompleteLogMutationVariables>(CompleteLogDocument, options);
      }
export type CompleteLogMutationHookResult = ReturnType<typeof useCompleteLogMutation>;
export type CompleteLogMutationResult = Apollo.MutationResult<CompleteLogMutation>;
export type CompleteLogMutationOptions = Apollo.BaseMutationOptions<CompleteLogMutation, CompleteLogMutationVariables>;
export const DeleteLogDocument = gql`
    mutation DeleteLog($id: UUID!) {
  deleteLog(id: $id) {
    id
  }
}
    `;
export type DeleteLogMutationFn = Apollo.MutationFunction<DeleteLogMutation, DeleteLogMutationVariables>;

/**
 * __useDeleteLogMutation__
 *
 * To run a mutation, you first call `useDeleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogMutation, { data, loading, error }] = useDeleteLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLogMutation, DeleteLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLogMutation, DeleteLogMutationVariables>(DeleteLogDocument, options);
      }
export type DeleteLogMutationHookResult = ReturnType<typeof useDeleteLogMutation>;
export type DeleteLogMutationResult = Apollo.MutationResult<DeleteLogMutation>;
export type DeleteLogMutationOptions = Apollo.BaseMutationOptions<DeleteLogMutation, DeleteLogMutationVariables>;
export const CleanLogNotesDocument = gql`
    mutation CleanLogNotes($notes: String!) {
  cleanNotes(notes: $notes)
}
    `;
export type CleanLogNotesMutationFn = Apollo.MutationFunction<CleanLogNotesMutation, CleanLogNotesMutationVariables>;

/**
 * __useCleanLogNotesMutation__
 *
 * To run a mutation, you first call `useCleanLogNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanLogNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanLogNotesMutation, { data, loading, error }] = useCleanLogNotesMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useCleanLogNotesMutation(baseOptions?: Apollo.MutationHookOptions<CleanLogNotesMutation, CleanLogNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CleanLogNotesMutation, CleanLogNotesMutationVariables>(CleanLogNotesDocument, options);
      }
export type CleanLogNotesMutationHookResult = ReturnType<typeof useCleanLogNotesMutation>;
export type CleanLogNotesMutationResult = Apollo.MutationResult<CleanLogNotesMutation>;
export type CleanLogNotesMutationOptions = Apollo.BaseMutationOptions<CleanLogNotesMutation, CleanLogNotesMutationVariables>;