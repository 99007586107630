import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { Power } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse
} from '../../../components';
import { ResourceForm } from './ResourceForm';
import { useResourceActions } from './useResourceActions';
import { useModal } from '../../../hooks';
import { ResourceType } from '../../../__generated__/graphql';

type ResourceModalProps = {
  isOpen: boolean;
  resourceId: string | null;
  toggle: () => void;
};

export type Resource = {
  name: string;
  description: string | null;
  capacity: number;
  type: ResourceType;
};

const defaultResource: Resource = {
  name: '',
  description: null,
  capacity: 1,
  type: ResourceType.Conference
};

enum ResourceMode {
  Create = 'Create',
  Update = 'Update'
}

export const ResourceModal: React.FC<ResourceModalProps> = (props) => {
  const { isOpen, toggle, resourceId } = props;

  const [mode, setMode] = useState<ResourceMode>(ResourceMode.Create);
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isResourceActivationModalOpen,
    toggleModal: toggleResourceActivationModal
  } = useModal();

  const {
    resource,
    getResource,
    getResourceLoading,
    handleCreateResource,
    createResourceLoading,
    handleUpdateResource,
    updateResourceLoading,
    handleToggleResource,
    toggleResourceLoading
  } = useResourceActions({ toggle, toggleResourceActivationModal });

  const form = useForm<Resource>({
    defaultValues: defaultResource
  });

  useEffect(() => {
    form.reset(resource);
  }, [resource]);

  useEffect(() => {
    setLoading(createResourceLoading || updateResourceLoading);
  }, [createResourceLoading, updateResourceLoading]);

  useEffect(() => {
    if (isOpen) {
      if (resourceId) {
        setMode(ResourceMode.Update);
        getResource({
          variables: {
            id: resourceId
          }
        });
      } else {
        setMode(ResourceMode.Create);
        form.reset(defaultResource);
      }
    }
  }, [resourceId, isOpen]);

  const handleSubmit = (data: Resource) => {
    if (mode === ResourceMode.Update) {
      handleUpdateResource(data);
    } else {
      handleCreateResource(data);
    }
  };

  const menuItems = [
    {
      icon: <Power size={14} />,
      label: resource?.deactivatedAt ? 'Activate' : 'Deactivate',
      onClick: toggleResourceActivationModal
    }
  ];

  const isResourceActive = useMemo(
    () => !resource?.deactivatedAt,
    [isResourceActivationModalOpen]
  );

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isResourceActivationModalOpen}
        toggle={toggleResourceActivationModal}
        title={isResourceActive ? 'Deactivate Resource' : 'Activate Resource'}
        body={
          <p>
            {`You are about to ${isResourceActive ? 'deactivate' : 'activate'} `}
            <span className="font-weight-bold text-default">
              {resource?.name}
            </span>
            {'. '}
            {isResourceActive
              ? 'This resource will no longer be bookable for appointments.'
              : 'This resource will be available to book for appointments.'}{' '}
            Are you sure you want to do this?
          </p>
        }
        loading={toggleResourceLoading}
        confirmationText={
          isResourceActive ? 'Yes, deactivate' : 'Yes, activate'
        }
        onConfirm={handleToggleResource}
      />
      <Modal toggle={!loading ? toggle : () => null} isOpen={isOpen} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="editResource">
            {`${mode} Resource`}
          </h5>
          {resource && mode === ResourceMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getResourceLoading ? (
          <Pulse />
        ) : (
          <>
            <ResourceForm form={form} />
            <ModalFooter>
              <Button disabled={loading} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                loading={loading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {mode}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
