import { pick } from 'lodash';
import {
  CreateUserInput,
  UpdateUserInput,
  namedOperations
} from '../../../__generated__/graphql';
import { NotificationSeverity, notify } from '../../../utils/toast';
import {
  useActivateBusinessUserMutation,
  useCreateBusinessUserMutation,
  useDeactivateBusinessUserMutation,
  useGetBusinessUserLazyQuery,
  useSendPasswordResetMutation,
  useUpdateBusinessUserMutation
} from './__generated__/graphql';
import { useMemo } from 'react';
import { User } from './UserModal';

type UseUserActionsOptions = {
  toggle: () => void;
  toggleUserActivationModal: () => void;
};

const baseUserKeys: Array<keyof CreateUserInput & keyof UpdateUserInput> = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'birthdate',
  'title',
  'role'
];

const createUserKeys: Array<keyof CreateUserInput> = [
  ...baseUserKeys,
  'companyId'
];

const updateUserKeys: Array<keyof UpdateUserInput> = baseUserKeys;

export const useUserActions = ({
  toggle,
  toggleUserActivationModal
}: UseUserActionsOptions) => {
  const [getUser, { data: getUserData, loading: getUserLoading }] =
    useGetBusinessUserLazyQuery();

  const user = useMemo(() => getUserData?.getUser, [getUserData]);

  const [createUser, { loading: createUserLoading }] =
    useCreateBusinessUserMutation({
      refetchQueries: [namedOperations.Query.GetBusinessUsers],
      onCompleted: () => {
        toggle();
        notify({
          message: 'User successfully created!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error creating user!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [updateUser, { loading: updateUserLoading }] =
    useUpdateBusinessUserMutation({
      refetchQueries: [namedOperations.Query.GetBusinessUsers],
      onCompleted: () => {
        toggle();
        notify({
          message: 'User successfully updated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error updating user!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [activateUser, { loading: activateUserLoading }] =
    useActivateBusinessUserMutation({
      refetchQueries: [namedOperations.Query.GetBusinessUsers],
      onCompleted: () => {
        toggleUserActivationModal();
        toggle();
        notify({
          message: 'User successfully activated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error activating user!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [deactivateUser, { loading: deactivateUserLoading }] =
    useDeactivateBusinessUserMutation({
      refetchQueries: [namedOperations.Query.GetBusinessUsers],
      onCompleted: () => {
        toggleUserActivationModal();
        toggle();
        notify({
          message: 'User successfully deactivated!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deactivating user!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const [sendPasswordReset] = useSendPasswordResetMutation({
    onCompleted: () => {
      notify({
        message: 'Password reset successfully sent!',
        severity: NotificationSeverity.Success
      });
    },
    onError: () => {
      notify({
        message: 'Error sending password reset!',
        severity: NotificationSeverity.Error
      });
    }
  });

  const handleCreateUser = async (data: User) => {
    await createUser({
      variables: { data: pick(data, createUserKeys) }
    });
  };

  const handleUpdateUser = async (data: User) => {
    if (user) {
      await updateUser({
        variables: { id: user.id, data: pick(data, updateUserKeys) }
      });
    }
  };

  const handleToggleUser = async () => {
    if (user) {
      const options = {
        variables: {
          id: user.id
        }
      };

      if (user.deactivatedAt) {
        await activateUser(options);
      } else {
        await deactivateUser(options);
      }
    }
  };

  const handleSendPasswordReset = async () => {
    if (user) {
      await sendPasswordReset({ variables: { email: user.email } });
    }
  };

  return {
    user: getUserData?.getUser,
    getUser,
    getUserLoading,
    handleCreateUser,
    createUserLoading,
    handleUpdateUser,
    updateUserLoading,
    handleToggleUser,
    toggleUserLoading: activateUserLoading || deactivateUserLoading,
    handleSendPasswordReset
  };
};
