import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

export const Logout: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('x-access-token');
  }, []);

  return (
    <Container fluid>
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Col lg="3">
          <Card>
            <CardHeader className="text-center">
              <h3 className="mb-0">Log Out Successful!</h3>
            </CardHeader>
            <CardBody>
              <Link to="/login">
                <Button color="primary" block>
                  Login
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
