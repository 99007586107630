import React from 'react';
import { InputGroupAddon, InputGroupText } from 'reactstrap';
import { Icon } from 'react-feather';

export enum AddOnType {
  Prepend = 'prepend',
  Append = 'append'
}

export type InputAddonProps = {
  addonType: AddOnType;
  value: React.ReactElement<Icon> | string;
};

export const InputAddon: React.FC<InputAddonProps> = ({ addonType, value }) => (
  <InputGroupAddon addonType={addonType} className="h5 mb-0">
    <InputGroupText>{value}</InputGroupText>
  </InputGroupAddon>
);
