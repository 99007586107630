import React from 'react';
import { Quill } from '../../../../components';
import { useLogContext } from '../../../../contexts';

export const CompanyIntakeTab: React.FC = () => {
  const { company } = useLogContext();

  if (!company?.intake) {
    return (
      <p className="mb-0">No intake information found for this company.</p>
    );
  }

  return (
    <Quill className="quill-disabled ql-h100" value={company.intake} readOnly />
  );
};
