import React, { useEffect, useState } from 'react';
import { Check } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import moment from 'moment-timezone';
import { useGetDashboardAppointmentsLazyQuery } from './__generated__/graphql';
import { Pulse } from '../../components';
import {
  AppointmentField,
  GetAppointmentsOptions,
  SortDirection
} from '../../__generated__/graphql';
import { useUser } from '../../hooks';

enum AppointmentStatus {
  Today = 'Today',
  Future = 'Future',
  Past = 'Past'
}

const defaultAppointmentOptions: GetAppointmentsOptions = {
  orderBy: {
    field: AppointmentField.StartAt,
    sort: SortDirection.Asc
  },
  pagination: {
    take: 5
  },
  includeCancelled: false
};

const appointmentOptions: Record<AppointmentStatus, GetAppointmentsOptions> = {
  [AppointmentStatus.Today]: {
    dateRange: {
      startAt: moment().startOf('day').toISOString(),
      endAt: moment().endOf('day').toISOString()
    }
  },
  [AppointmentStatus.Future]: {
    dateRange: {
      startAt: moment().toISOString(),
      endAt: '9999-12-31T23:59:59.000Z'
    }
  },
  [AppointmentStatus.Past]: {
    dateRange: {
      startAt: '0000-01-01T00:00:00.000Z',
      endAt: moment().toISOString()
    },
    orderBy: {
      field: AppointmentField.StartAt,
      sort: SortDirection.Desc
    }
  }
};

export const AppointmentsCard: React.FC = () => {
  const [status, setStatus] = useState<AppointmentStatus>(
    AppointmentStatus.Today
  );

  const user = useUser();

  const [getAppointments, { data, loading }] =
    useGetDashboardAppointmentsLazyQuery();

  useEffect(() => {
    if (user) {
      getAppointments({
        variables: {
          options: {
            ...defaultAppointmentOptions,
            ...appointmentOptions[status],
            filters: {
              companyId: user.company.id
            }
          }
        }
      });
    }
  }, [status, user]);

  const appointments = data?.getAppointments;

  return (
    <>
      <h2>Appointments</h2>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <CardTitle className="h3 mb-0">
                <UncontrolledDropdown group>
                  <DropdownToggle caret color="primary" size="sm">
                    {status}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.entries(AppointmentStatus).map(([key, value]) => (
                      <DropdownItem
                        key={key}
                        className={
                          key === status ? 'bg-primary text-white' : ''
                        }
                        onClick={() => setStatus(value)}
                      >
                        {value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardTitle>
            </Col>
            <Col className="text-right">
              {status === AppointmentStatus.Today && (
                <small className="text-muted mb-0 card-title">
                  {moment().format('dddd, MMMM Do')}
                </small>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="overflow-hidden card-scroll">
          {loading && <Pulse />}
          {!loading && !appointments?.length && (
            <div>
              <h4 className="text-center text-sm text-muted font-weight-normal mb-4">
                It looks like you have no appointments, let&apos;s change that
                <span role="img" aria-label="waving emoji">
                  {' '}
                  👋🏻
                </span>
              </h4>
              <Link to="/schedule/search">
                <Button color="primary" block>
                  Book Appointment
                </Button>
              </Link>
            </div>
          )}
          <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
          >
            {!loading &&
              appointments?.map((appointment) => (
                <div className="timeline-block" key={appointment.id}>
                  <span className="timeline-step badge-primary">
                    {appointment.status === 'COMPLETED' && <Check size={14} />}
                  </span>
                  <div className="timeline-content">
                    <h4>{appointment.resource.name}</h4>
                    <h5 className="font-weight-bold mb-0">
                      {moment(appointment.startAt).format('dddd, MMMM Do')}
                      <br />
                    </h5>
                    <h5 className="text-muted font-weight-normal">
                      {`
                        ${moment(appointment.startAt).format('h:mm a')}
                        –
                        ${moment(appointment.endAt).format('h:mm a')}
                      `}
                    </h5>
                    {appointment.notes && (
                      <div className="h5">
                        <span className="font-weight-bold text-default">
                          Notes:{' '}
                        </span>
                        <span className="hmb-0 text-muted font-weight-normal">
                          {appointment.notes}
                        </span>
                      </div>
                    )}
                    <div className="mt-2">
                      <span className="badge badge-primary badge-pill text-capitalize font-weight-normal">
                        {`${appointment.user.firstName} ${appointment.user.lastName}`}
                      </span>
                      {appointment.status === 'INPROGRESS' && (
                        <span className="ml-2 badge badge-default badge-pill text-capitalize font-weight-normal">
                          In Progress
                        </span>
                      )}
                      {appointment.status === 'COMPLETED' && (
                        <span className="ml-2 badge badge-success badge-pill text-capitalize font-weight-normal">
                          Complete
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
