import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import { Lock, Mail } from 'react-feather';
import { useLoginMutation } from './__generated__/graphql';
import Logo from '../../assets/images/logo.png';

import './style.scss';
import { ApolloError } from '@apollo/client';
import { Button } from '../../components';

export const Login: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('login-background');
    return () => document.body.classList.remove('login-background');
  }, []);

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const [authenticate, { loading }] = useLoginMutation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setError(null);

    try {
      const { data } = await authenticate({
        variables: formData
      });

      if (data?.login) {
        localStorage.setItem('x-access-token', data.login.accessToken);
        navigate('/dashboard');
      } else {
        setError('An unexpected error occurred.');
      }
    } catch (error) {
      const errorMessage =
        error instanceof ApolloError
          ? error.message
          : 'An unexpected error occurred.';
      setError(errorMessage);
    }
  };

  return (
    <Container>
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Col xl="4" lg="5" md="6" sm="9" xs="12">
          <Card className="p-3 pl-4 pr-4 m-0">
            <CardHeader className="text-center">
              <img src={Logo} width="200" alt="logo" className="img-fluid" />
            </CardHeader>
            <CardBody>
              <h3>Account Login</h3>
              <p>Enter your credentials to get started...</p>
              <Form id="login-form" onSubmit={handleSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Mail color="#606E80" size={18} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="email"
                      autoComplete="email"
                      placeholder="Email"
                      onChange={handleInputChange}
                      value={formData.email}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Lock color="#606E80" size={18} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      onChange={handleInputChange}
                      value={formData.password}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <div className="text-center">
                    {error && (
                      <Alert color="danger font-weight-bold">{error}</Alert>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="text-center mb-2">
                  <Button
                    id="login-button"
                    color="primary"
                    block
                    loading={loading}
                  >
                    Login
                  </Button>
                </FormGroup>
              </Form>
              <div className="mt-3 text-center">
                <p className="d-inline">Forgot your password?</p>
                <a href="/reset-password">
                  <u>
                    <p className="d-inline text-primary pl-1">Reset Password</p>
                  </u>
                </a>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
