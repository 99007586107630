import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';
import { Button } from 'reactstrap';
import {
  GetBusinessBlockoutsQuery,
  useDeleteBusinessBlockoutMutation,
  useGetBusinessBlockoutsQuery
} from './__generated__/graphql';
import { ConfirmationModal, ModalType, Table } from '../../../components';
import { useModal } from '../../../hooks';
import { BlockoutModal } from './BlockoutModal';
import { format, parseISO } from 'date-fns';
import { NotificationSeverity, notify } from '../../../utils/toast';
import { namedOperations } from '../../../__generated__/graphql';
import { Trash2 } from 'react-feather';

type Blockout = GetBusinessBlockoutsQuery['getBlockouts'][0];

export const Blockouts: React.FC = () => {
  const [blockoutId, setBlockoutId] = useState<string | null>(null);
  const [blockouts, setBlockouts] = useState<Blockout[]>([]);

  const { loading } = useGetBusinessBlockoutsQuery({
    onCompleted: (data) => setBlockouts(data.getBlockouts)
  });

  const { isOpen: isBlockoutModalOpen, toggleModal: toggleBlockoutModal } =
    useModal();

  const {
    isOpen: isDeleteBlockoutModalOpen,
    toggleModal: toggleDeleteBlockoutModal
  } = useModal();

  useEffect(() => {
    if (!isDeleteBlockoutModalOpen) {
      setBlockoutId(null);
    }
  }, [isDeleteBlockoutModalOpen]);

  const columns: Column<Blockout>[] = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value: date }) => (
        <span>{format(parseISO(date), 'EEEE, MMMM do, yyyy')}</span>
      )
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => {
        const handleClick = () => {
          setBlockoutId(value);
          toggleDeleteBlockoutModal();
        };

        return (
          <Button size="sm" color="danger" onClick={handleClick}>
            <Trash2 size={14} />
          </Button>
        );
      }
    }
  ];

  const [deleteBlockout, { loading: deleteBlockoutLoading }] =
    useDeleteBusinessBlockoutMutation({
      refetchQueries: [namedOperations.Query.GetBusinessBlockouts],
      onCompleted: () => {
        toggleDeleteBlockoutModal();
        notify({
          message: 'Blockout successfully deleted!',
          severity: NotificationSeverity.Success
        });
      },
      onError: () => {
        notify({
          message: 'Error deleting blockout!',
          severity: NotificationSeverity.Error
        });
      }
    });

  const handleCreate = () => {
    toggleBlockoutModal();
  };

  const handleDelete = async () => {
    if (blockoutId) {
      deleteBlockout({
        variables: {
          id: blockoutId
        }
      });

      setBlockoutId(null);
    }
  };

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isDeleteBlockoutModalOpen}
        toggle={toggleDeleteBlockoutModal}
        title="Delete Blockout"
        body={
          <p>
            Are you sure you want to delete this blockout? This action can not
            be reversed.
          </p>
        }
        loading={deleteBlockoutLoading}
        confirmationText="Yes, delete"
        onConfirm={handleDelete}
      />
      <BlockoutModal
        isOpen={isBlockoutModalOpen}
        toggle={toggleBlockoutModal}
      />
      <Table<Blockout>
        loading={loading}
        data={blockouts}
        columns={columns}
        disableHover
        sortBy={{
          id: 'date',
          desc: false
        }}
      >
        <Button
          size="sm"
          color="default"
          className="ml-2"
          onClick={handleCreate}
        >
          Create
        </Button>
      </Table>
    </>
  );
};
