/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { Option } from '../../../types';

export type SelectInputProps = InputWrapperProps &
  Pick<
    StateManagerProps,
    'isMulti' | 'isDisabled' | 'placeholder' | 'isSearchable' | 'onKeyDown'
  > & {
    options: Option[];
    value?: any;
    onChange: (value: any) => void;
  };

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  helperText,
  error,
  options,
  value,
  onChange,
  isMulti,
  ...props
}) => {
  const selectedOptions = isMulti
    ? options.filter((option) => value?.includes(option.value))
    : options.find((option) => option.value === value);

  const handleChange = (
    option: SingleValue<Option> | MultiValue<Option> | null
  ) => {
    if (!option) {
      onChange(null);
    }

    if (isMulti) {
      onChange((option as MultiValue<Option>).map((item) => item.value));
    } else {
      option && 'value' in option && onChange(option.value);
    }
  };

  return (
    <InputWrapper label={label} helperText={helperText} error={error}>
      <ReactSelectInput
        value={selectedOptions}
        options={options}
        onChange={handleChange}
        isMulti={isMulti}
        {...props}
      />
    </InputWrapper>
  );
};
const ReactSelectInput: React.FC<StateManagerProps<Option>> = (props) => {
  return (
    <Select
      isClearable
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        ClearIndicator: () => null
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          border: 0,
          boxShadow:
            '0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 0 rgba(0, 0, 0, 0.05)',
          cursor: 'pointer',
          fontSize: '0.7875rem;',
          padding: '0.625rem 0.75rem',
          height: props.isMulti ? '' : 'calc(1.125em + 0.9375rem + 7px)',
          minHeight: 'calc(1.125em + 0.9375rem + 7px)',
          alignItems: 'start',
          backgroundColor: props.isDisabled ? '#e9ecef !important' : '',
          opacity: props.isDisabled ? '1 !important' : '',
          ...(props.isMulti && {
            padding: 0,
            margin: 0,
            paddingLeft: 10,
            paddingRight: 10
          })
        }),
        multiValue: (provided) => ({
          ...provided,
          marginTop: 7,
          backgroundColor: '#5e72e4',
          borderRadius: '0.35rem'
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          paddingLeft: 9,
          paddingRight: 0,
          color: 'white'
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: 'white',
          ':hover': {
            backgroundColor: 'inherit',
            borderRadius: '0.35rem'
          }
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#606E80',
          marginTop: '-2.5px',
          margin: 0,
          padding: 0
        }),
        valueContainer: (provided) => ({
          ...provided,
          margin: 0,
          marginTop: '0px',
          marginBottom: props.isMulti ? '7px' : '0px',
          padding: 0
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          margin: '-8px'
        }),
        input: (provided) => ({
          ...provided,
          color: '#606E80',
          margin: 0,
          padding: 0,
          fontSize: '0.7875rem;',
          ...(props.isMulti && {
            marginTop: 9,
            caretColor: 'transparent'
          })
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#adb5bd',
          margin: 0,
          padding: 0,
          ...(props.isMulti && {
            marginTop: 9
          })
        }),
        menuList: (provided) => ({
          ...provided,
          borderRadius: '0.35rem',
          maxHeight: 180
        }),
        menu: (provided) => ({
          ...provided,
          border: 0,
          boxShadow: '2px 1px 15px -2px rgba(0,0,0,0.24)',
          borderRadius: '0.35rem',
          color: '#606E80',
          fontSize: '0.7875rem;',
          zIndex: '9999'
        }),
        option: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
          backgroundColor: state.isSelected ? '#5e72e4' : 'white',
          '&:hover': {
            backgroundColor: state.isSelected ? '#5e72e4' : '#f6f9fc'
          }
        })
      }}
      {...props}
    />
  );
};
