import React from 'react';
import { Clock } from 'react-feather';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useGetDashboardAnnouncementsQuery } from './__generated__/graphql';
import moment from 'moment-timezone';

export const AnnouncementsCard: React.FC = () => {
  const { data } = useGetDashboardAnnouncementsQuery();
  const announcements = data?.getAnnouncements;

  if (!announcements?.length) {
    return null;
  }

  return (
    <>
      <h2 className="mt-2">Announcements</h2>
      <Card>
        <CardBody>
          <Row>
            {announcements.map((announcement, index) => (
              <Col lg="12" key={announcement.id}>
                <div className="d-flex justify-content-between">
                  <h4>{announcement.title}</h4>
                  <div className="text-right text-muted">
                    <small>
                      <Clock color="#606E80" size={14} className="mr-2" />
                      {moment(announcement.startDate).fromNow()}
                    </small>
                  </div>
                </div>
                <h5 className="text-sm text-muted font-weight-normal mb-0">
                  {announcement.body}
                </h5>
                {index + 1 !== announcements.length && (
                  <div className="my-3 dropdown-divider" />
                )}
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
