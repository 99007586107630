/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import {
  GetCompanyUsageReportQuery,
  useGetCompanyUsageReportLazyQuery
} from './__generated__/graphql';
import { Pulse, Table, SelectInput } from '../../../../components';
import { Column } from 'react-table';
import { CompanyUsageDrilldown } from './CompanyUsageDrilldown';
import { monthOptions, yearOptions } from '../../../../constants';

type CompanyUsage = GetCompanyUsageReportQuery['getCompanies'][0];

const columns: Column<CompanyUsage>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Plan',
    accessor: (company) =>
      `${company.membership.name} ${company.membership.emoji}`
  },
  {
    id: 'percentUtilized',
    Header: 'Percent Utilized',
    accessor: (company) => `${company.usage.percentUtilized}%`
  },
  {
    Header: 'Utilized / Allocated',
    accessor: (company) =>
      `${company.usage.hoursUtilized} / ${company.usage.hoursAllocated}`
  }
];

export const CompanyUsage: React.FC = () => {
  const navigate = useNavigate();

  const { id: companyId } = useParams();

  const [year, setYear] = useState<string>(moment().format('YYYY'));
  const [month, setMonth] = useState<string>(moment().format('MM'));

  const [dateRange, setDateRange] = useState({
    startAt: moment().startOf('month').toISOString(),
    endAt: moment().endOf('month').toISOString()
  });

  useEffect(() => {
    setDateRange({
      startAt: moment(`${year}-${month}`, 'YYYY-MM')
        .startOf('month')
        .toISOString(),

      endAt: moment(`${year}-${month}`, 'YYYY-MM').endOf('month').toISOString()
    });
  }, [month, year]);

  const [getCompanyUsageReport, { data, loading }] =
    useGetCompanyUsageReportLazyQuery({
      variables: {
        dateRange
      }
    });

  const companies = useMemo(() => data?.getCompanies || [], [data]);

  useEffect(() => {
    getCompanyUsageReport();
  }, [dateRange]);

  const headers = [
    { label: 'Company', key: 'name' },
    { label: 'Plan', key: 'membership.name' },
    { label: 'Percent Utilized', key: 'usage.percentUtilized' },
    { label: 'Hours Utilized', key: 'usage.hoursUtilized' },
    { label: 'Hours Allocated', key: 'usage.hoursAllocated' },
    { label: 'Hours Available', key: 'usage.hoursAvailable' }
  ];

  const exportSettings = { headers, filename: 'company_usage_report.csv' };

  const UsageTable = useCallback(
    () => (
      <>
        <h2>Usage by Company</h2>
        <Table
          loading={loading}
          data={companies}
          columns={columns}
          handleSelect={({ id }) =>
            navigate(`/business/reports/company-usage/${id}`)
          }
          sortBy={{
            id: 'percentUtilized',
            desc: true
          }}
        >
          <CSVLink
            data={Object.values(companies)}
            filename={exportSettings.filename}
            headers={exportSettings.headers}
            target="_blank"
          >
            <Button size="sm" color="primary">
              Export
            </Button>
          </CSVLink>
        </Table>
      </>
    ),
    [loading, companies]
  );

  return (
    <>
      {companyId && (
        <Button
          color="primary"
          size="sm"
          className="mb-3"
          onClick={() => navigate('/business/reports/company-usage')}
        >
          Back
        </Button>
      )}
      <Card>
        <CardBody className="mb--3">
          <Row>
            <Col xs="6">
              <SelectInput
                label="Month"
                value={month}
                options={monthOptions}
                onChange={setMonth}
              />
            </Col>
            <Col xs="6">
              <SelectInput
                label="Year"
                value={year}
                options={yearOptions}
                onChange={setYear}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {loading && <Pulse />}
      {!loading && !companyId && <UsageTable />}
      {!loading && companyId && (
        <CompanyUsageDrilldown companyId={companyId} dateRange={dateRange} />
      )}
    </>
  );
};
