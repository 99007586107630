import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Row, Col, ModalBody } from 'reactstrap';
import { DateInput, TextArea } from '../../../components';
import { Blockout } from './BlockoutModal';
import { isAfter, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';
import moment from 'moment';

type BlockoutFormProps = {
  form: UseFormReturn<Blockout>;
};

export const BlockoutForm: React.FC<BlockoutFormProps> = ({
  form: {
    register,
    control,
    formState: { errors }
  }
}) => {
  return (
    <ModalBody>
      <Row>
        <Col xs="12">
          <Controller
            name="date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                label="Date"
                error={errors.date?.message}
                isValidDate={(currentDate) => {
                  const date = new Date(currentDate);
                  const minDate = new Date();

                  return (
                    isAfter(date, startOfDay(minDate)) ||
                    isEqual(date, startOfDay(minDate))
                  );
                }}
                onChange={(date) => {
                  onChange(moment(date).format('YYYY-MM-DD'));
                }}
                value={value}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <TextArea
            label="Description"
            error={errors.description?.message}
            placeholder="Description"
            {...register('description', {
              required: 'Description is required'
            })}
          />
        </Col>
      </Row>
    </ModalBody>
  );
};
