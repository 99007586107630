import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment-timezone';
import { DateInput, Pulse } from '../../../../components';
import { useGetLogReportLazyQuery } from './__generated__/graphql';
import { BarChart } from './BarChart';
import { logTag, logType } from '../../../../constants';

const StatisticCard: React.FC<{ title: string; value: string | number }> = ({
  title,
  value
}) => (
  <Col lg="3" md="6">
    <Card>
      <CardBody>
        <p className="mb-0">{title}</p>
        <h1>{value}</h1>
      </CardBody>
    </Card>
  </Col>
);

export const CaptainsLog: React.FC = () => {
  const [{ startAt, endAt }, setDateRange] = useState({
    startAt: moment().startOf('month').toISOString(),
    endAt: moment().endOf('month').toISOString()
  });

  const [getLogReport, { data, loading }] = useGetLogReportLazyQuery();

  const fetchLogReport = useCallback(() => {
    getLogReport({
      variables: { dateRange: { startAt, endAt } }
    });
  }, [getLogReport, startAt, endAt]);

  useEffect(() => {
    fetchLogReport();
  }, [fetchLogReport]);

  const handleDateChange =
    (key: 'startAt' | 'endAt') => (value: string | moment.Moment) => {
      setDateRange((prev) => ({ ...prev, [key]: moment(value).toISOString() }));
    };

  const logReport = data?.getLogReport;
  const authorLogReport = data?.getAuthorLogReport;

  const countByType = useMemo(
    () =>
      (logReport?.countByType || []).map((item) => ({
        label: logType[item.type],
        value: item.count
      })),
    [logReport]
  );

  const countByTag = useMemo(
    () =>
      (logReport?.countByTag || []).map((item) => ({
        label: logTag[item.tag],
        value: item.count
      })),
    [logReport]
  );

  const countByCompany = useMemo(
    () =>
      (logReport?.countByCompany || []).map((item) => ({
        label: item.company,
        value: item.count
      })),
    [logReport]
  );

  const countByAuthor = useMemo(
    () =>
      (authorLogReport || []).map((item) => ({
        label: item.author,
        value: item.count
      })),
    [authorLogReport]
  );

  const averageDurationByAuthor = useMemo(
    () =>
      (authorLogReport || []).map((item) => ({
        label: item.author,
        value: parseFloat((item.averageDurationInSeconds / 60).toFixed(2))
      })),
    [authorLogReport]
  );

  const averageCharacterCountByAuthor = useMemo(
    () =>
      (authorLogReport || []).map((item) => ({
        label: item.author,
        value: item.averageCharacterCount
      })),
    [authorLogReport]
  );

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody className="mb--3">
              <Row>
                <Col xs="6">
                  <DateInput
                    label="Start Date"
                    value={startAt}
                    onChange={handleDateChange('startAt')}
                  />
                </Col>
                <Col xs="6">
                  <DateInput
                    label="End Date"
                    value={endAt}
                    onChange={handleDateChange('endAt')}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {loading && <Pulse />}
      {logReport && (
        <>
          <Row>
            <StatisticCard
              title="Total Logs"
              value={logReport.count.toLocaleString()}
            />
            <StatisticCard
              title="Average Duration (minutes)"
              value={(logReport.averageDurationInSeconds / 60).toFixed(2)}
            />
            <StatisticCard
              title="Average Character Count"
              value={logReport.averageCharacterCount}
            />
            <StatisticCard
              title="Average Interaction Rating"
              value={
                logReport.averageLogRating === -1
                  ? 'N/A'
                  : `${logReport.averageLogRating}/5`
              }
            />
          </Row>
          <Row>
            <Col lg="6">
              <BarChart data={countByType} chartLabel="Log Type Breakdown" />
            </Col>
            <Col lg="6">
              <BarChart data={countByTag} chartLabel="Log Tag Breakdown" />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                data={countByCompany}
                chartLabel="Log Count by Company"
              />
            </Col>
          </Row>
        </>
      )}
      {authorLogReport && (
        <>
          <Row>
            <Col>
              <BarChart data={countByAuthor} chartLabel="Log Count by Author" />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                data={averageDurationByAuthor}
                chartLabel="Average Log Duration by Author"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BarChart
                data={averageCharacterCountByAuthor}
                chartLabel="Average Character Count by Author"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
