import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { Power } from 'react-feather';
import {
  Button,
  ConfirmationModal,
  DropdownMenu,
  ModalType,
  Pulse
} from '../../../components';
import { useCompanyActions } from './useCompanyActions';
import { useModal } from '../../../hooks';
import classNames from 'classnames';
import { GeneralInformation } from './GeneralInformation';
import { Intake } from './Intake';
import { Training } from './Training';
import { CompanyAlertsTable, CompanyLocationsTable } from '../../../components';
import { useGetCompanyAlertsCountQuery } from './__generated__/graphql';
import { CompanyPhoneTier } from '../../../__generated__/graphql';
import { CompanyNotificationPreferencesTable } from '../../../components/app/CompanyNotificationPreferencesTable/CompanyNotificationPreferencesTable';

type CompanyModalProps = {
  isOpen: boolean;
  companyId: string | null;
  toggle: () => void;
};

export type Company = {
  name: string;
  email: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  tollNumber: string | null;
  websiteUrl: string | null;
  notes: string | null;
  intake: string | null;
  training: string | null;
  memberSince: string | null;
  phoneTier: CompanyPhoneTier | null;
  membershipId: string;
};

const defaultCompany: Company = {
  name: '',
  email: null,
  phoneNumber: null,
  faxNumber: null,
  tollNumber: null,
  websiteUrl: null,
  notes: null,
  intake: null,
  training: null,
  memberSince: null,
  phoneTier: null,
  membershipId: ''
};

enum CompanyMode {
  Create = 'Create',
  Update = 'Update'
}

export const CompanyModal: React.FC<CompanyModalProps> = (props) => {
  const { isOpen, toggle, companyId } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [mode, setMode] = useState<CompanyMode>(CompanyMode.Create);
  const [loading, setLoading] = useState(false);

  const {
    isOpen: isCompanyActivationModalOpen,
    toggleModal: toggleCompanyActivationModal
  } = useModal();

  const {
    company,
    getCompany,
    getCompanyLoading,
    handleCreateCompany,
    createCompanyLoading,
    handleUpdateCompany,
    updateCompanyLoading,
    handleToggleCompany,
    toggleCompanyLoading
  } = useCompanyActions({ toggle, toggleCompanyActivationModal });

  const form = useForm<Company>({
    defaultValues: defaultCompany
  });

  const { data: companyAlertsData } = useGetCompanyAlertsCountQuery({
    variables: {
      companyId: companyId || ''
    },
    skip: !companyId
  });
  const companyAlertsCount = companyAlertsData?.getCompany.activeAlertCount;

  useEffect(() => {
    form.reset(company);
  }, [company]);

  useEffect(() => {
    setLoading(createCompanyLoading || updateCompanyLoading);
  }, [createCompanyLoading, updateCompanyLoading]);

  useEffect(() => {
    if (isOpen) {
      setActiveTab(0);
      if (companyId) {
        setMode(CompanyMode.Update);
        getCompany({
          variables: {
            id: companyId
          }
        });
      } else {
        setMode(CompanyMode.Create);
        form.reset(defaultCompany);
      }
    }
  }, [companyId, isOpen]);

  const handleSubmit = (data: Company) => {
    if (mode === CompanyMode.Update) {
      handleUpdateCompany(data);
    } else {
      handleCreateCompany(data);
    }
  };

  const menuItems = [
    {
      icon: <Power size={14} />,
      label: company?.deactivatedAt ? 'Activate' : 'Deactivate',
      onClick: toggleCompanyActivationModal
    }
  ];

  const tabs = [
    {
      label: 'General Information',
      hide: false
    },
    {
      label: 'Intake',
      hide: false
    },
    {
      label: 'Training',
      hide: false
    },
    {
      label: (
        <div className="d-flex">
          <div>Alerts</div>
          {!!companyAlertsCount && (
            <Badge color="danger" className="ml-2" pill>
              {companyAlertsCount}
            </Badge>
          )}
        </div>
      ),
      hide: !companyId
    },
    {
      label: 'Locations',
      hide: !companyId
    },
    {
      label: 'Notification Preferences',
      hide: !companyId
    }
  ];

  const isCompanyActive = useMemo(
    () => !company?.deactivatedAt,
    [isCompanyActivationModalOpen]
  );

  return (
    <>
      <ConfirmationModal
        type={ModalType.Error}
        isOpen={isCompanyActivationModalOpen}
        toggle={toggleCompanyActivationModal}
        title={isCompanyActive ? 'Deactivate Company' : 'Activate Company'}
        body={
          <p>
            {`You are about to ${isCompanyActive ? 'deactivate' : 'activate'} `}
            <span className="font-weight-bold text-default">
              {`${company?.name}.`}
            </span>{' '}
            {`All user access to this company will be ${isCompanyActive ? 'revoked' : 'restored'}, are you sure you want to do this?`}
          </p>
        }
        loading={toggleCompanyLoading}
        confirmationText={isCompanyActive ? 'Yes, deactivate' : 'Yes, activate'}
        onConfirm={handleToggleCompany}
      />
      <Modal
        toggle={!loading ? toggle : () => null}
        isOpen={isOpen}
        style={{ maxWidth: '650px' }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="editCompany">
            {`${mode} Company`}
          </h5>
          {company && mode === CompanyMode.Update && (
            <DropdownMenu items={menuItems} />
          )}
        </div>
        {getCompanyLoading ? (
          <Pulse />
        ) : (
          <FormProvider {...form}>
            <div className="px-4">
              <Nav tabs className="pb-4">
                {tabs
                  .filter((tab) => !tab.hide)
                  .map((tab, index) => (
                    <NavItem key={`tab-${index}`}>
                      <NavLink
                        className={classNames({
                          active: activeTab === index
                        })}
                        onClick={() => {
                          setActiveTab(index);
                        }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  ))}
              </Nav>
            </div>
            <ModalBody className="pt-0" style={{ height: '615px' }}>
              <TabContent activeTab={activeTab.toString()} className="h-100">
                <TabPane tabId="0">
                  <GeneralInformation />
                </TabPane>
                <TabPane tabId="1">
                  <Intake />
                </TabPane>
                <TabPane tabId="2">
                  <Training />
                </TabPane>
                <TabPane
                  tabId="3"
                  className="h-100"
                  style={{ overflowY: 'scroll', height: '100%' }}
                >
                  {companyId && <CompanyAlertsTable companyId={companyId} />}
                </TabPane>
                <TabPane
                  tabId="4"
                  className="h-100"
                  style={{ overflowY: 'scroll', height: '100%' }}
                >
                  {companyId && <CompanyLocationsTable companyId={companyId} />}
                </TabPane>
                <TabPane
                  tabId="5"
                  className="h-100"
                  style={{ overflowY: 'scroll', height: '100%' }}
                >
                  {companyId && (
                    <CompanyNotificationPreferencesTable
                      companyId={companyId}
                    />
                  )}
                </TabPane>
              </TabContent>
            </ModalBody>
            {activeTab < 3 && (
              <ModalFooter>
                <Button disabled={loading} onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  loading={loading}
                  onClick={form.handleSubmit(handleSubmit, () =>
                    setActiveTab(0)
                  )}
                >
                  {mode}
                </Button>
              </ModalFooter>
            )}
          </FormProvider>
        )}
      </Modal>
    </>
  );
};
