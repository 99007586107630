import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessCalendarQueryVariables = Types.Exact<{
  options: Types.InputMaybe<Types.GetAppointmentsOptions>;
}>;


export type GetBusinessCalendarQuery = { getResources: Array<{ id: string, name: string, capacity: number, type: Types.ResourceType }>, getAppointments: Array<{ id: string, notes: string | null, internalNotes: string | null, startAt: string, endAt: string, status: Types.AppointmentStatus, resource: { id: string, name: string }, user: { id: string, firstName: string, lastName: string } }> };

export type GetBusinessAppointmentQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetBusinessAppointmentQuery = { getAppointment: { id: string, notes: string | null, internalNotes: string | null, startAt: string, endAt: string, status: Types.AppointmentStatus, resourceId: string, userId: string, events: Array<{ id: string, action: Types.EventAction, message: string | null, createdAt: string, user: { id: string, firstName: string, lastName: string } }> } };

export type CreateBusinessAppointmentMutationVariables = Types.Exact<{
  data: Types.CreateAppointmentInput;
  options: Types.InputMaybe<Types.AppointmentOptions>;
}>;


export type CreateBusinessAppointmentMutation = { createAppointment: { id: string } };

export type UpdateBusinessAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateAppointmentInput;
  options: Types.InputMaybe<Types.AppointmentOptions>;
}>;


export type UpdateBusinessAppointmentMutation = { updateAppointment: { id: string } };

export type CancelBusinessAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  options: Types.InputMaybe<Types.AppointmentOptions>;
}>;


export type CancelBusinessAppointmentMutation = { cancelAppointment: { id: string } };


export const GetBusinessCalendarDocument = gql`
    query GetBusinessCalendar($options: GetAppointmentsOptions) {
  getResources(includeInactive: false) {
    id
    name
    capacity
    type
  }
  getAppointments(options: $options) {
    id
    notes
    internalNotes
    startAt
    endAt
    status
    resource {
      id
      name
    }
    user {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetBusinessCalendarQuery__
 *
 * To run a query within a React component, call `useGetBusinessCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCalendarQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetBusinessCalendarQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessCalendarQuery, GetBusinessCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessCalendarQuery, GetBusinessCalendarQueryVariables>(GetBusinessCalendarDocument, options);
      }
export function useGetBusinessCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessCalendarQuery, GetBusinessCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessCalendarQuery, GetBusinessCalendarQueryVariables>(GetBusinessCalendarDocument, options);
        }
export type GetBusinessCalendarQueryHookResult = ReturnType<typeof useGetBusinessCalendarQuery>;
export type GetBusinessCalendarLazyQueryHookResult = ReturnType<typeof useGetBusinessCalendarLazyQuery>;
export type GetBusinessCalendarQueryResult = Apollo.QueryResult<GetBusinessCalendarQuery, GetBusinessCalendarQueryVariables>;
export const GetBusinessAppointmentDocument = gql`
    query GetBusinessAppointment($id: UUID!) {
  getAppointment(id: $id) {
    id
    notes
    internalNotes
    startAt
    endAt
    status
    resourceId
    userId
    events {
      id
      user {
        id
        firstName
        lastName
      }
      action
      message
      createdAt
    }
  }
}
    `;

/**
 * __useGetBusinessAppointmentQuery__
 *
 * To run a query within a React component, call `useGetBusinessAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessAppointmentQuery, GetBusinessAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessAppointmentQuery, GetBusinessAppointmentQueryVariables>(GetBusinessAppointmentDocument, options);
      }
export function useGetBusinessAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessAppointmentQuery, GetBusinessAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessAppointmentQuery, GetBusinessAppointmentQueryVariables>(GetBusinessAppointmentDocument, options);
        }
export type GetBusinessAppointmentQueryHookResult = ReturnType<typeof useGetBusinessAppointmentQuery>;
export type GetBusinessAppointmentLazyQueryHookResult = ReturnType<typeof useGetBusinessAppointmentLazyQuery>;
export type GetBusinessAppointmentQueryResult = Apollo.QueryResult<GetBusinessAppointmentQuery, GetBusinessAppointmentQueryVariables>;
export const CreateBusinessAppointmentDocument = gql`
    mutation CreateBusinessAppointment($data: CreateAppointmentInput!, $options: AppointmentOptions) {
  createAppointment(data: $data, options: $options) {
    id
  }
}
    `;
export type CreateBusinessAppointmentMutationFn = Apollo.MutationFunction<CreateBusinessAppointmentMutation, CreateBusinessAppointmentMutationVariables>;

/**
 * __useCreateBusinessAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateBusinessAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessAppointmentMutation, { data, loading, error }] = useCreateBusinessAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateBusinessAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessAppointmentMutation, CreateBusinessAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessAppointmentMutation, CreateBusinessAppointmentMutationVariables>(CreateBusinessAppointmentDocument, options);
      }
export type CreateBusinessAppointmentMutationHookResult = ReturnType<typeof useCreateBusinessAppointmentMutation>;
export type CreateBusinessAppointmentMutationResult = Apollo.MutationResult<CreateBusinessAppointmentMutation>;
export type CreateBusinessAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateBusinessAppointmentMutation, CreateBusinessAppointmentMutationVariables>;
export const UpdateBusinessAppointmentDocument = gql`
    mutation UpdateBusinessAppointment($id: UUID!, $data: UpdateAppointmentInput!, $options: AppointmentOptions) {
  updateAppointment(id: $id, data: $data, options: $options) {
    id
  }
}
    `;
export type UpdateBusinessAppointmentMutationFn = Apollo.MutationFunction<UpdateBusinessAppointmentMutation, UpdateBusinessAppointmentMutationVariables>;

/**
 * __useUpdateBusinessAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessAppointmentMutation, { data, loading, error }] = useUpdateBusinessAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateBusinessAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessAppointmentMutation, UpdateBusinessAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessAppointmentMutation, UpdateBusinessAppointmentMutationVariables>(UpdateBusinessAppointmentDocument, options);
      }
export type UpdateBusinessAppointmentMutationHookResult = ReturnType<typeof useUpdateBusinessAppointmentMutation>;
export type UpdateBusinessAppointmentMutationResult = Apollo.MutationResult<UpdateBusinessAppointmentMutation>;
export type UpdateBusinessAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessAppointmentMutation, UpdateBusinessAppointmentMutationVariables>;
export const CancelBusinessAppointmentDocument = gql`
    mutation CancelBusinessAppointment($id: UUID!, $options: AppointmentOptions) {
  cancelAppointment(id: $id, options: $options) {
    id
  }
}
    `;
export type CancelBusinessAppointmentMutationFn = Apollo.MutationFunction<CancelBusinessAppointmentMutation, CancelBusinessAppointmentMutationVariables>;

/**
 * __useCancelBusinessAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelBusinessAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBusinessAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBusinessAppointmentMutation, { data, loading, error }] = useCancelBusinessAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCancelBusinessAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelBusinessAppointmentMutation, CancelBusinessAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBusinessAppointmentMutation, CancelBusinessAppointmentMutationVariables>(CancelBusinessAppointmentDocument, options);
      }
export type CancelBusinessAppointmentMutationHookResult = ReturnType<typeof useCancelBusinessAppointmentMutation>;
export type CancelBusinessAppointmentMutationResult = Apollo.MutationResult<CancelBusinessAppointmentMutation>;
export type CancelBusinessAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelBusinessAppointmentMutation, CancelBusinessAppointmentMutationVariables>;