import React, { forwardRef } from 'react';
import ReactQuill, { Quill as Q } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module-ts';
import 'quill-paste-smart';
import 'react-quill/dist/quill.snow.css';

Q.register('modules/imageResize', ImageResize);

type QuillProps = Omit<ReactQuill.ReactQuillProps, 'onChange' | 'value'> & {
  onChange?: (value: string) => void;
  value?: string | null;
};

export const Quill = forwardRef<ReactQuill, QuillProps>(
  ({ onChange, value, ...rest }, ref) => (
    <ReactQuill
      ref={ref} // Pass the ref to ReactQuill
      theme="snow"
      modules={{
        imageResize: {
          parchment: Q.import('parchment'),
          modules: ['Resize', 'DisplaySize']
        },
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            { color: [] }
          ],
          [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
          ['image', 'link']
        ]
      }}
      value={value === null ? '' : value}
      {...rest}
      onChange={(newValue, _delta, source) => {
        if (source === 'user') {
          onChange?.(newValue);
        }
      }}
    />
  )
);

Quill.displayName = 'Quill';
