import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyLocationsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyLocationsQuery = { getCompanyLocations: Array<{ id: string, addressLine1: string | null, addressLine2: string | null, addressCity: string | null, addressState: string | null, addressZip: string | null, label: string | null, isPrimary: boolean }> };

export type GetCompanyLocationQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetCompanyLocationQuery = { getCompanyLocation: { id: string, companyId: string, addressLine1: string | null, addressLine2: string | null, addressCity: string | null, addressState: string | null, addressZip: string | null, label: string | null, isPrimary: boolean } };

export type CreateCompanyLocationMutationVariables = Types.Exact<{
  data: Types.CreateCompanyLocationInput;
}>;


export type CreateCompanyLocationMutation = { createCompanyLocation: { id: string } };

export type UpdateCompanyLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateCompanyLocationInput;
}>;


export type UpdateCompanyLocationMutation = { updateCompanyLocation: { id: string } };

export type DeleteCompanyLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeleteCompanyLocationMutation = { deleteCompanyLocation: { id: string } };


export const GetCompanyLocationsDocument = gql`
    query GetCompanyLocations($companyId: UUID!) {
  getCompanyLocations(companyId: $companyId) {
    id
    addressLine1
    addressLine2
    addressCity
    addressState
    addressZip
    label
    isPrimary
  }
}
    `;

/**
 * __useGetCompanyLocationsQuery__
 *
 * To run a query within a React component, call `useGetCompanyLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLocationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>(GetCompanyLocationsDocument, options);
      }
export function useGetCompanyLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>(GetCompanyLocationsDocument, options);
        }
export type GetCompanyLocationsQueryHookResult = ReturnType<typeof useGetCompanyLocationsQuery>;
export type GetCompanyLocationsLazyQueryHookResult = ReturnType<typeof useGetCompanyLocationsLazyQuery>;
export type GetCompanyLocationsQueryResult = Apollo.QueryResult<GetCompanyLocationsQuery, GetCompanyLocationsQueryVariables>;
export const GetCompanyLocationDocument = gql`
    query GetCompanyLocation($id: UUID!) {
  getCompanyLocation(id: $id) {
    id
    companyId
    addressLine1
    addressLine2
    addressCity
    addressState
    addressZip
    label
    isPrimary
  }
}
    `;

/**
 * __useGetCompanyLocationQuery__
 *
 * To run a query within a React component, call `useGetCompanyLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyLocationQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyLocationQuery, GetCompanyLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyLocationQuery, GetCompanyLocationQueryVariables>(GetCompanyLocationDocument, options);
      }
export function useGetCompanyLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyLocationQuery, GetCompanyLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyLocationQuery, GetCompanyLocationQueryVariables>(GetCompanyLocationDocument, options);
        }
export type GetCompanyLocationQueryHookResult = ReturnType<typeof useGetCompanyLocationQuery>;
export type GetCompanyLocationLazyQueryHookResult = ReturnType<typeof useGetCompanyLocationLazyQuery>;
export type GetCompanyLocationQueryResult = Apollo.QueryResult<GetCompanyLocationQuery, GetCompanyLocationQueryVariables>;
export const CreateCompanyLocationDocument = gql`
    mutation CreateCompanyLocation($data: CreateCompanyLocationInput!) {
  createCompanyLocation(data: $data) {
    id
  }
}
    `;
export type CreateCompanyLocationMutationFn = Apollo.MutationFunction<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>;

/**
 * __useCreateCompanyLocationMutation__
 *
 * To run a mutation, you first call `useCreateCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyLocationMutation, { data, loading, error }] = useCreateCompanyLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>(CreateCompanyLocationDocument, options);
      }
export type CreateCompanyLocationMutationHookResult = ReturnType<typeof useCreateCompanyLocationMutation>;
export type CreateCompanyLocationMutationResult = Apollo.MutationResult<CreateCompanyLocationMutation>;
export type CreateCompanyLocationMutationOptions = Apollo.BaseMutationOptions<CreateCompanyLocationMutation, CreateCompanyLocationMutationVariables>;
export const UpdateCompanyLocationDocument = gql`
    mutation UpdateCompanyLocation($id: UUID!, $data: UpdateCompanyLocationInput!) {
  updateCompanyLocation(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateCompanyLocationMutationFn = Apollo.MutationFunction<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>;

/**
 * __useUpdateCompanyLocationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyLocationMutation, { data, loading, error }] = useUpdateCompanyLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>(UpdateCompanyLocationDocument, options);
      }
export type UpdateCompanyLocationMutationHookResult = ReturnType<typeof useUpdateCompanyLocationMutation>;
export type UpdateCompanyLocationMutationResult = Apollo.MutationResult<UpdateCompanyLocationMutation>;
export type UpdateCompanyLocationMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyLocationMutation, UpdateCompanyLocationMutationVariables>;
export const DeleteCompanyLocationDocument = gql`
    mutation DeleteCompanyLocation($id: UUID!) {
  deleteCompanyLocation(id: $id) {
    id
  }
}
    `;
export type DeleteCompanyLocationMutationFn = Apollo.MutationFunction<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>;

/**
 * __useDeleteCompanyLocationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyLocationMutation, { data, loading, error }] = useDeleteCompanyLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>(DeleteCompanyLocationDocument, options);
      }
export type DeleteCompanyLocationMutationHookResult = ReturnType<typeof useDeleteCompanyLocationMutation>;
export type DeleteCompanyLocationMutationResult = Apollo.MutationResult<DeleteCompanyLocationMutation>;
export type DeleteCompanyLocationMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyLocationMutation, DeleteCompanyLocationMutationVariables>;