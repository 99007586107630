import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyOptionsQuery = { getCompanies: Array<{ id: string, name: string }> };


export const GetCompanyOptionsDocument = gql`
    query GetCompanyOptions {
  getCompanies(includeInactive: true) {
    id
    name
  }
}
    `;

/**
 * __useGetCompanyOptionsQuery__
 *
 * To run a query within a React component, call `useGetCompanyOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyOptionsQuery, GetCompanyOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyOptionsQuery, GetCompanyOptionsQueryVariables>(GetCompanyOptionsDocument, options);
      }
export function useGetCompanyOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyOptionsQuery, GetCompanyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyOptionsQuery, GetCompanyOptionsQueryVariables>(GetCompanyOptionsDocument, options);
        }
export type GetCompanyOptionsQueryHookResult = ReturnType<typeof useGetCompanyOptionsQuery>;
export type GetCompanyOptionsLazyQueryHookResult = ReturnType<typeof useGetCompanyOptionsLazyQuery>;
export type GetCompanyOptionsQueryResult = Apollo.QueryResult<GetCompanyOptionsQuery, GetCompanyOptionsQueryVariables>;