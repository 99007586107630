import React from 'react';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
  Button
} from 'reactstrap';
import { GetBusinessAppointmentQuery } from './__generated__/graphql';

export type AppointmentHistoryModalProps = {
  toggle: () => void;
  isOpen: boolean;
  events: GetBusinessAppointmentQuery['getAppointment']['events'];
};

export const AppointmentHistoryModal: React.FC<
  AppointmentHistoryModalProps
> = ({ isOpen, toggle, events }) => {
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="lg" centered>
      <ModalHeader>Appointment History</ModalHeader>
      <ModalBody>
        {!events.length && (
          <p className="mb-0">No history found for this appointment.</p>
        )}
        {events.length > 0 && (
          <Table striped>
            <thead>
              <tr>
                <th>User</th>
                <th>Action</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {events
                .sort(
                  (a, b) =>
                    moment(b.createdAt).valueOf() -
                    moment(a.createdAt).valueOf()
                )
                .map((event) => (
                  <tr key={event.id} style={{ cursor: 'default' }}>
                    <td>{`${event.user.firstName} ${event.user.lastName}`}</td>
                    <td>{event.message}</td>
                    <td>
                      {moment(event.createdAt).format(
                        'dddd, MMMM Do YYYY [at] h:mm A'
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
