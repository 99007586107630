import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDashboardAnnouncementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDashboardAnnouncementsQuery = { getAnnouncements: Array<{ id: string, title: string, body: string, startDate: string }> };

export type GetDashboardBlockoutsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDashboardBlockoutsQuery = { getBlockouts: Array<{ id: string, date: string, description: string | null }> };

export type GetDashboardAppointmentsQueryVariables = Types.Exact<{
  options: Types.GetAppointmentsOptions;
}>;


export type GetDashboardAppointmentsQuery = { getAppointments: Array<{ id: string, notes: string | null, status: Types.AppointmentStatus, startAt: string, endAt: string, buffer: number, user: { id: string, firstName: string, lastName: string }, resource: { id: string, name: string } }> };


export const GetDashboardAnnouncementsDocument = gql`
    query GetDashboardAnnouncements {
  getAnnouncements(currentOnly: true) {
    id
    title
    body
    startDate
  }
}
    `;

/**
 * __useGetDashboardAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetDashboardAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardAnnouncementsQuery, GetDashboardAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardAnnouncementsQuery, GetDashboardAnnouncementsQueryVariables>(GetDashboardAnnouncementsDocument, options);
      }
export function useGetDashboardAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardAnnouncementsQuery, GetDashboardAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardAnnouncementsQuery, GetDashboardAnnouncementsQueryVariables>(GetDashboardAnnouncementsDocument, options);
        }
export type GetDashboardAnnouncementsQueryHookResult = ReturnType<typeof useGetDashboardAnnouncementsQuery>;
export type GetDashboardAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetDashboardAnnouncementsLazyQuery>;
export type GetDashboardAnnouncementsQueryResult = Apollo.QueryResult<GetDashboardAnnouncementsQuery, GetDashboardAnnouncementsQueryVariables>;
export const GetDashboardBlockoutsDocument = gql`
    query GetDashboardBlockouts {
  getBlockouts {
    id
    date
    description
  }
}
    `;

/**
 * __useGetDashboardBlockoutsQuery__
 *
 * To run a query within a React component, call `useGetDashboardBlockoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardBlockoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardBlockoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardBlockoutsQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardBlockoutsQuery, GetDashboardBlockoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardBlockoutsQuery, GetDashboardBlockoutsQueryVariables>(GetDashboardBlockoutsDocument, options);
      }
export function useGetDashboardBlockoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardBlockoutsQuery, GetDashboardBlockoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardBlockoutsQuery, GetDashboardBlockoutsQueryVariables>(GetDashboardBlockoutsDocument, options);
        }
export type GetDashboardBlockoutsQueryHookResult = ReturnType<typeof useGetDashboardBlockoutsQuery>;
export type GetDashboardBlockoutsLazyQueryHookResult = ReturnType<typeof useGetDashboardBlockoutsLazyQuery>;
export type GetDashboardBlockoutsQueryResult = Apollo.QueryResult<GetDashboardBlockoutsQuery, GetDashboardBlockoutsQueryVariables>;
export const GetDashboardAppointmentsDocument = gql`
    query GetDashboardAppointments($options: GetAppointmentsOptions!) {
  getAppointments(options: $options) {
    id
    notes
    status
    startAt
    endAt
    buffer
    user {
      id
      firstName
      lastName
    }
    resource {
      id
      name
    }
  }
}
    `;

/**
 * __useGetDashboardAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetDashboardAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAppointmentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetDashboardAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardAppointmentsQuery, GetDashboardAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardAppointmentsQuery, GetDashboardAppointmentsQueryVariables>(GetDashboardAppointmentsDocument, options);
      }
export function useGetDashboardAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardAppointmentsQuery, GetDashboardAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardAppointmentsQuery, GetDashboardAppointmentsQueryVariables>(GetDashboardAppointmentsDocument, options);
        }
export type GetDashboardAppointmentsQueryHookResult = ReturnType<typeof useGetDashboardAppointmentsQuery>;
export type GetDashboardAppointmentsLazyQueryHookResult = ReturnType<typeof useGetDashboardAppointmentsLazyQuery>;
export type GetDashboardAppointmentsQueryResult = Apollo.QueryResult<GetDashboardAppointmentsQuery, GetDashboardAppointmentsQueryVariables>;