import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessResourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessResourcesQuery = { getResources: Array<{ id: string, name: string, capacity: number, type: Types.ResourceType, deactivatedAt: string | null }> };

export type GetBusinessResourceQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetBusinessResourceQuery = { getResource: { id: string, name: string, description: string | null, capacity: number, type: Types.ResourceType, deactivatedAt: string | null } };

export type CreateBusinessResourceMutationVariables = Types.Exact<{
  data: Types.CreateResourceInput;
}>;


export type CreateBusinessResourceMutation = { createResource: { id: string } };

export type UpdateBusinessResourceMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  data: Types.UpdateResourceInput;
}>;


export type UpdateBusinessResourceMutation = { updateResource: { id: string } };

export type ActivateBusinessResourceMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type ActivateBusinessResourceMutation = { activateResource: { id: string } };

export type DeactivateBusinessResourceMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type DeactivateBusinessResourceMutation = { deactivateResource: { id: string } };


export const GetBusinessResourcesDocument = gql`
    query GetBusinessResources {
  getResources(includeInactive: true) {
    id
    name
    capacity
    type
    deactivatedAt
  }
}
    `;

/**
 * __useGetBusinessResourcesQuery__
 *
 * To run a query within a React component, call `useGetBusinessResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessResourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessResourcesQuery, GetBusinessResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessResourcesQuery, GetBusinessResourcesQueryVariables>(GetBusinessResourcesDocument, options);
      }
export function useGetBusinessResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessResourcesQuery, GetBusinessResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessResourcesQuery, GetBusinessResourcesQueryVariables>(GetBusinessResourcesDocument, options);
        }
export type GetBusinessResourcesQueryHookResult = ReturnType<typeof useGetBusinessResourcesQuery>;
export type GetBusinessResourcesLazyQueryHookResult = ReturnType<typeof useGetBusinessResourcesLazyQuery>;
export type GetBusinessResourcesQueryResult = Apollo.QueryResult<GetBusinessResourcesQuery, GetBusinessResourcesQueryVariables>;
export const GetBusinessResourceDocument = gql`
    query GetBusinessResource($id: UUID!) {
  getResource(id: $id) {
    id
    name
    description
    capacity
    type
    deactivatedAt
  }
}
    `;

/**
 * __useGetBusinessResourceQuery__
 *
 * To run a query within a React component, call `useGetBusinessResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessResourceQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessResourceQuery, GetBusinessResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessResourceQuery, GetBusinessResourceQueryVariables>(GetBusinessResourceDocument, options);
      }
export function useGetBusinessResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessResourceQuery, GetBusinessResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessResourceQuery, GetBusinessResourceQueryVariables>(GetBusinessResourceDocument, options);
        }
export type GetBusinessResourceQueryHookResult = ReturnType<typeof useGetBusinessResourceQuery>;
export type GetBusinessResourceLazyQueryHookResult = ReturnType<typeof useGetBusinessResourceLazyQuery>;
export type GetBusinessResourceQueryResult = Apollo.QueryResult<GetBusinessResourceQuery, GetBusinessResourceQueryVariables>;
export const CreateBusinessResourceDocument = gql`
    mutation CreateBusinessResource($data: CreateResourceInput!) {
  createResource(data: $data) {
    id
  }
}
    `;
export type CreateBusinessResourceMutationFn = Apollo.MutationFunction<CreateBusinessResourceMutation, CreateBusinessResourceMutationVariables>;

/**
 * __useCreateBusinessResourceMutation__
 *
 * To run a mutation, you first call `useCreateBusinessResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessResourceMutation, { data, loading, error }] = useCreateBusinessResourceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusinessResourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessResourceMutation, CreateBusinessResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessResourceMutation, CreateBusinessResourceMutationVariables>(CreateBusinessResourceDocument, options);
      }
export type CreateBusinessResourceMutationHookResult = ReturnType<typeof useCreateBusinessResourceMutation>;
export type CreateBusinessResourceMutationResult = Apollo.MutationResult<CreateBusinessResourceMutation>;
export type CreateBusinessResourceMutationOptions = Apollo.BaseMutationOptions<CreateBusinessResourceMutation, CreateBusinessResourceMutationVariables>;
export const UpdateBusinessResourceDocument = gql`
    mutation UpdateBusinessResource($id: UUID!, $data: UpdateResourceInput!) {
  updateResource(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateBusinessResourceMutationFn = Apollo.MutationFunction<UpdateBusinessResourceMutation, UpdateBusinessResourceMutationVariables>;

/**
 * __useUpdateBusinessResourceMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessResourceMutation, { data, loading, error }] = useUpdateBusinessResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusinessResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessResourceMutation, UpdateBusinessResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessResourceMutation, UpdateBusinessResourceMutationVariables>(UpdateBusinessResourceDocument, options);
      }
export type UpdateBusinessResourceMutationHookResult = ReturnType<typeof useUpdateBusinessResourceMutation>;
export type UpdateBusinessResourceMutationResult = Apollo.MutationResult<UpdateBusinessResourceMutation>;
export type UpdateBusinessResourceMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessResourceMutation, UpdateBusinessResourceMutationVariables>;
export const ActivateBusinessResourceDocument = gql`
    mutation ActivateBusinessResource($id: UUID!) {
  activateResource(id: $id) {
    id
  }
}
    `;
export type ActivateBusinessResourceMutationFn = Apollo.MutationFunction<ActivateBusinessResourceMutation, ActivateBusinessResourceMutationVariables>;

/**
 * __useActivateBusinessResourceMutation__
 *
 * To run a mutation, you first call `useActivateBusinessResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBusinessResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBusinessResourceMutation, { data, loading, error }] = useActivateBusinessResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBusinessResourceMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBusinessResourceMutation, ActivateBusinessResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBusinessResourceMutation, ActivateBusinessResourceMutationVariables>(ActivateBusinessResourceDocument, options);
      }
export type ActivateBusinessResourceMutationHookResult = ReturnType<typeof useActivateBusinessResourceMutation>;
export type ActivateBusinessResourceMutationResult = Apollo.MutationResult<ActivateBusinessResourceMutation>;
export type ActivateBusinessResourceMutationOptions = Apollo.BaseMutationOptions<ActivateBusinessResourceMutation, ActivateBusinessResourceMutationVariables>;
export const DeactivateBusinessResourceDocument = gql`
    mutation DeactivateBusinessResource($id: UUID!) {
  deactivateResource(id: $id) {
    id
  }
}
    `;
export type DeactivateBusinessResourceMutationFn = Apollo.MutationFunction<DeactivateBusinessResourceMutation, DeactivateBusinessResourceMutationVariables>;

/**
 * __useDeactivateBusinessResourceMutation__
 *
 * To run a mutation, you first call `useDeactivateBusinessResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateBusinessResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateBusinessResourceMutation, { data, loading, error }] = useDeactivateBusinessResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateBusinessResourceMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateBusinessResourceMutation, DeactivateBusinessResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateBusinessResourceMutation, DeactivateBusinessResourceMutationVariables>(DeactivateBusinessResourceDocument, options);
      }
export type DeactivateBusinessResourceMutationHookResult = ReturnType<typeof useDeactivateBusinessResourceMutation>;
export type DeactivateBusinessResourceMutationResult = Apollo.MutationResult<DeactivateBusinessResourceMutation>;
export type DeactivateBusinessResourceMutationOptions = Apollo.BaseMutationOptions<DeactivateBusinessResourceMutation, DeactivateBusinessResourceMutationVariables>;