import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalFooter } from 'reactstrap';
import { Button } from '../..';
import { CompanyNotificationPreferenceForm } from './CompanyNotificationPreferenceForm';
import { UserWithNotificationPreference } from './CompanyNotificationPreferencesTable';
import { LogType, namedOperations } from '../../../__generated__/graphql';
import { useSetUserNotificationPreferenceMutation } from './__generated__/graphql';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';

type CompanyNotificationPreferenceModalProps = {
  isOpen: boolean;
  user: UserWithNotificationPreference | null;
  toggle: () => void;
};

export type CompanyNotificationPreference = {
  userId: string;
  logTypes: LogType[];
};

export const CompanyNotificationPreferenceModal: React.FC<
  CompanyNotificationPreferenceModalProps
> = (props) => {
  const { isOpen, toggle, user } = props;

  const defaultCompanyNotificationPreference: CompanyNotificationPreference = {
    userId: '',
    logTypes: []
  };

  const form = useForm<CompanyNotificationPreference>({
    defaultValues: defaultCompanyNotificationPreference
  });

  useEffect(() => {
    if (isOpen && user) {
      form.reset({
        userId: user.id,
        logTypes: user.notificationPreference.logTypes
      });
    } else {
      form.reset(defaultCompanyNotificationPreference);
    }
  }, [user, isOpen]);

  const [setNotificationPreference, { loading }] =
    useSetUserNotificationPreferenceMutation({
      refetchQueries: [namedOperations.Query.GetCompanyNotificationPreferences]
    });

  const handleSubmit = async (data: CompanyNotificationPreference) => {
    try {
      await setNotificationPreference({
        variables: { data }
      });

      showSuccessToast('Notification preferences successfully updated!');
      toggle();
    } catch (error) {
      showErrorToast('Error updating notification preferences!');
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Modal toggle={!loading ? toggle : () => null} isOpen={isOpen} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="editCompanyNotificationPreference">
            Notification Preferences
          </h5>
        </div>
        <CompanyNotificationPreferenceForm form={form} user={user} />
        <ModalFooter>
          <Button disabled={loading} onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            loading={loading}
            onClick={form.handleSubmit(handleSubmit)}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
