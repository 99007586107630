import React, { useState } from 'react';
import { Column } from 'react-table';
import { Badge, Button } from 'reactstrap';
import {
  GetBusinessAnnouncementsQuery,
  useGetBusinessAnnouncementsQuery
} from './__generated__/graphql';
import { Table } from '../../../components';
import { useModal } from '../../../hooks';
import { AnnouncementModal } from './AnnouncementModal';
import { format, isAfter, isBefore, parseISO } from 'date-fns';

type Announcement = GetBusinessAnnouncementsQuery['getAnnouncements'][0];

const columns: Column<Announcement>[] = [
  {
    id: 'title',
    Header: 'Title',
    accessor: 'title',
    disableSortBy: true
  },
  {
    Header: 'Body',
    accessor: 'body',
    Cell: ({ value: body }) => {
      return <div className="wrap-cell">{body}</div>;
    },
    disableSortBy: true
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value: startDate }) => (
      <span>{format(parseISO(startDate), 'EEEE, MMMM do, yyyy')}</span>
    )
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    Cell: ({ value: endDate }) => (
      <span>{format(parseISO(endDate), 'EEEE, MMMM do, yyyy')}</span>
    )
  },
  {
    Header: 'Status',
    accessor: (announcement) => {
      const now = new Date();

      if (isAfter(now, new Date(announcement.endDate))) {
        return (
          <Badge color="default" pill>
            Completed
          </Badge>
        );
      }

      if (isBefore(now, new Date(announcement.startDate))) {
        return (
          <Badge color="info" pill>
            Scheduled
          </Badge>
        );
      }

      return (
        <Badge color="primary" pill>
          Live
        </Badge>
      );
    },
    disableSortBy: true
  }
];

export const Announcements: React.FC = () => {
  const [announcementId, setAnnouncementId] = useState<string | null>(null);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const {
    isOpen: isAnnouncementModalOpen,
    toggleModal: toggleAnnouncementModal
  } = useModal();

  const { loading } = useGetBusinessAnnouncementsQuery({
    onCompleted: (data) => setAnnouncements(data.getAnnouncements)
  });

  const handleSelect = (announcement: Announcement) => {
    setAnnouncementId(announcement.id);
    toggleAnnouncementModal();
  };

  const handleCreate = () => {
    setAnnouncementId(null);
    toggleAnnouncementModal();
  };

  return (
    <>
      <AnnouncementModal
        isOpen={isAnnouncementModalOpen}
        toggle={toggleAnnouncementModal}
        announcementId={announcementId}
      />
      <Table<Announcement>
        loading={loading}
        data={announcements}
        columns={columns}
        handleSelect={handleSelect}
        sortBy={{
          id: 'startDate',
          desc: true
        }}
      >
        <Button
          size="sm"
          color="default"
          className="ml-2"
          onClick={handleCreate}
        >
          Create
        </Button>
      </Table>
    </>
  );
};
