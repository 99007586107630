import React from 'react';

import './style.scss';

export type SpinnerProps = {
  stroke?: string;
};

export const Spinner: React.FC<SpinnerProps> = ({ stroke = '#e9ecef' }) => {
  return (
    <svg className="spinner" viewBox="0 0 50 50">
      <circle
        className="path"
        stroke={stroke}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        width="100%"
        height="100%"
      />
    </svg>
  );
};
