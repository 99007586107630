import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserRoleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserRoleQuery = { currentUser: { id: string, role: Types.UserRole } };


export const GetUserRoleDocument = gql`
    query GetUserRole {
  currentUser {
    id
    role
  }
}
    `;

/**
 * __useGetUserRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
      }
export function useGetUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
        }
export type GetUserRoleQueryHookResult = ReturnType<typeof useGetUserRoleQuery>;
export type GetUserRoleLazyQueryHookResult = ReturnType<typeof useGetUserRoleLazyQuery>;
export type GetUserRoleQueryResult = Apollo.QueryResult<GetUserRoleQuery, GetUserRoleQueryVariables>;