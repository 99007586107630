import * as Types from '../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusinessSettingsQuery = { getBusiness: { id: string, appointmentBuffer: number, appointmentBookingEnabled: boolean, maintenanceEnabled: boolean, appointmentMaxBookingDays: number } };


export const GetBusinessSettingsDocument = gql`
    query GetBusinessSettings {
  getBusiness {
    id
    appointmentBuffer
    appointmentBookingEnabled
    maintenanceEnabled
    appointmentMaxBookingDays
  }
}
    `;

/**
 * __useGetBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useGetBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessSettingsQuery, GetBusinessSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessSettingsQuery, GetBusinessSettingsQueryVariables>(GetBusinessSettingsDocument, options);
      }
export function useGetBusinessSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessSettingsQuery, GetBusinessSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessSettingsQuery, GetBusinessSettingsQueryVariables>(GetBusinessSettingsDocument, options);
        }
export type GetBusinessSettingsQueryHookResult = ReturnType<typeof useGetBusinessSettingsQuery>;
export type GetBusinessSettingsLazyQueryHookResult = ReturnType<typeof useGetBusinessSettingsLazyQuery>;
export type GetBusinessSettingsQueryResult = Apollo.QueryResult<GetBusinessSettingsQuery, GetBusinessSettingsQueryVariables>;