import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFileUploadQueryVariables = Types.Exact<{
  businessId: Types.Scalars['UUID']['input'];
  id: Types.Scalars['UUID']['input'];
}>;


export type GetFileUploadQuery = { getFileUpload: { id: string, url: string, fileName: string, fileType: string } };


export const GetFileUploadDocument = gql`
    query GetFileUpload($businessId: UUID!, $id: UUID!) {
  getFileUpload(businessId: $businessId, id: $id) {
    id
    url
    fileName
    fileType
  }
}
    `;

/**
 * __useGetFileUploadQuery__
 *
 * To run a query within a React component, call `useGetFileUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileUploadQuery(baseOptions: Apollo.QueryHookOptions<GetFileUploadQuery, GetFileUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileUploadQuery, GetFileUploadQueryVariables>(GetFileUploadDocument, options);
      }
export function useGetFileUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileUploadQuery, GetFileUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileUploadQuery, GetFileUploadQueryVariables>(GetFileUploadDocument, options);
        }
export type GetFileUploadQueryHookResult = ReturnType<typeof useGetFileUploadQuery>;
export type GetFileUploadLazyQueryHookResult = ReturnType<typeof useGetFileUploadLazyQuery>;
export type GetFileUploadQueryResult = Apollo.QueryResult<GetFileUploadQuery, GetFileUploadQueryVariables>;