import React from 'react';
import { Navigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Logo from '../../assets/images/logo.png';
import { useBusinessSettings } from '../../hooks';
import { Pulse } from '../../components';

export const Maintenance: React.FC = () => {
  const settings = useBusinessSettings();

  if (!settings) {
    return <Pulse />;
  }

  if (settings.maintenanceEnabled) {
    return (
      <Container fluid>
        <Row
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '100vh' }}
        >
          <Col lg="6" md="8" sm="10" xs="12">
            <Card>
              <CardHeader className="border-0 pb-0 text-center">
                <img
                  src={Logo}
                  alt="logo"
                  style={{ maxWidth: '200px' }}
                  className="pb-4"
                />
                <h2 className="mb-0">Scheduled Maintenance</h2>
              </CardHeader>
              <CardBody className="text-center pt-3 text-sm">
                <div>Sorry, the page you are looking for is currently</div>
                <div>under maintenance and will be back soon.</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return <Navigate to="/dashboard" />;
};
