import React from 'react';
import { Alert, Card, CardBody } from 'reactstrap';
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar
} from 'recharts';

type BarChartProps = {
  data: Array<{ label: string; value: number }>;
  chartLabel: string;
  yAxisLabel?: string;
};

const CustomTooltip: React.FC<{
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  label?: string;
  dataKey: string;
}> = ({ active, payload, label: toolTipLabel, dataKey }) => {
  if (active) {
    return (
      <Alert
        color="white"
        className="p-2"
        style={{ boxShadow: '0px 0px 22px 2px rgba(178, 178, 178, .4)' }}
      >
        <div className="font-weight-bold">{toolTipLabel}</div>
        {payload && `${payload[0].payload[dataKey]}`}
      </Alert>
    );
  }
  return null;
};

export const BarChart: React.FC<BarChartProps> = ({
  data,
  chartLabel,
  yAxisLabel
}) => {
  return (
    <>
      <h2>{chartLabel}</h2>
      <Card>
        <CardBody>
          <ResponsiveContainer height={400}>
            <RechartsBarChart
              data={data}
              margin={{ left: 15, right: 15, bottom: 75, top: 50 }}
              className="ml--3"
            >
              <CartesianGrid strokeDasharray="2 3" vertical={false} />
              <XAxis
                height={50}
                axisLine={false}
                tickLine={false}
                dataKey="label"
                dy={50}
                interval={0}
                angle={-45}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                dx={-10}
                label={{ value: yAxisLabel, angle: -90, dx: -30 }}
              />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={<CustomTooltip dataKey="value" />}
              />
              <Bar
                radius={[10, 10, 10, 10]}
                barSize={10}
                dataKey="value"
                fill="#5e72e4"
                stroke="#5e72e4"
              />
            </RechartsBarChart>
          </ResponsiveContainer>
        </CardBody>
      </Card>
    </>
  );
};
