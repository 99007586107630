import classNames from 'classnames';
import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { GeneralSettings } from './GeneralSettings';
import { BusinessHours } from './BusinessHours';

const tabs = [
  { label: 'General Settings', element: <GeneralSettings /> },
  { label: 'Business Hours', element: <BusinessHours /> }
];

export const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Row>
      <Col xs="12">
        <Nav tabs className="mb-4">
          {tabs.map((tab, index) => (
            <NavItem key={tab.label}>
              <NavLink
                className={classNames({ active: activeTab === index })}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Col>
      <Col sm="12">{tabs[activeTab].element}</Col>
    </Row>
  );
};
